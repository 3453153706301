import { loadAction } from './index'
import Wheat from "../../services/Wheat";
const { default: ActionTypes } = require("./actionType");


export function getWheatList(offset, limit, month, year) {
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return Wheat.getWheatList(offset, limit, month, year)
        .then((wheats) => {
            dispatch({
                type: ActionTypes.GET_WHEAT_LIST,
                payload: wheats.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        })
        .catch(() => {
            dispatch({
                type: ActionTypes.GET_WHEAT_LIST,
                payload: []
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}


