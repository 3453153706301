import React, { useEffect, useState } from 'react';
import { 
    Dialog, 
    DialogContent, 
    DialogTitle, 
    Typography,
    useMediaQuery,
    IconButton
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

import Seasonal from '../../services/Seasonal';
import DateObject from '../../utils/DateObject';
import HorizontalRangeBarChart from '../Chart/HorizontalRangeBarChart';

function SeasonalModal(props) {
    const { isDisplayDialog, materialType, handleCloseDialog } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [dataSourceList, setDataSourceList] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if( isDisplayDialog ) {
            setDataSourceList([]);

            if( materialType.length > 0 ) {
                Seasonal.getSeasonalList(materialType).then(resultObj => {
                    const data = resultObj['data'];
                    generateDataSourceList(data);
                }).catch(error => {
                    setErrorMessage( !error.response ? "An error occurred, please try again" : error.response.data.message );
                });
            }
        }
    }, [isDisplayDialog, materialType]);

    const generateDataSourceList = (dataList) => {
        const newDataSourceList = [];
        const currentYear = DateObject.getDateTime('YYYY');
        for( const dataObj of dataList ) {
            const startDateList = dataObj['startAction'].split(" ");
            const endDateList = dataObj['endAction'].split(" ");

            const startMonth = DateObject.SHORT_MONTH_EN_LIST.indexOf(startDateList[1]);
            const endMonth = DateObject.SHORT_MONTH_EN_LIST.indexOf(endDateList[1]);
            const areaCropRound = `${dataObj['area']} - ${dataObj['cropRound']}`;

            if( endMonth < startMonth ) {
                newDataSourceList.push({
                    areaCropRound: areaCropRound,
                    action: dataObj['action'],
                    startAction: new Date(currentYear, startMonth, startDateList[0]),
                    endAction: new Date(currentYear, 11, 31)
                });

                newDataSourceList.push({
                    areaCropRound: areaCropRound,
                    action: dataObj['action'],
                    startAction: new Date(currentYear, 0, 1),
                    endAction: new Date(currentYear, endMonth, endDateList[0])
                });

            } else {
                newDataSourceList.push({
                    areaCropRound: areaCropRound,
                    action: dataObj['action'],
                    startAction: new Date(currentYear, startMonth, startDateList[0]),
                    endAction: new Date(currentYear, endMonth, endDateList[0])
                });
            }
        }
        setDataSourceList(newDataSourceList);
    }

    return (
        <Dialog open={isDisplayDialog} aria-labelledby="form-dialog-title" fullWidth fullScreen={fullScreen} maxWidth="md" onClose={handleCloseDialog}>
            <DialogTitle disableTypography className="flex items-center justify-between">
                <div>
                    <Typography
                        variant='h3'
                        color="primary"
                    >
                        {materialType ? `${materialType} Calendar` : 'Calendar'}
                    </Typography>
                </div>
                <div>
                    <IconButton size="small" onClick={handleCloseDialog}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                { dataSourceList.length > 0 ? (
                    <HorizontalRangeBarChart
                        dataSourceList={dataSourceList}
                        titleText={materialType ? `${materialType} Calendar` : 'Calendar'}
                        argumentField="areaCropRound"
                        argumentFormat="month"
                        valueField="action"
                        rangeValue1Field="startAction"
                        rangeValue2Field="endAction"
                    />
                ) : (
                    <div>
                        { errorMessage ? (
                            <Typography variant="body1" color="error">{errorMessage}</Typography>
                        ) : (
                            <Typography variant="body1">No data...</Typography>
                        ) }
                    </div>
                ) }
            </DialogContent>
        </Dialog>
    )
}

export default SeasonalModal