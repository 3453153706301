import ApiProxy from '../utils/ApiProxy';

class SoybeanMeal {

    static async getSoybeanMealList(offset = 0, limit = 0, month, year) {
        return new Promise(async (resolve, reject) => {
            let soybeanMealList = await ApiProxy.sendRequest('GET', `soybeanmeals?offset=${offset}&limit=${limit}&month=${!month ? '' : month}&year=${!year ? '' : year}`).catch((error) => {
                reject(error);
            });
            resolve(soybeanMealList);
        });
    }

    static async downloadDataMarketPrice(startDate, endDate) {
        return new Promise(async (resolve, reject) => {
            let marketPriceExcel = await ApiProxy.exportData('GET', `soybeanmeals/download?startDate=${startDate}&endDate=${endDate}`).catch((error) => { reject(error) });
            resolve(marketPriceExcel);
        });
    }

    static async getSoybeanMealResultList(year) {
        return new Promise(async (resolve, reject) => {
            let soybeanMealResultList = await ApiProxy.sendRequest('GET', `soybeanmeals/result?year=${year ? year : ""}`).catch((error) => {
                reject(error);
            });
            resolve(soybeanMealResultList);
        });
    }

    static async downloadDataResult(year) {
        return new Promise(async (resolve, reject) => {
            let resultExcel = await ApiProxy.exportData('GET', `soybeanmeals/download/result?year=${year}`).catch((error) => { reject(error) });
            resolve(resultExcel);
        });
    }

    static async getSoybeanMealResultObjectByDate(month, year, contractMonth="") {
        return new Promise(async (resolve, reject) => {
            let soybeanMealResultObject = await ApiProxy.sendRequest('GET', `soybeanmeals/result/search?month=${!month ? '' : month}&year=${!year ? '' : year}&contract=${contractMonth}`).catch((error) => {
                reject(error);
            });
            resolve(soybeanMealResultObject);
        });
    }

    static async getSoybeanMealResultSummaryList(startDate) {
        return new Promise(async (resolve, reject) => {
            let soybeanMealResultSummaryList = await ApiProxy.sendRequest('GET', `soybeanmeals/summary?startDate=${startDate}`).catch((error) => {
                return reject(error);
            });
            return resolve(soybeanMealResultSummaryList);
        });
    }

    static async getHighestLowestActualPriceObject() {
        return new Promise(async (resolve, reject) => {
            let soybeanMealResultSummaryList = await ApiProxy.sendRequest('GET', `soybeanmeals/highest-lowest/actual-price`).catch((error) => {
                return reject(error);
            });
            return resolve(soybeanMealResultSummaryList);
        });
    }

}

export default SoybeanMeal;