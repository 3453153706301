import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import Slide from '@material-ui/core/Slide';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStore, applyMiddleware, compose } from 'redux';

import THEME from './assets/themes/index';
import Routes from './routes/Routes';
import reducers from './store/reducers';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

import "tailwindcss/tailwind.css"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

function App() {
    return (
        <Provider store={store}>
            <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} TransitionComponent={Slide} autoHideDuration={3000} >
                <ThemeProvider theme={THEME}>
                    <BrowserRouter basename="">
                        <CssBaseline />
                        <ScrollToTop>
                            <Routes />
                        </ScrollToTop>
                    </BrowserRouter>
                </ThemeProvider>
            </SnackbarProvider>
        </Provider >
    );
}

export default App;