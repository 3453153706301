import React, { useEffect, useState } from 'react';
import PieChart, {
    Legend,
    Series,
    Label,
    Size,
    Animation
  } from 'devextreme-react/pie-chart';

const ForecastAccuracyPercentChart = (props) => {

    const { value } = props;
    const [dataSource, setDataSource] = useState([]);

    useEffect(() => {
        const newDataSource = [];
        newDataSource.push({ type: 'Accuracy', value: value });
        newDataSource.push({ type: 'Error', value: 100 - value });
        setDataSource(newDataSource);
    }, [value]);

    const renderCenterLabel = (pieChart) => {
        return (
            <svg>
                <text textAnchor="middle" x="100" y="120" style={{ fontSize: 18, fill: '#494949', fontWeight: 'bold' }}>
                    <tspan x="100">{value}%</tspan>
                </text>
            </svg>
        );
    }
    
    return (
        <PieChart
            type="doughnut"
            palette="Material"
            dataSource={dataSource}
            innerRadius={0.75}
            startAngle={90}
            centerRender={renderCenterLabel}
        >
            <Size height={120} />
            <Series argumentField="type" valueField="value">
                <Label visible={false} />
            </Series>
            <Legend visible={false} />
            <Animation enabled={false} />
        </PieChart>
    );
}

export default ForecastAccuracyPercentChart;