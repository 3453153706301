import ApiProxy from '../utils/ApiProxy';

class Wheat {

    static async getWheatList(offset = 0, limit = 0, month, year) {
        return new Promise(async (resolve, reject) => {
            let wheatList = await ApiProxy.sendRequest('GET', `wheats?offset=${offset}&limit=${limit}&month=${!month ? '' : month}&year=${!year ? '' : year}`).catch((error) => {
                reject(error);
            });
            resolve(wheatList);
        });
    }

    static async insertWheatObject(data) {
        return new Promise(async (resolve, reject) => {
            let insertResult = await ApiProxy.sendRequest('POST', 'wheats', data).catch((error) => {
                reject(error);
            });
            resolve(insertResult);
        });
    }

    static async insertWheatList(data) {
        return new Promise(async (resolve, reject) => {
            let insertResult = await ApiProxy.sendRequest('POST', 'wheats/upload', data).catch((error) => {
                reject(error);
            });
            resolve(insertResult);
        });
    }

    static async updateWheatObject(wheatId, data) {
        return new Promise(async (resolve, reject) => {
            let updateResult = await ApiProxy.sendRequest('PUT', `wheats/${wheatId}`, data).catch((error) => {
                reject(error);
            });
            resolve(updateResult);
        });
    }

    static async deleteWheatList(data) {
        return new Promise(async (resolve, reject) => {
            let updateResult = await ApiProxy.sendRequest('DELETE', 'wheats', data).catch((error) => {
                reject(error);
            });
            resolve(updateResult);
        });
    }

    static async downloadDataMarketPrice(startDate, endDate) {
        return new Promise(async (resolve, reject) => {
            let marketPriceExcel = await ApiProxy.exportData('GET', `wheats/download?startDate=${startDate}&endDate=${endDate}`).catch((error) => { reject(error) });
            resolve(marketPriceExcel);
        });
    }

    static async getWheatResultList(year) {
        return new Promise(async (resolve, reject) => {
            let wheatResultList = await ApiProxy.sendRequest('GET', `wheats/result?year=${year ? year : ""}`).catch((error) => {
                reject(error);
            });
            resolve(wheatResultList);
        });
    }

    static async downloadDataResult(year) {
        return new Promise(async (resolve, reject) => {
            let resultExcel = await ApiProxy.exportData('GET', `wheats/download/result?year=${year}`).catch((error) => { reject(error) });
            resolve(resultExcel);
        });
    }

    static async getWheatResultObjectByDate(month, year) {
        return new Promise(async (resolve, reject) => {
            let wheatResultObject = await ApiProxy.sendRequest('GET', `wheats/result/search?month=${!month ? '' : month}&year=${!year ? '' : year}`).catch((error) => {
                reject(error);
            });
            resolve(wheatResultObject);
        });
    }

    static async getWheatResultSummaryList(startDate) {
        return new Promise(async (resolve, reject) => {
            let cornResultSummaryList = await ApiProxy.sendRequest('GET', `wheats/summary?startDate=${startDate}`).catch((error) => {
                return reject(error);
            });
            return resolve(cornResultSummaryList);
        });
    }

    static async getHighestLowestActualPriceObject() {
        return new Promise(async (resolve, reject) => {
            let cornResultSummaryList = await ApiProxy.sendRequest('GET', `wheats/highest-lowest/actual-price`).catch((error) => {
                return reject(error);
            });
            return resolve(cornResultSummaryList);
        });
    }

}

export default Wheat;