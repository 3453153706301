import React, { useEffect, useState } from 'react';
import Chart, {
    ArgumentAxis,
    Series,
    ZoomAndPan,
    Legend,
    ScrollBar,
    ConstantLine,
    Label,
    Grid,
    ValueAxis,
    CommonSeriesSettings,
    Point,
    Tooltip,
    Format
} from 'devextreme-react/chart';
import { useSelector } from 'react-redux';

import DateObject from '../../utils/DateObject'
import Currency from '../../utils/Currency'
import EventModal from '../Options/EventModal';

/**
 * 
 * install: npm install devextreme@21.1 devextreme-react@21.1
 * Ref: https://js.devexpress.com/Demos/WidgetsGallery/Demo/Charts/Line/React/Light/
 * 
 * @param
 *  dataList:
 *      array ของ object ที่จะนำไปแสดงในกราฟ โดย object จะอยู่ในรูปแบบ 
 *         { 
 *              key1: value1, 
 *              key2: value2, 
 *              key3: value3,
 *              ...
 *          }
 *      Ex: [ { 'date': '2013-11-01', 'actualPrice': 7.903125, 'forecastValue': null, 'forecastLower': null, 'forecastUpper': null }, { 'date': '2013-12-01', 'actualPrice': 7.153260869, 'forecastValue': null, 'forecastLower': null, 'forecastUpper': null }, ...]
 * 
 *  argumentField:
 *      ชื่อ key ที่จะใช้เป็น label ในแกน x
 *      Ex: key1
 * 
 *  argumentType:
 *      ประเภทของการแสดงผล label ในแกน x ค่าที่กำหนดได้คือ 'datetime' | 'numeric' | 'string'
 *      Ex: datetime 
 * 
 *  argumentFormat:
 *      รูปแบบการแสดงผลของ label ในแกน x ดูเพิ่มเติมได้ที่ https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxChart/Configuration/argumentAxis/label/#format
 *      Ex: monthAndYear
 * 
 *  argumentTitle:
 *      ชื่อ title ที่แสดงในแนวแกน x
 *      Ex: เดือน
 * 
 *  argumentConstantLineList:
 *      array ของ object ที่จะนำไปแสดงเส้นตั้งฉากกับแกน x โดย object จะอยู่ในรูปแบบ
 *          {
 *              label: <ชื่อที่แสดงของเส้นตั้งฉากแกน x>,
 *              value: <ค่าในแนวแกน x สำหรับแสดงเส้นตั้งฉาก>,
 *              color: <สีของเส้น>,
 *              style: <รูปแบบของเส้น 'dash' | 'dot' | 'longDash' | 'solid'>
 *          }
 *      Ex: [{ label: 'Today', value: '2021-10-04', color: '#8c8cff', style: 'dash' }]
 * 
 *  argumentRangeStart:
 *      ค่าเริ่มต้นในแนวแกน x ที่ใช้แสดงกราฟ
 *      Ex: '2021-01-01', 10
 * 
 *  argumentRangeEnd:
 *      ค่าสิ้นสุดในแนวแกน x ที่ใช้แสดงกราฟ
 *      Ex: '2021-12-01', 100
 * 
 *  averageField:
 *      ชื่อของ key ใน dataList ที่จะนำมาคำนวณหาค่าเฉลี่ยของกราฟ
 * 
 *  valueTitle:
 *      ชื่อ title ที่แสดงในแนวแกน y
 * 
 *  valueFieldList:
 *      array ของ object ที่จะนำไปแสดงเป็นเส้นในกราฟ โดย object จะอยู่ในรูปแบบ
 *          {
 *              key: <คีย์สำหรับวนลูป array ใน react>,
 *              valueField: <ชื่อของ key ใน dataList ที่จะนำมาแสดงเป็นกราฟ>,
 *              name: <ชื่อที่จะนำไปแสดงใน Legend เพื่อบอกว่าเส้นสีนี้แสดงค่าอะไร>,
 *              color: <สีของเส้นที่แสดงในกราฟ (optional)>,
 *              isShowInLegend: <ค่า true, false เพื่อกำหนดว่าเส้นนี้จะแสดงอยู่ใน Legend หรือไม่ (optional)>,
 *              type: <ประเภทของค่าที่จะแสดงในกราฟ (optional)>,
 *              rangeValue1Field: <ชื่อของ key ใน dataList ที่จะนำมาแสดงร่วมกับค่านี้ (optional)>,
 *              rangeValue2Field: <ชื่อของ key ใน dataList ที่จะนำมาแสดงร่วมกับค่านี้ (optional)>,
 *          },
 *      Ex: [ { key: 'rangeArea', rangeValue1Field: 'forecastLower', rangeValue2Field: 'forecastUpper', type: 'rangeArea', color: '#b0daff', isShowInLegend: false }, { key: "actualPrice", valueField: "actualPrice", name: 'ราคา', isShowInLegend: true }, ...]
 *  
 *  isStartFromZero:
 *      ค่า true, false สำหรับกำหนดให้กราฟเริ่มต้นที่ค่า 0
 * 
 *  isDisplayPoint:
 *      ค่า true, false สำหรับกำหนดให้มีการแสดงจุดบนกราฟ
 *  
 * @returns 
 */

/**
 * ตัวอย่างการเรียกใช้งาน
 * <LineChart
        dataList={[
            { 'date': '2021-04-01', 'actualPrice': 9.206558062, 'forecastValue': null, 'forecastLower': null, 'forecastUpper': null },
            { 'date': '2021-05-01', 'actualPrice': 9.322916667, 'forecastValue': null, 'forecastLower': null, 'forecastUpper': null },
            { 'date': '2021-06-01', 'actualPrice': 9.768333333, 'forecastValue': null, 'forecastLower': null, 'forecastUpper': null },
            { 'date': '2021-07-01', 'actualPrice': 10.26666667, 'forecastValue': null, 'forecastLower': null, 'forecastUpper': null },
            { 'date': '2021-08-01', 'actualPrice': null, 'forecastValue': 8.885284798223715, 'forecastLower': 5.820702068851409, 'forecastUpper': 11.94986793114859 },
            { 'date': '2021-09-01', 'actualPrice': null, 'forecastValue': 8.91806066632154, 'forecastLower': 5.9233392125219275, 'forecastUpper': 11.912782787478072 },
            { 'date': '2021-10-01', 'actualPrice': null, 'forecastValue': 8.956665846756222, 'forecastLower': 5.9732476446129095, 'forecastUpper': 11.79732235538709 },
            { 'date': '2021-11-01', 'actualPrice': null, 'forecastValue': 8.973612140274348, 'forecastLower': 6.076414470868299, 'forecastUpper': 11.6941555291317 },
            { 'date': '2021-12-01', 'actualPrice': null, 'forecastValue': 8.992833712057573, 'forecastLower': 6.248602704052163, 'forecastUpper': 11.521967295947835 },
            { 'date': '2022-01-01', 'actualPrice': null, 'forecastValue': 8.9928864349419, 'forecastLower': 6.025216105619942, 'forecastUpper': 11.745353894380058 },
        ]}
        argumentField="date"
        argumentType="datetime"
        argumentFormat="monthAndYear"
        argumentTitle="เดือน"
        argumentConstantLineList={[
            { label: 'Today', value: '2021-10-04', color: '#8c8cff', style: 'dash' }
        ]}
        argumentRangeStart="2021-01-01"
        argumentRangeEnd="2021-12-01"
        averageField="actualPrice"
        valueTitle="Market price"
        valueFieldList={[
            { key: 'rangeArea', rangeValue1Field: 'forecastLower', rangeValue2Field: 'forecastUpper', type: 'rangeArea', color: '#b0daff', isShowInLegend: false },
            { key: "actualPrice", valueField: "actualPrice", name: 'ราคา', isShowInLegend: true }, 
            { key: "forecastValue", valueField: "forecastValue", name: 'คำนาย', isShowInLegend: true }, 
            { key: "forecastLower", valueField: "forecastLower", name: 'ต่ำสุด', isShowInLegend: true },
            { key: "forecastUpper", valueField: "forecastUpper", name: 'สูง', isShowInLegend: true }
        ]}
        isStartFromZero={true}
        isDisplayPoint={true}
    />
 */


const LineChart = (props) => {

    const { dataList, argumentField, argumentType, argumentFormat, argumentTitle, argumentConstantLineList, valueTitle, valueFieldList, averageField, isStartFromZero, unitType = "", isDisplayPoint=true, materialType } = props;
    const [isDisplayEventModal, setIsDisplayEventModal] = useState(false);
    const [date, setDate] = useState(null)
    const [cursorStyle, setCursorStyle] = useState('')
    const materialEventList = useSelector(state => state.materialEventReducer);

    let { argumentRangeStart, argumentRangeEnd } = props;
    const averageColor = '#FF00FF';
  
    if( !argumentRangeStart ) {
        argumentRangeStart = dataList[0]?.[argumentField];
    }
    if( !argumentRangeEnd ) {
        argumentRangeEnd = dataList[ dataList.length - 1 ]?.[argumentField];
    }
    
    const [visualRangeObject, setVisualRangeObject] = useState({ startValue: argumentRangeStart, endValue: argumentRangeEnd })
    
    useEffect(() => {
        setVisualRangeObject({ startValue: argumentRangeStart, endValue: argumentRangeEnd })
    }, [argumentRangeStart, argumentRangeEnd])

    const getChartAverageValue = (averageField) => {
        let average = 0;
        let countValue = 0;

        if( averageField ) {
            for( let i=0; i<dataList.length; i++ ) {
                const currentDataObj = dataList[i];
                if( currentDataObj.hasOwnProperty(averageField) ) {
                    average += currentDataObj[averageField];
                    countValue++;
                }
            }
            average = average / countValue;
        }
        return average;
    }

    let averageValue = getChartAverageValue(averageField);

    const handleOptionChange = (e) => {
        if(e.fullName === 'argumentAxis.visualRange') {
            const stateStart = visualRangeObject.startValue;
            const currentStart = e.value.startValue;
            if(!stateStart || !currentStart){
                return;
            }
            if(stateStart.valueOf() !== currentStart.valueOf()) {
                setVisualRangeObject(e.value)
            }
          }
    }

    const getEventObject = (dateOfPoint) => {
        const eventObject = materialEventList.find(item => { return new Date(item.period).toDateString() === new Date(dateOfPoint).toDateString()})
        if(eventObject){
            return ('Click on dot for view more detail')
        }else{
            return ('-')
        }
    }

    function customizeTooltip(arg) {
        return {
            text: getTextTooltip(arg.seriesName.replace(/\s/g, '').toLowerCase(), arg.valueText, arg.argumentText)
        };
    }
    
    function getTextTooltip(type, text, date) {     
        if(type.match(/^forecastprice.*$/)){
            return `<b>Month : </b> ${DateObject.convertDateTime(date, 'Month yyyy', true)} \n  <b>Forecast price : </b> ${text} ${Currency.getSign(unitType)}`
        } else if(type.match(/^marketprice.*$/)){
            return `<b>Month : </b> ${DateObject.convertDateTime(date, 'Month yyyy', true)} \n <b>Market price : </b> ${text} ${Currency.getSign(unitType)} \n <b> Event : </b> ${getEventObject(date)}`
        } else if(type.match(/^lowerbounded.*$/)){
            return `<b>Month : </b> ${DateObject.convertDateTime(date, 'Month yyyy', true)} \n <b> Lower bounded : </b> ${text} ${Currency.getSign(unitType)}`
        } else if(type.match(/^upperbounded.*$/)){
            return `<b>Month : </b> ${DateObject.convertDateTime(date, 'Month yyyy', true)} \n <b> Upper bounded : </b> ${text} ${Currency.getSign(unitType)}`
        } else if(type.match(/^series.*$/)){
            return `<b>Month : </b> ${DateObject.convertDateTime(date, 'Month yyyy', true)} \n <b> Price range : </b> ${text} ${Currency.getSign(unitType)}`
        } else if(type.match(/^targetprice.*$/)){
            return `<b> Target Price : </b> ${text} ${Currency.getSign(unitType)}`
        } else if(type.match(/^inventoryprice$/)){
            return `<b> Inventory Price : </b> ${text} ${Currency.getSign(unitType)}`
        }else if(type.match(/^inventoryprice *([0-9]{4})/)){
            let year = type.split('inventoryprice')[1]
            let newDate = new Date(new Date(date).setFullYear(year))
            return `<b>Month : </b> ${DateObject.convertDateTime(newDate, 'Month yyyy', true)} \n <b> Inventory Price : </b> ${text} ${Currency.getSign(unitType)} `
        } else {
            
            return `${text}`
        }
    }

    const onPointClick = (e) => {
        if(materialType) {
            let dateOfPoint = e.target.data.date
            setDate(dateOfPoint)
            setIsDisplayEventModal(true) 
        }
    }

    const onPointHoverChanged = (e) => {
        const point = e.target;
        if (point.isHovered()) {
            setCursorStyle("pointer")
        } else {
            setCursorStyle("initial")
        }
    }

    return (
        <>
            <Chart
                id="chart"
                dataSource={dataList}
                onOptionChanged={handleOptionChange}
                onPointClick={onPointClick}
                onPointHoverChanged={onPointHoverChanged}
                style={{cursor: cursorStyle}}
            >
                <CommonSeriesSettings argumentField={argumentField} type="line" hoverMode="includePoints">
                    <Point hoverMode="allArgumentPoints" />
                </CommonSeriesSettings>
                {
                    valueFieldList.map((item) => {
                        return <Series key={item.key} valueField={item.valueField} name={item.name} rangeValue1Field={item.rangeValue1Field} rangeValue2Field={item.rangeValue2Field} type={item.type} color={item.color} showInLegend={item.isShowInLegend}>
                            <Point visible={isDisplayPoint}/>
                        </Series>;
                    })
                }
                <ArgumentAxis visualRange={visualRangeObject} argumentType={argumentType} title={argumentTitle}>
                    <Label format={argumentFormat} indentFromAxis={10} />
                    <Grid visible={true} />
                    { argumentConstantLineList.map((argumentConstantLineObj, index) => {
                        return (
                            <ConstantLine
                                width={2}
                                value={argumentConstantLineObj.value}
                                color={argumentConstantLineObj.color}
                                dashStyle={argumentConstantLineObj.style}
                                key={index}
                            >
                                <Label text={argumentConstantLineObj.label} horizontalAlignment="left" />
                            </ConstantLine>  
                        );
                    }) }
                </ArgumentAxis>
                <ValueAxis maxValueMargin={0.1} title={valueTitle} showZero={isStartFromZero}>
                    { averageValue > 0 ? (
                        <ConstantLine
                            width={2}
                            value={averageValue}
                            color={averageColor}
                        >
                            <Label text="Average"/>
                        </ConstantLine>
                    ) : (null) }
                </ValueAxis>
                <ScrollBar visible={true} position="bottom" />
                <ZoomAndPan argumentAxis="pan" />
                <Legend 
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                    itemTextPosition="bottom"
                />
                <Tooltip enabled={true} customizeTooltip={customizeTooltip}>
                    <Format type="fixedPoint" precision={2}/>
                </Tooltip>
            </Chart>
            <EventModal isDisplayDialog={isDisplayEventModal} materialType={materialType} date={date} handleCloseDialog={() => setIsDisplayEventModal(!isDisplayEventModal)}/>
        </>
    );
}

export default LineChart;