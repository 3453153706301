import { loadAction } from './index'
import PriceSummary from "../../services/PriceSummary";
const { default: ActionTypes } = require("./actionType");


export function getPriceSummaryObject(startDate, countLastMonths) {
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return PriceSummary.getPriceSummaryObject(startDate, countLastMonths).then((wheats) => {
            dispatch({
                type: ActionTypes.GET_PRICE_SUMMARY_OBJECT,
                payload: wheats.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        }).catch(() => {
            dispatch({
                type: ActionTypes.GET_PRICE_SUMMARY_OBJECT,
                payload: null
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}


