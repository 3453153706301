import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { Typography, Button, Grid, Paper, Modal } from '@material-ui/core';

import useStyles from '../../assets/styles';

export default function ModalDelete(props) {
    const classes = useStyles();
    const {isOpen, handleOnCancle, handleOnConfirm} = props;
    return(
        <Modal open={isOpen} onClose={()=>{handleOnCancle(false)}} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" className="flex justify-center items-center">
            <Paper elevation={0} className="p-10" style={{maxWidth: '90%', width: 620, outline: 'none'}}>
                <Grid container>
                    <Grid item xs={12} className="flex items-center"> 
                        <WarningIcon />
                        <Typography variant="h3" className="pl-2" style={{fontWeight: 'bold'}}>
                            Alert
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className="flex items-center pt-8">
                        <Typography variant="body1">
                            Are you sure you want to delete this item?
                        </Typography> 
                    </Grid>
                    <Grid item xs={12} className="flex items-center">
                        <Typography variant="body1" className="text-red-500" style={{fontWeight: 'bold'}}>
                            Warning
                        </Typography> 
                        <Typography variant="body1">
                             : Deleted data will not be shown on the platform
                        </Typography> 
                    </Grid>
                    <Grid item xs={12} className="pt-8 text-right">
                        <Button onClick={()=>{handleOnCancle(false)}} className="mr-8">Cancel</Button>
                        <Button variant="contained" className={classes.deleteBtn} onClick={()=>{handleOnConfirm()}}>
                            DELETE
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>   
    )
}