import { AppBar, Fab, Grid, Hidden, IconButton, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from '@material-ui/icons/Person';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useStyles from '../../assets/styles';
import UserService from '../../services/User';
import { loadAction, userAction, sideBarAction } from '../../store/actions';

function NavBar(props) {
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory()
    const userProfileObj = useSelector(state => state.userReducer);
    const sideBarObj = useSelector(state => state.sideBarReducer);
    const currentRole = userProfileObj["userLevel"]
    const [anchorEl, setAnchorEl] = useState(null);

    const openProfileMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const clickLogout = async () => {
        dispatch(loadAction.displayLoadingOverlay());
        UserService.logoutUser()
            .then(() => {
                dispatch(userAction.authenUser()).then(() => {
                    dispatch(loadAction.hideLoadingOverlay());
                    history.push("/login");
                });
            })
    }

    const renderHomeHeader = () => {

        return (
            <Grid item>
                <Fab variant="extended" size="small" style={{ boxShadow: 'none' }} color="primary" onClick={openProfileMenu}>
                    <AccountCircleIcon style={{ color: 'white', fontSize: '2rem' }} />
                    <Typography className="ml-1" variant="overline">{userProfileObj['firstName']}</Typography>
                </Fab>
                <Menu id="profile-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} >
                    <MenuItem component={Link} to={"/profile"} onClick={handleClose}>
                        <PersonIcon className="mr-2" /> Profile
                    </MenuItem>
                    <MenuItem onClick={clickLogout} component={Link} to={'/login'}>
                        <ExitToAppIcon className="mr-2" />Logout
                    </MenuItem>
                </Menu>
            </Grid>
        )
    }

    const menuCondition = (link) => {
        if (!props.isLoginPage) {
            return renderHomeHeader()
        } 
    }

    const toggleMobileMenu = () => {
        dispatch( sideBarAction.updateMobileMenuOpen( !sideBarObj['mobileMenuOpen'] ) );
    }

    return (
        <AppBar position={props.position} color="transparent" className={classes.appBarHome}>
            <Toolbar variant="dense">
                {!props.isLoginPage ?
                    <IconButton edge="start" color="inherit" aria-label="menu" className={classes.menuButton} onClick={toggleMobileMenu}>
                        <MenuIcon />
                    </IconButton>
                : null}
                <Link to={currentRole === "guest" ? "/login" : "/home"} id="name-link">
                    <Hidden xsDown implementation="css">
                        <Typography variant="h5" className="pl-2 whitespace-nowrap" style={{ letterSpacing: '0.2rem', fontWeight: 700 }}>
                            Big Data in Purchasing 
                        </Typography>
                    </Hidden>
                </Link>
                <Grid container alignItems="center" spacing={2} justifyContent="flex-end" className={classes.gridMainMenu}>
                    {menuCondition(location.pathname)}
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

export default NavBar
