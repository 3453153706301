import { loadAction } from './index'
import Lysine from "../../services/Lysine";
const { default: ActionTypes } = require("./actionType");


export function getLysineResultList(year) {
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return Lysine.getLysineResultList(year)
        .then((lysineResult) => {
            dispatch({
                type: ActionTypes.GET_LYSINE_RESULT_LIST,
                payload: lysineResult.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        })
        .catch(() => {
            dispatch({
                type: ActionTypes.GET_LYSINE_RESULT_LIST,
                payload: []
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}

export function getLysineResultSummaryList(startDate) {
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return Lysine.getLysineResultSummaryList(startDate).then((soybeanMealResult) => {
            dispatch({
                type: ActionTypes.GET_LYSINE_RESULT_LIST,
                payload: soybeanMealResult.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        }).catch(() => {
            dispatch({
                type: ActionTypes.GET_LYSINE_RESULT_LIST,
                payload: []
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}

