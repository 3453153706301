import React from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { Typography, Button, Grid, FormControl, InputLabel, OutlinedInput, InputAdornment, Hidden } from '@material-ui/core';

import useStyles from '../../assets/styles';

export default function HeaderSearch(props) {
    const { tittle = "", quickSearchValue = "", handleQuickSearch = ()=>{}, rightHeaderSearch = ()=>{ return null }, handleSearchClear = ()=>{} } = props;
    const classes = useStyles();

    return(
        <Grid container item xs={12} alignItems="center">
            <Grid item xs={!rightHeaderSearch() ? 12 : 4} md={4} className={!rightHeaderSearch() ? "self-center pt-4 "+classes.headerSearchTitle : "self-center "+classes.headerSearchTitle}>
                <Typography variant="h3" style={{ fontWeight: 'bold' }}>{tittle}</Typography>
            </Grid>
            <Hidden mdUp>
                <Grid container item xs={8} md={4} className={classes.headerSearchTitle}>
                    {rightHeaderSearch()}
                </Grid>
            </Hidden>
            <Grid container item xs={12} md={5}>
                <Grid item xs={12} className="self-center text-left">
                    <FormControl variant="outlined" margin="dense" className="mt-auto mb-auto" style={{ backgroundColor: 'white', borderRadius: '4px', marginTop: '4px', width: `calc(100% - 145px)` }}>
                        <InputLabel htmlFor="search">Search</InputLabel>
                        <OutlinedInput id="search" type={'text'} labelWidth={45}
                            value={quickSearchValue}
                            onChange={(e) => {handleQuickSearch(e.target.value)}}
                            endAdornment={
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <Button variant="contained" className={classes.advanceSearchBtn+' ml-2 mt-auto mb-auto'} style={{maxWidth: '64px'}} onClick={()=>{handleSearchClear()}}>
                        clear
                    </Button>
                </Grid>
            </Grid>
            <Hidden smDown>
                <Grid container item xs={3}>
                    {rightHeaderSearch()}
                </Grid>
            </Hidden>
        </Grid>
    )
}