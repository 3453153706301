import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {List, Drawer, Divider, ListItem, ListItemIcon, Hidden, ListItemText, Collapse} from '@material-ui/core';

import useStyles from '../../assets/styles';
import DateObject from '../../utils/DateObject';
import logo from '../../assets/images/thaifood-logo.png';
import PermissionMenu from '../../configs/PermissionMenu';
import { sideBarAction } from '../../store/actions';

export default function SideBar(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [expandKey, setExpandKey] = useState('')
    const { activeMenu } = props;
    const userProfile = useSelector(state => state.userReducer);
    const sideBarObj = useSelector(state => state.sideBarReducer);
    const currentRole = userProfile["userLevel"];
    const [allowedMenu, setAllowedMenu] = useState(PermissionMenu[currentRole]);

    const checkingActiveClass = (link, menuKey) => {
        if(activeMenu === link){
            if(!expandKey) setExpandKey(menuKey)
            return true;
        }else if((activeMenu.search(link+"/")>=0) && (link!=="/")){
            if(!expandKey) setExpandKey(menuKey)
            return true;
        }else{
            return false;
        }
    }

    useEffect(() => {
        setAllowedMenu(PermissionMenu[currentRole]);
    }, [userProfile, currentRole]);

    const handleClickExpand = key => () => {
        setExpandKey(expandKey === key ? '' : key);
    };

    const toggleMobileMenu = () => {
        dispatch( sideBarAction.updateMobileMenuOpen( !sideBarObj['mobileMenuOpen'] ) );
    }

    const drawer = (
        <div>
            <List>
                <ListItem component={Link} to={"/home"}>
                    <img src={logo} alt={'Thaifood Logo'} className="mx-auto" />
                </ListItem>
            </List>
            <Divider />
            <List>
                {allowedMenu?.map((menu, index)=>{
                    const isOpen = expandKey === menu.key ? true : false;
                    return(
                        !menu.subMenuList ? (
                            <ListItem button key={index} className={checkingActiveClass(menu.link, menu.key) ? classes.sideBarItemActive : null} component={Link} to={menu.link}>
                                <ListItemIcon>
                                    <menu.icon className={checkingActiveClass(menu.link, menu.key) ? classes.sideBarItemActiveIcon : null} />
                                </ListItemIcon>
                                <ListItemText primary={menu.name} />
                            </ListItem>
                        ) : (
                            <div key={index} >
                                <ListItem button onClick={handleClickExpand(menu.key)} component={Link} to={menu.subMenuList[0].link}>
                                    <ListItemIcon> 
                                        <menu.icon />
                                    </ListItemIcon>
                                    <ListItemText primary={menu.name} />
                                    {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </ListItem>
                                <Collapse in={isOpen} timeout="auto" unmountOnExit>
                                    {menu.subMenuList.map((subMenu, subMenuIndex)=>{
                                        if((activeMenu === subMenu.link) && (expandKey !== menu.key)){
                                            setExpandKey(menu.key) 
                                        }

                                        return(
                                            <List disablePadding key={subMenuIndex} component={Link} to={subMenu.link} onClick={handleClickExpand(menu.key)}>
                                                <ListItem button className={checkingActiveClass(subMenu.link, menu.key) ? classes.sideBarItemActive : null} style={{paddingLeft: '2rem'}}>
                                                    <ListItemIcon>
                                                        <subMenu.icon className={checkingActiveClass(subMenu.link, menu.key) ? classes.sideBarItemActiveIcon : null} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={subMenu.name} />
                                                </ListItem>
                                            </List>
                                        )
                                    })}
                                </Collapse>
                            </div>
                        )
                    )
                })}
            </List>
            <Divider />
            <List>
                <ListItem>
                    <ListItemText primary={<span>© {DateObject.getDateTime('YYYY')} Coraline co.,ltd</span>} />
                </ListItem>
            </List>
        </div>
    );

    return (
        <nav className={classes.drawer}>
            <Hidden mdUp implementation="css">
                <Drawer variant="temporary" anchor={'left'} open={sideBarObj['mobileMenuOpen']} onClose={toggleMobileMenu} classes={{ paper: classes.drawerPaper }} ModalProps={{ keepMounted: true }} >
                    {drawer}
                </Drawer>
            </Hidden>
            <Hidden smDown implementation="css">
                <Drawer
                    classes={{ paper: classes.drawerPaper }}
                    variant="permanent"
                    open
                >
                    {drawer}
                </Drawer>
            </Hidden>
        </nav>
    );
}
