import ApiProxy from '../utils/ApiProxy';

class PriceSummary {

    static async getPriceSummaryObject(startDate, countLastMonths) {
        return new Promise(async (resolve, reject) => {
            let responseObj = await ApiProxy.sendRequest('GET', `price-summary?startDate=${startDate}&countLastMonths=${countLastMonths}`).catch((error) => {
                reject(error);
            });
            resolve(responseObj);
        });
    }
}

export default PriceSummary;