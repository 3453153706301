import React, { useEffect, useState } from 'react';
import { Typography, Button, Grid, TextField, MenuItem } from '@material-ui/core';
import MaterialEventService from '../../../services/MaterialEvent';
import { useDispatch } from 'react-redux';
import DateObject from '../../../utils/DateObject';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { categoriesList, impactList } from '../../../configs/MaterialEventConfig';
import { loadAction, snackbarAction } from '../../../store/actions';
import { CloudDownload, CloudUpload } from '@material-ui/icons';
import FileObject from '../../../utils/FileObject';
import { acceptFileType } from '../../../configs/AcceptFileType';


function EventInput(props) {

    const { materialType, getNewDataList = () => {} } = props
    const dispatch = useDispatch();
    const [eventYear, setEventYear] = useState(DateObject.getDateTime('YYYY'))
    const [eventMonth, setEventmonth] = useState("")
    const [categories, setCategories] = useState("")
    const [impact, setImpact] = useState("")
    const [event, setEvent] = useState("")
    const [materialEventTemplateUrl, setMaterialEventTemplateUrl] = useState("")

    useEffect(() => {

        if(materialType === "corn"){
            setMaterialEventTemplateUrl(process.env.REACT_APP_CORN_MATERIAL_EVENT_TEMPLATE_URL)
        }else if (materialType === "wheat") {
            setMaterialEventTemplateUrl(process.env.REACT_APP_WHEAT_MATERIAL_EVENT_TEMPLATE_URL)
        }else if (materialType === "tapioca") {
            setMaterialEventTemplateUrl(process.env.REACT_APP_TAPIOCA_MATERIAL_EVENT_TEMPLATE_URL)
        }else if (materialType === "lysine") {
            setMaterialEventTemplateUrl(process.env.REACT_APP_LYSINE_MATERIAL_EVENT_TEMPLATE_URL)
        }else if (materialType === "soybeanMeal") {
            setMaterialEventTemplateUrl(process.env.REACT_APP_SOYBEAN_MEAL_MATERIAL_EVENT_TEMPLATE_URL)
        }else {
            setMaterialEventTemplateUrl("")
        }

    }, [materialType])

    const checkIsDisableSubmitBtn = () => {
        return !(eventYear > 0 &&
            eventMonth.length > 0 &&
            categories.length > 0 &&
            impact.length > 0 &&
            event.length > 0
        )

    }

    const resetForm = () => {
        setEventYear(DateObject.getDateTime('YYYY'))
        setEventmonth("")
        setCategories("")
        setImpact("")
        setEvent("")

    }

    const submitForm = (e) => {
        e.preventDefault()
        let materialEventObject = {
            material: materialType,
            period: `${eventYear}-${eventMonth}`,
            categories: categories,
            impact: impact,
            event: event
        }
        dispatch(loadAction.displayLoadingOverlay());
        MaterialEventService.insertMaterialEventObject(materialEventObject).then((response) => {
            resetForm()
            dispatch(snackbarAction.openSnackbar({ options: { variant: "success" }, message: "Event has been successfully added" }))
            getNewDataList()
            dispatch(loadAction.hideLoadingOverlay());
        }).catch((error) => {
            let newError = { ...error }
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            dispatch(loadAction.hideLoadingOverlay());
        });
    }

    const onDownload = () => {
        window.location.href = materialEventTemplateUrl
    }

    const onReadExcel = async (e) => {

        const selectedFile = e.target.files[0];

        if(selectedFile !== undefined){
            dispatch(loadAction.displayLoadingOverlay());
            if (acceptFileType.split().indexOf(selectedFile.type) === -1) {
                dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message:"Support file type is .XLSX" }))
                dispatch(loadAction.hideLoadingOverlay());
            }else{
                let [ dataList, sheetName ] = await FileObject.readExcel(selectedFile)
                if(dataList.length > 0 && materialType === sheetName){
                    MaterialEventService.insertMaterialEventList({dataList:dataList, materialType: materialType }).then((response) => {
                        dispatch(snackbarAction.openSnackbar({ options: { variant: "success" }, message: `Upload ${sheetName} event data upload completed` }))
                        getNewDataList()
                        dispatch(loadAction.hideLoadingOverlay());
                    }).catch((error) => {
                        let newError = { ...error }
                        dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
                        dispatch(loadAction.hideLoadingOverlay());
                    });
                }else{
                    dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: "An error occurred, please try again" }))
                    dispatch(loadAction.hideLoadingOverlay());
                }
            }
        }
    };

    return (
        <>
            <Grid container spacing={2} className="mb-8">
                <Grid item xs={12} sm={6} md={12} lg={6}>
                    <Typography variant="h3" style={{ fontWeight: 'bold' }}>Event</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={12} lg={6} className='text-right'>
                    <Grid container spacing={1} justifyContent="flex-end">
                        <Grid item xs={6} sm="auto">
                            <Button className="w-full md:w-auto" variant="outlined" size="small" disableElevation color="primary" disabled={!materialEventTemplateUrl} startIcon={<CloudDownload/>} onClick={onDownload}>
                                Download Template
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm="auto">
                            <Button className="w-full md:w-auto" variant="contained" size="small" disableElevation component="label" color="primary" startIcon={<CloudUpload/>} onClick={() => {  }}>
                                Upload File
                                <input type="file" hidden accept={acceptFileType} onChange={(e) => {onReadExcel(e)}}/>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <form noValidate autoComplete="off" onSubmit={submitForm}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={5} sm={4} md={4}>
                                <Typography variant="body1" className="self-center">Event Year</Typography>
                            </Grid>
                            <Grid item xs={7} sm={8} lg={6}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        autoOk
                                        inputVariant="outlined"
                                        size="small"
                                        views={["year"]}
                                        label="Year"
                                        format="yyyy"
                                        name="marketPriceYear"
                                        fullWidth
                                        value={eventYear}
                                        onChange={(date) => { setEventYear(date ? String(date.getFullYear()) : "") }}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={5} sm={4} md={4}>
                                <Typography variant="body1" className="self-center">Event Month</Typography>
                            </Grid>
                            <Grid item xs={7} sm={8} lg={6}>
                                <TextField label="Month" select size="small" variant="outlined" fullWidth value={eventMonth} onChange={(e) => { setEventmonth(e.target.value) }}>
                                    {DateObject.MONTH_OBJECT_LIST.map((monthObject) => {
                                        return (
                                            <MenuItem key={monthObject.value} value={monthObject.value}>{monthObject.name}</MenuItem>
                                        )
                                    })}
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={5} sm={4} md={4}>
                                <Typography variant="body1" className="self-center">Categories</Typography>
                            </Grid>
                            <Grid item xs={7} sm={8} lg={6}>
                                <TextField label="Categories" select size="small" variant="outlined" fullWidth value={categories} onChange={(e) => { setCategories(e.target.value) }}>
                                    {categoriesList.map((name, index) => {
                                        return (
                                            <MenuItem key={index} value={name}>{name}</MenuItem>
                                        )
                                    })}
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={5} sm={4} md={4}>
                                <Typography variant="body1" className="self-center">Impact</Typography>
                            </Grid>
                            <Grid item xs={7} sm={8} lg={6}>
                                <TextField label="Impact" select size="small" variant="outlined" fullWidth value={impact} onChange={(e) => { setImpact(e.target.value) }}>
                                    {impactList.map((name, index) => {
                                        return (
                                            <MenuItem key={index} value={name}>{name}</MenuItem>
                                        )
                                    })}
                                </TextField>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={5} sm={2}>
                                <Typography variant="body1" className="self-center">Event</Typography>
                            </Grid>
                            <Grid item xs={7} sm={8} lg={6}>
                                <TextField fullWidth type="text" multiline label="Event" variant="outlined" size="small" autoComplete="off" value={event} onChange={(e) => { setEvent(e.target.value) }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="text-right">
                        <Button variant="contained" color="primary" disableElevation type="submit" disabled={checkIsDisableSubmitBtn()}>
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

export default EventInput