const { default: ActionTypes } = require("../actions/actionType");

const initialState = [ ];

const wheatReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_WHEAT_LIST: {
            if(action.payload === null){
                return [ ...state ]
            }else{
                return [ ...action.payload ]
            }
        }
        default: {
            return [ ...state ]
        }
    }

}

export default wheatReducer;