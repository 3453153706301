import React, { useState } from "react";
import { Typography, Button, Grid, TextField } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { loadAction, snackbarAction } from "../../../store/actions";
import LysineService from "../../../services/Lysine";
import { useDispatch } from "react-redux";
import { CloudDownload, CloudUpload } from "@material-ui/icons";
import FileObject from "../../../utils/FileObject";
import { acceptFileType } from "../../../configs/AcceptFileType";
import ModalConfirm from "../../MainLayout/ModalConfirm";
import DateObject from "../../../utils/DateObject";

function LysineInput(props) {
  const { getNewDataList = () => {}, dataList = [] } = props;
  const dispatch = useDispatch();

  const placeholdertext = "Max 99";
  const thresholdPrice = 99;

  const separatorList = {
    regex: /^(0|[1-9][0-9]{0,2})(\.[0-9]{0,2})?$/,
    incompleteDecimal: /^(0|[1-9][0-9]{0,2})\.$/,
  };

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [sheetDataList, setSheetDataList] = useState([]);

  const [marketPriceObject, setMarketPriceObject] = useState({
    marketPriceDate: new Date(),
    marketPrice: "",
  });

  const resetForm = () => {
    setMarketPriceObject({
      marketPriceDate: new Date(),
      marketPrice: "",
    });
  };

  const LYSINE_MARKET_PRICE_TEMPLATE_URL =
    process.env.REACT_APP_LYSINE_MARKET_PRICE_TEMPLATE_URL || "";

  const checkIsDisableSubmitBtn = () => {
    return (
      marketPriceObject.marketPrice == ""
    );
  };

  const checkDuplicateData = () => {
    const isExisted = dataList.find(
      (item) =>
        item.marketPriceDate ===
        DateObject.convertDateTime(
          marketPriceObject.marketPriceDate,
          "YYYY-MM-DD"
        )
    );
    if (isExisted) {
      setSheetDataList([]);
      setIsConfirmModalOpen(true);
    } else {
      submitForm();
    }
  };

  const uploadExcel = (dataList = []) => {
    setIsConfirmModalOpen(false);
    dispatch(loadAction.displayLoadingOverlay());
    LysineService.insertLysineList(dataList)
      .then((response) => {
        dispatch(
          snackbarAction.openSnackbar({
            options: { variant: "success" },
            message: "Lysine market price data upload completed",
          })
        );
        getNewDataList();
        setSheetDataList([]);
        dispatch(loadAction.hideLoadingOverlay());
      })
      .catch((error) => {
        let newError = { ...error };
        dispatch(
          snackbarAction.openSnackbar({
            options: { variant: "error" },
            message: !newError.response
              ? "An error occurred, please try again"
              : newError.response.data.message,
          })
        );
        dispatch(loadAction.hideLoadingOverlay());
      });
  };

  const submitForm = () => {
    setIsConfirmModalOpen(false);
    dispatch(loadAction.displayLoadingOverlay());
    LysineService.insertLysineObject(marketPriceObject)
      .then((response) => {
        dispatch(
          snackbarAction.openSnackbar({
            options: { variant: "success" },
            message: "Lysine market price has been successfully added",
          })
        );
        getNewDataList();
        resetForm();
        dispatch(loadAction.hideLoadingOverlay());
      })
      .catch((error) => {
        let newError = { ...error };
        dispatch(
          snackbarAction.openSnackbar({
            options: { variant: "error" },
            message: !newError.response
              ? "An error occurred, please try again"
              : newError.response.data.message,
          })
        );
        dispatch(loadAction.hideLoadingOverlay());
      });
  };

  const onDownload = () => {
    window.location.href = LYSINE_MARKET_PRICE_TEMPLATE_URL;
  };

  const onReadExcel = async (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile !== undefined) {
      dispatch(loadAction.displayLoadingOverlay());
      if (acceptFileType.split().indexOf(selectedFile.type) === -1) {
        dispatch(
          snackbarAction.openSnackbar({
            options: { variant: "error" },
            message: "Support file type is .XLSX",
          })
        );
        dispatch(loadAction.hideLoadingOverlay());
      } else {
        let [sheetList, sheetName, sheetHeader] = await FileObject.readExcel(selectedFile);
        if (sheetList.length > 0) {
          const keysTemplate = [
            "marketPriceDate (YYYY-MM-DD)",
            "marketPrice",
          ];
          const isTemplateMatched = keysTemplate.every(element => sheetHeader.includes(element));
          if (!isTemplateMatched) {
            dispatch(
              snackbarAction.openSnackbar({
                options: { variant: "error" },
                message: `Incorrect file, please reupload with the Lysine (Market Price)`,
              })
            );
            dispatch(loadAction.hideLoadingOverlay());
          } else {
            let dateErr = "";
            let fieldErr = "";
            let valueErr = "";
            let ismorethanThreshold = false;
            for (let obj of sheetList) {
              let foundmoreThanThreshold = false;
              dateErr = obj["marketPriceDate (YYYY-MM-DD)"];
              for (let [key, value] of Object.entries(obj)) {
                const valuecheck = parseFloat(value);
                if (
                  key !== "marketPriceDate (YYYY-MM-DD)" &&
                  typeof valuecheck === "number" &&
                  (valuecheck > thresholdPrice || valuecheck < 0)
                ) {
                  fieldErr = key;
                  valueErr = valuecheck;
                  foundmoreThanThreshold = true;
                  break;
                }
              }
              if (foundmoreThanThreshold) {
                ismorethanThreshold = true;
                break;
              }
            }
            if (ismorethanThreshold) {
              dispatch(
                snackbarAction.openSnackbar({
                  options: { variant: "error" },
                  message: `Value of ROW: ${dateErr}, COL: ${fieldErr} is ${valueErr} which is not in range`,
                })
              );
              dispatch(loadAction.hideLoadingOverlay());
            } else {
              const isExisted = dataList.find(
                (item) =>
                  item.marketPriceDate ===
                  sheetList.find(
                    (item) => item["marketPriceDate (YYYY-MM-DD)"]
                  )["marketPriceDate (YYYY-MM-DD)"]
              );
              setSheetDataList(sheetList);
              if (isExisted) {
                setIsConfirmModalOpen(true);
              } else {
                uploadExcel(sheetList);
              }
            }
          }
        } else {
          dispatch(
            snackbarAction.openSnackbar({
              options: { variant: "error" },
              message: "File data empty",
            })
          );
          dispatch(loadAction.hideLoadingOverlay());
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} className="mb-8">
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <Typography variant="h3" style={{ fontWeight: "bold" }}>
            Market price
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6} className="text-right">
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs={6} sm="auto">
              <Button
                className="w-full md:w-auto"
                variant="outlined"
                size="small"
                disableElevation
                color="primary"
                disabled={!LYSINE_MARKET_PRICE_TEMPLATE_URL}
                startIcon={<CloudDownload />}
                onClick={onDownload}
              >
                Download Template
              </Button>
            </Grid>
            <Grid item xs={6} sm="auto">
              <Button
                className="w-full md:w-auto"
                variant="contained"
                size="small"
                disableElevation
                component="label"
                color="primary"
                startIcon={<CloudUpload />}
                onClick={() => {}}
              >
                Upload File
                <input
                  type="file"
                  hidden
                  accept={acceptFileType}
                  onChange={(e) => {
                    onReadExcel(e);
                  }}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <form noValidate autoComplete="off" onSubmit={(e) => {}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={5} sm={2}>
                <Typography variant="body1" className="self-center">
                  Market Price Date
                </Typography>
              </Grid>
              <Grid item xs={7} sm={5} md={4} lg={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    inputVariant="outlined"
                    size="small"
                    label="Date"
                    format="yyyy-MM-dd"
                    name="marketPriceDate"
                    fullWidth
                    onChange={(date) => {
                      setMarketPriceObject({
                        ...marketPriceObject,
                        marketPriceDate: date,
                      });
                    }}
                    value={marketPriceObject["marketPriceDate"]}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={5} sm={2}>
                <Typography variant="body1" className="self-center">
                  Market Price
                </Typography>
              </Grid>
              <Grid item xs={7} sm={5} md={4} lg={3}>
                <TextField
                  label="Unit THB"
                  placeholder={placeholdertext}
                  fullWidth
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  inputProps={{ step: "0.1" }}
                  value={marketPriceObject.marketPrice}
                  onChange={(event) => {
                    const inputNumber = event.target.value;
                    if (separatorList.regex.test(inputNumber)) {
                      if (inputNumber >= 0 && inputNumber <= 99) {
                        setMarketPriceObject({
                          ...marketPriceObject,
                          marketPrice: inputNumber,
                        });
                      }
                    } else if (
                      separatorList.incompleteDecimal.test(inputNumber)
                    ) {
                      setMarketPriceObject({
                        ...marketPriceObject,
                        marketPrice: inputNumber,
                      });
                    } else if (event.target.value === "") {
                      setMarketPriceObject({
                        ...marketPriceObject,
                        marketPrice: "",
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="text-right">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => {
                checkDuplicateData();
              }}
              disabled={checkIsDisableSubmitBtn()}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        <ModalConfirm
          isOpen={isConfirmModalOpen}
          handleOnCancle={(e) => setIsConfirmModalOpen(e)}
          handleOnConfirm={() => {
            sheetDataList?.length > 0
              ? uploadExcel(sheetDataList)
              : submitForm();
          }}
        />
      </form>
    </>
  );
}

export default LysineInput;
