import React, { useEffect, useState } from 'react';
import { Typography, Button, Grid, Modal, Paper, MenuItem, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { loadAction, snackbarAction } from '../../../store/actions';
import DateObject from '../../../utils/DateObject';
import MaterialEventService from '../../../services/MaterialEvent';
import { categoriesList, impactList } from '../../../configs/MaterialEventConfig';

export function ModalUpdateMaterialEventForm(props) {
    const { dataInfo, isOpen, handleOnCancle, getNewDataList = () => {} } = props;

    const dispatch = useDispatch();
    const [eventId, setEventId] = useState(dataInfo['id'])
    const [month, setMonth] = useState(dataInfo['period']?.split("-")[1])
    const [year, setYear] = useState(dataInfo['period']?.split("-")[0])
    const [categories, setCategories] = useState(dataInfo['categories'])
    const [impact, setImpact] = useState(dataInfo['impact'])
    const [event, setEvent] = useState(dataInfo['event'])

    useEffect(() => {
        setEventId(dataInfo['id'])
        setMonth(dataInfo['period']?.split("-")[1])
        setYear(dataInfo['period']?.split("-")[0])
        setCategories(dataInfo['categories'])
        setImpact(dataInfo['impact'])
        setEvent(dataInfo['event'])
        
    }, [dataInfo])
    
    const checkIsDisableSaveBtn = () => {
        return !(month && year && categories && impact && event) 
        
    }

    const onClickSubmitForm = async () => {
        handleOnCancle(false)
        let data = {
            period: `${year}-${month}`,
            categories: categories,
            impact: impact,
            event: event

        }
        dispatch(loadAction.displayLoadingOverlay());
        MaterialEventService.updateMaterialEventObject(eventId, data).then((response) => {
            dispatch(snackbarAction.openSnackbar({ options: { variant: "success" }, message: "Event data has been updated." }))
            getNewDataList()
            dispatch(loadAction.hideLoadingOverlay());
        }).catch((error) => {
            let newError = { ...error };
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "Sorry , there was error." : newError.response.data.message }))
            dispatch(loadAction.hideLoadingOverlay());
        });
    }

    return (
            <Modal open={isOpen} onClose={() => { handleOnCancle(false) }} aria-labelledby="modal-update-form" className="flex justify-center items-center">
                <Paper elevation={0} className="p-10" style={{ maxWidth: '90%', width: 620, outline: 'none', overflowY:'auto', maxHeight:'90vh' }}>
                    <Grid container>
                        <Grid item xs={12} className="pb-4">
                            <Typography variant="h3" style={{ fontWeight: 'bold' }}>Event form</Typography>
                        </Grid>
                        <Grid container item xs={12}>
                            <Grid item xs={12}>
                                <Typography variant="body1" className="self-center pr-2 mb-2 mt-2">Event Year</Typography>
                                <TextField type="tel" label="Year" variant="outlined" size="small" autoComplete="off" inputProps={{maxLength:4}} value={year} onChange={(e) => { setYear(e.target.value) }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" className="self-center pr-2 mb-2 mt-2">Event Month</Typography>
                                <Grid item xs={4}>
                                    <Grid container alignItems='center' justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <TextField label="Month" select margin="dense" variant="outlined" value={month} fullWidth onChange={(e) => { setMonth(e.target.value) }}>
                                                {DateObject.MONTH_OBJECT_LIST.map((monthObject) => {
                                                    return (
                                                        <MenuItem key={monthObject.value} value={monthObject.value}>{monthObject.name}</MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" className="self-center pr-2 mb-2 mt-2">Categories</Typography>
                                <Grid item xs={4}>
                                    <Grid container alignItems='center' justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <TextField label="Categories" select margin="dense" variant="outlined" value={categories} fullWidth onChange={(e) => { setCategories(e.target.value) }}>
                                                {categoriesList.map((name, index) => {
                                                    return (
                                                        <MenuItem key={index} value={name}>{name}</MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" className="self-center pr-2 mb-2 mt-2">Impact</Typography>
                                <Grid item xs={4}>
                                    <Grid container alignItems='center' justifyContent='space-between'>
                                        <Grid item xs={10}>
                                            <TextField label="Impact" select margin="dense" variant="outlined" value={impact} fullWidth onChange={(e) => { setImpact(e.target.value) }}>
                                                {impactList.map((name, index) => {
                                                    return (
                                                        <MenuItem key={index} value={name}>{name}</MenuItem>
                                                    )
                                                })}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body1" className="self-center pr-2 mb-2 mt-2">Event</Typography>
                                <TextField fullWidth type="text" multiline label="Event" variant="outlined" size="medium" autoComplete="off" value={event} onChange={(e) => { setEvent(e.target.value) }} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="text-right pt-4">
                            <Button className="mr-2" onClick={() => { handleOnCancle(false) }}>
                                CANCEL
                            </Button>
                            <Button className="ml-2" variant="contained" color="primary" onClick={() => onClickSubmitForm()} disabled={checkIsDisableSaveBtn()}>
                                SAVE
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Modal>
    )
}