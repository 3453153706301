import ApiProxy from '../utils/ApiProxy';

class Seasonal {

    static async getSeasonalList(materialType) {
        return new Promise(async (resolve, reject) => {
            let seasonalList = await ApiProxy.sendRequest('GET', `seasonals?materialType=${materialType}`).catch((error) => {
                return reject(error);
            });
            return resolve(seasonalList);
        });
    }

}

export default Seasonal;