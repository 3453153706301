const { default: ActionTypes } = require("./actionType");

export function openSnackbar(notification) {
    
    const key = notification.options && notification.options.key;

    return {
        type: ActionTypes.OPEN_SNACKBAR,
        notification: {
            ...notification,
            key: key || new Date().getTime() + Math.random()
        }
    };
};

export const closeSnackbar = key => ({
    type: ActionTypes.CLOSE_SNACKBAR,
    dismissAll: !key,
    key
});

export const removeSnackbar = key => ({
    type: ActionTypes.REMOVE_SNACKBAR,
    key
});
