import ApiProxy from '../utils/ApiProxy';

class InventoryPrice {

    static async getInventoryPriceList(offset = 0, limit = 0, month, year, inventoryType) {
        return new Promise(async (resolve, reject) => {
            let inventoryPriceList = await ApiProxy.sendRequest('GET', `inventory-price?offset=${offset}&limit=${limit}&month=${!month ? '' : month}&year=${!year ? '' : year}&inventoryType=${inventoryType}`).catch((error) => {
                reject(error);
            });
            resolve(inventoryPriceList);
        });
    }

    static async insertInventoryPriceObject(data) {
        return new Promise(async (resolve, reject) => {
            let insertResult = await ApiProxy.sendRequest('POST', 'inventory-price', data).catch((error) => {
                reject(error);
            });
            resolve(insertResult);
        });
    }

    static async insertInventoryPriceList(data) {
        return new Promise(async (resolve, reject) => {
            let insertResult = await ApiProxy.sendRequest('POST', 'inventory-price/upload', data).catch((error) => {
                reject(error);
            });
            resolve(insertResult);
        });
    }

    static async updateInventoryPriceObject(inventoryPriceId, data) {
        return new Promise(async (resolve, reject) => {
            let updateResult = await ApiProxy.sendRequest('PUT', `inventory-price/${inventoryPriceId}`, data).catch((error) => {
                reject(error);
            });
            resolve(updateResult);
        });
    }

    static async deleteInventoryList(data, inventoryType) {
        return new Promise(async (resolve, reject) => {
            let updateResult = await ApiProxy.sendRequest('DELETE', `inventory-price?inventoryType=${inventoryType}`, data).catch((error) => {
                reject(error);
            });
            resolve(updateResult);
        });
    }

    static async downloadDataInventoryPrice(inventoryType, startDate, endDate) {
        return new Promise(async (resolve, reject) => {
            let inventoryPriceExcel = await ApiProxy.exportData('GET', `inventory-price/download?inventoryType=${inventoryType}&startDate=${startDate}&endDate=${endDate}`).catch((error) => { reject(error) });
            resolve(inventoryPriceExcel);
        });
    }

    static async getInventoryPriceObjectByDate(inventoryType, month, year) {
        return new Promise(async (resolve, reject) => {
            let inventoryPriceObject = await ApiProxy.sendRequest('GET', `inventory-price/search?month=${!month ? '' : month}&year=${!year ? '' : year}&inventoryType=${inventoryType}`).catch((error) => {
                reject(error);
            });
            resolve(inventoryPriceObject);
        });
    }

    static async getInventorySummaryList(queryType) {
        return new Promise(async (resolve, reject) => {
            let inventorySummaryList = await ApiProxy.sendRequest('GET', `inventory-price/summary/${queryType}`).catch((error) => {
                reject(error);
            });
            resolve(inventorySummaryList);
        });
    }

    static async downloadDataInventorySummary(queryType, year) {
        return new Promise(async (resolve, reject) => {
            let inventorySummaryExcel = await ApiProxy.exportData('GET', `inventory-price/summary/download/${queryType}?year=${year}`).catch((error) => { reject(error) });
            resolve(inventorySummaryExcel);
        });
    }

    static async getAllWeekInventorySummaryList(startDate, endDate) {
        return new Promise(async (resolve, reject) => {
            let inventorySummaryList = await ApiProxy.sendRequest('GET', `inventory-price/summary?startDate=${startDate}&endDate=${endDate}`).catch((error) => {
                reject(error);
            });
            resolve(inventorySummaryList);
        });
    }

    static async getHighestLowestInventorySummaryList() {
        return new Promise(async (resolve, reject) => {
            let inventorySummaryList = await ApiProxy.sendRequest('GET', `inventory-price/highest-lowest`).catch((error) => {
                reject(error);
            });
            resolve(inventorySummaryList);
        });
    }

}

export default InventoryPrice;