import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Button, Grid, Paper, FormGroup, FormControl, Switch, FormControlLabel, TextField } from '@material-ui/core';
import TapiocaService from '../services/Tapioca';
import InventoryPriceService from '../services/InventoryPrice';
import EnhancedTable from '../components/Table/EnhancedTable';
import { tapiocaResultAction, materialEventAction, loadAction, snackbarAction } from '../store/actions';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import LineChart from '../components/Chart/LineChart'
import DateObject from '../utils/DateObject';
import { ModalDownloadForecastResult } from '../components/DataManagement/ModalForm/ModalDownloadForecastResult';
import SeasonalModal from '../components/Options/SeasonalModal';
import CalculateFunction from '../utils/Calulate';
import RefreshIcon from '@material-ui/icons/Refresh';
import FilterTimeButton from '../components/FilterTimeButton';
import PriceSuggestionResultArea from '../components/Areas/PriceSuggestionResultArea';
import JsonObject from '../utils/JsonObject';

const TapiocaResult = () => {
    const dispatch = useDispatch();

    const [selectedYear, setSelectedYear] = useState(null)
    
    const [filterTapiocaResult, setFilterTapiocaResult] = useState([])
    const [forecastDate, setForecastDate] = useState(DateObject.getDateTime('YYYY-MM-DD'))
    const [inputPrice, setInputPrice] = useState(0)
    const [isCheckedForecastHistory, setIsCheckedForecastHistory] = useState(true)
    const [isCheckedPriceRange, setIsCheckedPriceRange] = useState(true)
    const [isChartDisplayPoint, setIsChartDisplayPoint] = useState(true);

    const [priceSuggestionResultObj, setPriceSuggestionResultObj] = useState(null);

    const [isOpenModalDownloadForecastResult, setIsOpenModalDownloadForecastResult] = useState(false)
    const [isDisplaySeasonalModal, setIsDisplaySeasonalModal] = useState(false);
    const tapiocaResultList = useSelector(state => state.tapiocaResultReducer);


    const todayDate = DateObject.convertDateTime(new Date(), "YYYY-MM-DD")
    const [selectedFilterTime, setSelectedFilterTime] = useState(-12);
    const unit = "THB/KG";

    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);

    useEffect(() => {
        if( tapiocaResultList.length > 0 ) {
            if( !minDate ) {
                setMinDate(tapiocaResultList.filter((item) => item.forecastValue !== null)[0]?.date)
            }
            if( !maxDate ) {
                setMaxDate(DateObject.getLastDayOfMonth(tapiocaResultList[tapiocaResultList.length -1]?.date));
            }
        }
    }, [tapiocaResultList, minDate, maxDate])

    const getTapiocaResultListData = useCallback(() => {
        dispatch(tapiocaResultAction.getTapiocaResultList(""));
        dispatch(materialEventAction.getMaterialEventList("","","","tapioca"));
        setSelectedYear(null)
    }, [dispatch]);

    useEffect(() => {
        getTapiocaResultListData()
    }, [getTapiocaResultListData])

    useEffect(() => {
        if(tapiocaResultList.length > 0 && filterTapiocaResult.length === 0){
            setFilterTapiocaResult(tapiocaResultList)
        }
    }, [tapiocaResultList, filterTapiocaResult])

    const headCellsTapiocaMarketPrice = [
        { id: 'date', label: 'Date', numeric: false, disablePadding: false, isDate: true, isBoolean: false, dateFormat: "YYYY-MM", className: 'w-48', headAlign: 'center', isEditAble: false },
        { id: 'actualPrice', label: `Market Price (${unit})`, numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2 },
        { id: 'forecastLower', label: `Forecast Price Min (${unit})`, numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2 },
        { id: 'forecastValue', label: `Forecast Price Mean (${unit})`, numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2 },
        { id: 'forecastUpper', label: `Forecast Price Max (${unit})`, numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2 },
    ]

    const tapiocaResultTable = () => {
        return (
            <EnhancedTable tableDataList={tapiocaResultList} headCellList={headCellsTapiocaMarketPrice} handleTableClick={() => { }} handleRowSeletion={() => { }} isCheckBox={false} rowSelectedList={[]} defaultOrder="desc" defaultOrderBy="date"/>
        )
    }

    const handleChangeDate = (date) => {
        let newDate = new Date(date)
        let selectedYear = newDate.getFullYear()

        dispatch(loadAction.displayLoadingOverlay())
        setSelectedYear(newDate)
        dispatch(tapiocaResultAction.getTapiocaResultList(selectedYear));
        dispatch(loadAction.hideLoadingOverlay());
    }

    const handleChangeForecastDate = (date) => {
        let newDate = new Date(date)

        setForecastDate(newDate)
    }

    const rightHeaderTapiocaResultSearch = () => {
        return (
            <Grid item xs={12} className="mb-3">
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                views={["year"]}
                                label="Year"
                                format="yyyy"
                                name="tapiocaForecastDate"
                                onChange={(date) => { handleChangeDate(date) }}
                                value={selectedYear}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <Button className="w-full md:w-auto mt-5 ml-2" variant="outlined" size="small" disableElevation color="primary"  startIcon={<RefreshIcon/>} onClick={ () => { getTapiocaResultListData()}}>
                            Refresh
                        </Button>
                    </Grid>
                    <Grid item className="mt-3">
                        <Button variant="contained" color="primary" onClick={() => { setIsOpenModalDownloadForecastResult(true) }}>
                            Download Data
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const handleDownloadTapiocaForecast = (year) => {
        dispatch(loadAction.displayLoadingOverlay())
        TapiocaService.downloadDataResult(year).then((response) => {
            dispatch(snackbarAction.openSnackbar({ options: { variant: "success" }, message: "Download Completed" }))
            dispatch(loadAction.hideLoadingOverlay());
        }).catch((error) => {
            let newError = { ...error }
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            dispatch(loadAction.hideLoadingOverlay());
        });
    }

    const calculatePriceSuggest = async() => {
        dispatch(loadAction.displayLoadingOverlay())
        const selectedForecastMonth = DateObject.convertDateTime(forecastDate, 'MM');
        const selectedForecastYear = DateObject.convertDateTime(forecastDate, 'YYYY');
        InventoryPriceService.getInventoryPriceObjectByDate("tapioca", selectedForecastMonth , selectedForecastYear ).then((inventoryPriceData) => {
            TapiocaService.getTapiocaResultObjectByDate(selectedForecastMonth, selectedForecastYear).then( async(tapiocaResultData) => {
                const average = CalculateFunction.findAverageFromObjectList(inventoryPriceData['data'], 'targetPrice');
                const suggestionPercentage = await CalculateFunction.calPriceSuggestion(inputPrice, tapiocaResultData["data"]?.["forecastValue"], tapiocaResultData["data"]?.["forecastRmse"]);
                const diffPercentage = (inputPrice - average) / average * 100;

                const suggestionObj = {
                    userInputPrice: inputPrice,
                    targetPrice: average,
                    minValue: tapiocaResultData["data"]?.["forecastLower"],
                    maxValue: tapiocaResultData["data"]?.["forecastUpper"],
                    forecastValue: tapiocaResultData["data"]?.["forecastValue"],
                    forecastRmse: tapiocaResultData["data"]?.["forecastRmse"],
                    suggestionPercentage: suggestionPercentage,
                    diffPercentage: diffPercentage,
                    chartScale: JsonObject.parseChartRate(tapiocaResultData["data"]?.["forecastScaleJson"])
                }
                setPriceSuggestionResultObj(suggestionObj);
                dispatch(loadAction.hideLoadingOverlay());
            }).catch((error)=> {
                let newError = { ...error }
                dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
                dispatch(loadAction.hideLoadingOverlay());
                setPriceSuggestionResultObj(null);
            })
        }).catch((error) => {
            let newError = { ...error }
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            dispatch(loadAction.hideLoadingOverlay());
            setPriceSuggestionResultObj(null);
        });
    }

    const genNewDateWithMonth = (date, months) => {
        date.setMonth(date.getMonth() + months);
        return date;
    }

    const filterChartDataList = () => {
        if( isCheckedForecastHistory ) {
            return [...filterTapiocaResult];
        }

        const chartDataList = [...filterTapiocaResult].map(cornResultObj => {
            const returnObj = {...cornResultObj};
            if( (new Date()).getTime() > (new Date(returnObj['date'])).getTime() ) {
                returnObj['forecastLower'] = null;
                returnObj['forecastUpper'] = null;
                returnObj['forecastValue'] = null;
            }
            return returnObj;
        });
        return chartDataList;
    };

    const getStartDate = () => {
        if( selectedFilterTime === 0 ) {
            return filterTapiocaResult[0]?.date;
        }
        
        const lastDataDate = filterTapiocaResult[filterTapiocaResult.length-1]?.date;
        const endDate = new Date(lastDataDate);
        const startDate = genNewDateWithMonth(endDate, selectedFilterTime)
        const filterCornResult = filterTapiocaResult.filter(item => {
            return new Date(item.date) >= startDate;
        })
        return filterCornResult[0]?.date;
    }

    const getArgumentConstantLineLabel = () => {
        if( filterTapiocaResult.length > 0 ) {
            const currentObj = filterTapiocaResult.filter(itemObj => {
                return itemObj['date'] === DateObject.getDateTime('YYYY-MM-01');
            });
            
            let returnValue = `Current Month:<br>`;
            if( isCheckedPriceRange ) {
                returnValue+= `Max: ${currentObj[0]['forecastUpper'].toFixed(2)}<br>`;
            }
            returnValue+= `Forecast: ${currentObj[0]['forecastValue'].toFixed(2)}<br>`;
            if( isCheckedPriceRange ) {
                returnValue+= `Min: ${currentObj[0]['forecastLower'].toFixed(2)}`;
            }
            
            return returnValue;
        }
    }

    const handleFilterTimeButtonClick = (newSelectedFilterTime) => {
        setSelectedFilterTime(newSelectedFilterTime);
        if( newSelectedFilterTime === 0 ) {
            setIsChartDisplayPoint(false);
        } else {
            setIsChartDisplayPoint(true);
        }
    }
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
                <Paper elevation={0} className="p-2 md:p-4 h-full">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs="auto"><Typography variant="h3" style={{ fontWeight: 'bold' }}>Tapioca Forecasting Result</Typography></Grid>
                                <Grid item xs="auto">
                                    <Button size="small" variant="outlined" color="primary" onClick={() => setIsDisplaySeasonalModal(!isDisplaySeasonalModal)}>
                                        Crop Calendar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                                <Grid item xs="auto">
                                    <FormControl component="fieldset">
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                value="start"
                                                control={<Switch color="primary" onChange={(e) => { setIsCheckedForecastHistory(e.target.checked) }} checked={isCheckedForecastHistory}/>}
                                                label="Forecast History"
                                                labelPlacement="start"
                                            />
                                            <FormControlLabel
                                                value="start"
                                                control={<Switch color="primary" onChange={(e) => { setIsCheckedPriceRange(e.target.checked) }} checked={isCheckedPriceRange}/>}
                                                label="Price Range"
                                                labelPlacement="start"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs="auto">
                                    <FilterTimeButton
                                        selectedValue={selectedFilterTime}
                                        handleButtonClick={handleFilterTimeButtonClick}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <LineChart
                                argumentRangeStart={getStartDate()}
                                argumentRangeEnd={filterTapiocaResult[filterTapiocaResult.length - 1]?.['date']}
                                dataList={filterChartDataList()}
                                argumentField="date"
                                argumentType="datetime"
                                argumentFormat="monthAndYear"
                                argumentTitle="Month"
                                argumentConstantLineList={[
                                    { label: getArgumentConstantLineLabel(), value: todayDate, color: '#8c8cff', style: 'dash' }
                                ]}
                                valueTitle={`Market price (${unit})`}
                                valueFieldList={
                                    isCheckedPriceRange ? 
                                        [ 
                                            { key: 'rangeArea', rangeValue1Field: 'forecastLower', rangeValue2Field: 'forecastUpper', type: 'rangeArea', color: '#b0daff', isShowInLegend: false },
                                            { key: "actualPrice", valueField: "actualPrice", name: 'Market Price', isShowInLegend: true },
                                            { key: "forecastValue", valueField: "forecastValue", name: 'Forecast Price', isShowInLegend: true },
                                            { key: "forecastLower", valueField: "forecastLower", name: 'Lower bounded', isShowInLegend: true },
                                            { key: "forecastUpper", valueField: "forecastUpper", name: 'Upper bounded', isShowInLegend: true }
                                        ]
                                    : 
                                        [    
                                            { key: "actualPrice", valueField: "actualPrice", name: 'Market Price', isShowInLegend: true },
                                            { key: "forecastValue", valueField: "forecastValue", name: 'Forecast Price', isShowInLegend: true }                                    
                                        ]
                                }
                                isStartFromZero={false}
                                isDisplayPoint={isChartDisplayPoint}
                                materialType="tapioca"
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} lg={4}>
                <Paper elevation={0} className="p-2 md:p-4 h-full">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <Typography variant="h3" style={{ fontWeight: 'bold' }}>Price Suggestion</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <PriceSuggestionResultArea 
                                priceSuggestionObj={priceSuggestionResultObj}
                                unit={unit}
                            />
                        </Grid>
                        <Grid item xs={12} className="flex py-2">
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <Typography variant="body1" className="self-center">Month</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            views={["year", "month"]}
                                            label="Date"
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            autoOk
                                            inputVariant="outlined"
                                            size="small"
                                            format="MM/yyyy"
                                            name="forecastDate"
                                            onChange={(date) => { handleChangeForecastDate(date) }}
                                            value={forecastDate}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <Typography variant="body1" className="self-center">Input price</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField type="number" label={`Price (${unit})`} variant="outlined" size="small" autoComplete="off" value={inputPrice} onChange={(e) => { setInputPrice(e.target.value) }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="text-right">
                            <Button variant="contained" color="primary" onClick={() => { calculatePriceSuggest() }} disabled={!inputPrice}>
                                Calculate
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={0} className="p-10 md:p-4 h-full">
                    <Grid container>
                        <Grid item xs={12}>
                            {rightHeaderTapiocaResultSearch()}
                            {tapiocaResultTable()}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <ModalDownloadForecastResult isOpen={isOpenModalDownloadForecastResult} handleOnCancle={(e) => setIsOpenModalDownloadForecastResult(e)} handleDownload={handleDownloadTapiocaForecast} />
            <ModalDownloadForecastResult isOpen={isOpenModalDownloadForecastResult} handleOnCancle={(e) => setIsOpenModalDownloadForecastResult(e)} handleDownload={handleDownloadTapiocaForecast}/>
            <SeasonalModal isDisplayDialog={isDisplaySeasonalModal} materialType="Tapioca" handleCloseDialog={() => setIsDisplaySeasonalModal(!isDisplaySeasonalModal)} />
        </Grid>
    );
}

export default TapiocaResult;