import { 
    Grid,
} from '@material-ui/core';
import { useEffect, useState, memo } from 'react';

import DateObject from '../../utils/DateObject';
import SummaryLineChart from '../Chart/SummaryLineChart';
import SummaryLinearGauge from '../Chart/SummaryLinearGauge';
import ForecastAccuracyPercentChart from '../Chart/ForecastAccuracyPercentChart';

const initForecastObject = {
    actualPrice: null,
    date: null,
    forecastLower: null,
    forecastRmse: null,
    forecastUpper: null,
    forecastValue: null
};

const PriceSummaryArea = (props) => {
    const { forecastObjectList, forecastAccuracyObj, currentMonthInventoryObj, previousMonthInventoryObj, highestLowestInventoryObj, highestLowestActualPriceObj, previousMonthCovertedInventoryObj, customCurrentMonthForecastObj=null } = props;

    const [previousTwoMonthForecastObj, setPreviousTwoMonthForecastObj] = useState({...initForecastObject});
    const [previousMonthForecastObj, setPreviousMonthForecastObj] = useState({...initForecastObject});
    const [currentMonthForecastObj, setCurrentMonthForecastObj] = useState({...initForecastObject});
    const [nextMonthForecastObj, setNextMonthForecastObj] = useState({...initForecastObject});
    const [nextThreeMonthForecastObj, setNextThreeMonthForecastObj] = useState({...initForecastObject});
    const [nextFiveMonthForecastObj, setNextFiveMonthForecastObj] = useState({...initForecastObject});
    const [chartDataList, setChartDataList] = useState([]);
    const currentDate = DateObject.getDateTime('YYYY-MM-DD');
    const countDecimal = 2;

    const setStatueFunctionForForecastObject = {
        "5": setNextFiveMonthForecastObj,
        "3": setNextThreeMonthForecastObj,
        "1": setNextMonthForecastObj,
        "0": setCurrentMonthForecastObj,
        "-1": setPreviousMonthForecastObj,
        "-2": setPreviousTwoMonthForecastObj
    };

    useEffect(() => {
        prepareStateForecastObject();
        prepareChartDataList();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forecastObjectList]);

    const prepareStateForecastObject = () => {
        for( const forecastObj of forecastObjectList ) {
            const forecastDate = forecastObj['date'];

            const monthDiff = DateObject.calculatemonthDifference(currentDate, forecastDate);
            if( setStatueFunctionForForecastObject.hasOwnProperty(monthDiff) ) {
                setStatueFunctionForForecastObject[monthDiff](forecastObj);
            }
        }
    }

    const prepareChartDataList = () => {
        const newChartDataList = [];
        if( forecastObjectList.length > 0 ) {
            for( const forecastObj of forecastObjectList ) {
                const forecastDate = forecastObj['date'];
    
                const monthDiff = DateObject.calculatemonthDifference(currentDate, forecastDate);
                if( monthDiff <= 0 ) {
                    newChartDataList.push(forecastObj);
                }
            }
        }
        setChartDataList(newChartDataList);
    }

    const calculateDifferencePercentage = (firstValue, secondValue, disableTextColor=false) => {
        const salt = Math.pow(10, countDecimal);
        const differenceValue = ((secondValue - firstValue) / firstValue) * 100;
        const roundDifferenceValue  = Math.round(differenceValue * salt) / salt;
        
        if( isNaN(roundDifferenceValue) || isNaN(firstValue) || isNaN(secondValue) || !firstValue || !secondValue ) {
            if( !disableTextColor ) {
                return <span className="text-red-600">NaN</span>;
            } else {
                return NaN;
            }
        }

        if( roundDifferenceValue > 0 ) {
            if( !disableTextColor ) {
                return <span className="text-green-700">+{roundDifferenceValue}%</span>;
            } else {
                return `+${roundDifferenceValue}%`;
            }
        } else {
            if( !disableTextColor ) {
                return <span className="text-red-600">-{Math.abs(roundDifferenceValue)}%</span>;
            } else {
                return `-${Math.abs(roundDifferenceValue)}%`;
            }
        }
    }

    const renderCurrentMonthForecastArea = () => {
        
        const currentMonthForecast = customCurrentMonthForecastObj && customCurrentMonthForecastObj.hasOwnProperty('forecastValue') ? customCurrentMonthForecastObj['forecastValue'] : currentMonthForecastObj['forecastValue'];

        let resultStr = '';
        let backgroundColor = '';
        if( isNaN(currentMonthInventoryObj['targetPrice']) || !currentMonthInventoryObj['targetPrice'] ) {
            resultStr = 'Target price not available';
            backgroundColor = '#e7e9eb';
        } else if( currentMonthForecast < currentMonthInventoryObj['targetPrice'] ) {
            resultStr = `Lower than Target ${ calculateDifferencePercentage(currentMonthForecast, currentMonthInventoryObj['targetPrice'], true) }`;
            backgroundColor = '#93c47d';
        } else {
            resultStr = `Higher than Target ${ calculateDifferencePercentage(currentMonthForecast, currentMonthInventoryObj['targetPrice'], true) }`;
            backgroundColor = '#e06666';
        }

        return (
            <Grid container spacing={1} className="text-center">
                <Grid item xs={12} className="text-sm">Forecast This Month</Grid>
                <Grid item xs={12} className="py-2" style={{ backgroundColor: backgroundColor }}>
                    <div className="text-xl font-bold mb-1">{ currentMonthForecast } ({ calculateDifferencePercentage(previousMonthForecastObj['forecastValue'], currentMonthForecast, true) })</div>
                    <div className="text-xs mb-1">{resultStr}</div>
                    <div className="text-xs">(Target is { currentMonthInventoryObj['targetPrice'] || "NaN" })</div>
                </Grid>
            </Grid>
        );
    }
    
    return (
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={4}>
                <div className="text-center text-sm font-semibold mb-2">% Accuracy</div>
                <ForecastAccuracyPercentChart
                    value={forecastAccuracyObj ? forecastAccuracyObj['accuracy'] : 0}
                />
            </Grid>
            <Grid item xs={12} sm={8}>
                { renderCurrentMonthForecastArea() }
            </Grid>
            <Grid item xs={12}>
                <div className="mx-auto w-10/12 text-sm sm:w-5/6 md:w-4/6 xl:w-5/6 2xl:w-4/6">
                    <Grid container>
                        <Grid item xs={7} sm={9} md={8}>Last Months</Grid>
                        <Grid item xs={5} sm={3} md={4}>{ previousMonthForecastObj['forecastValue'] } ({ calculateDifferencePercentage(previousTwoMonthForecastObj['forecastValue'], previousMonthForecastObj['forecastValue']) })</Grid>
                        
                        <Grid item xs={7} sm={9} md={8}>Next Months</Grid>
                        <Grid item xs={5} sm={3} md={4}>{ nextMonthForecastObj['forecastValue'] } ({ calculateDifferencePercentage(currentMonthForecastObj['forecastValue'], nextMonthForecastObj['forecastValue']) })</Grid>
                    
                        <Grid item xs={7} sm={9} md={8}>Next 3 Months</Grid>
                        <Grid item xs={5} sm={3} md={4}>{ nextThreeMonthForecastObj['forecastValue'] } ({ calculateDifferencePercentage(currentMonthForecastObj['forecastValue'], nextThreeMonthForecastObj['forecastValue']) })</Grid>
                    
                        <Grid item xs={7} sm={9} md={8}>Next 5 Months</Grid>
                        <Grid item xs={5} sm={3} md={4}>{ nextFiveMonthForecastObj['forecastValue'] } ({ calculateDifferencePercentage(currentMonthForecastObj['forecastValue'], nextFiveMonthForecastObj['forecastValue']) })</Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} className="text-sm">
                <div className="text-center font-semibold mb-2">Market Price last 12 Months</div>
                <div className="mx-auto w-full text-sm sm:w-5/6 md:w-4/6 xl:w-5/6 2xl:w-5/6">
                    <Grid container spacing={1} className="mx-auto w-full text-sm sm:w-5/6 md:w-4/6 xl:w-5/6 2xl:w-5/6">
                        <Grid item xs={12} sm={9} md={8}>
                            <SummaryLineChart
                                dataList={chartDataList}
                                argumentField="date"
                                argumentType="datetime"
                                argumentFormat="month"
                                valueFieldList={[
                                    { key: "actualPrice", valueField: "actualPrice", isShowInLegend: false }, 
                                ]}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={4}>
                            <div className="text-xs">Market Price last month</div>
                            <div className="flex">
                                <div>
                                    <div className="text-xl font-bold">{ previousMonthForecastObj['actualPrice'] }</div>
                                    <div className="text-xs text-right">({ calculateDifferencePercentage(previousTwoMonthForecastObj['forecastValue'], previousMonthForecastObj['forecastValue']) })</div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className="border-2 border-yellow-400 rounded mx-auto w-full text-sm p-2">
                    <div className="text-center font-semibold">Historical Market Price ({DateObject.convertDateTime(previousMonthForecastObj['date'], 'Month')})</div>
                    <SummaryLinearGauge
                        value={previousMonthForecastObj['actualPrice']}
                        monthOfValue={DateObject.convertDateTime(previousMonthForecastObj['date'], 'ShortMon')}
                        valueColor="#6d9eeb"
                        subvalues={previousMonthCovertedInventoryObj['targetPrice'] ? previousMonthCovertedInventoryObj['targetPrice'] : null}
                        scaleColor="#ffd966"
                        startValue={highestLowestActualPriceObj ? highestLowestActualPriceObj['minActualPrice'] : 0}
                        endValue={highestLowestActualPriceObj ? highestLowestActualPriceObj['maxActualPrice'] : 100}
                    />
                </div>
            </Grid>
            <Grid item xs={12}>
                <div className="border-2 rounded mx-auto w-full text-sm p-2" style={{ borderColor: '#e69138' }}>
                    <div className="text-center font-semibold">Historical Inventory Price ({DateObject.convertDateTime(previousMonthForecastObj['date'], 'Month')})</div>
                    <SummaryLinearGauge
                        value={previousMonthInventoryObj['inventoryPrice'] ? previousMonthInventoryObj['inventoryPrice'] : 0}
                        monthOfValue={DateObject.convertDateTime(previousMonthForecastObj['date'], 'ShortMon')}
                        valueColor="#6d9eeb"
                        subvalues={previousMonthInventoryObj['targetPrice'] ? previousMonthInventoryObj['targetPrice'] : null}
                        scaleColor="#e69138"
                        startValue={highestLowestInventoryObj['minInventoryPrice']}
                        endValue={highestLowestInventoryObj['maxInventoryPrice']}
                    />
                </div>
            </Grid>
        </Grid>
    );
}

export default memo(PriceSummaryArea);