import ApiProxy from '../utils/ApiProxy';

class Corn {

    static async getCornList(offset = 0, limit = 0, month, year) {
        return new Promise(async (resolve, reject) => {
            let cornList = await ApiProxy.sendRequest('GET', `corns?offset=${offset}&limit=${limit}&month=${!month ? '' : month}&year=${!year ? '' : year}`).catch((error) => {
                reject(error);
            });
            resolve(cornList);
        });
    }

    static async insertCornObject(data) {
        return new Promise(async (resolve, reject) => {
            let insertResult = await ApiProxy.sendRequest('POST', 'corns', data).catch((error) => {
                reject(error);
            });
            resolve(insertResult);
        });
    }

    static async insertCornList(data) {
        return new Promise(async (resolve, reject) => {
            let insertResult = await ApiProxy.sendRequest('POST', 'corns/upload', data).catch((error) => {
                reject(error);
            });
            resolve(insertResult);
        });
    }
    
    static async updateCornObject(cornId, data) {
        return new Promise(async (resolve, reject) => {
            let updateResult = await ApiProxy.sendRequest('PUT', `corns/${cornId}`, data).catch((error) => {
                reject(error);
            });
            resolve(updateResult);
        });
    }

    static async deleteCornList(data) {
        return new Promise(async (resolve, reject) => {
            let updateResult = await ApiProxy.sendRequest('DELETE', 'corns', data).catch((error) => {
                reject(error);
            });
            resolve(updateResult);
        });
    }

    
    static async downloadDataMarketPrice(startDate, endDate) {
        return new Promise(async (resolve, reject) => {
            let marketPriceExcel = await ApiProxy.exportData('GET', `corns/download?startDate=${startDate}&endDate=${endDate}`).catch((error) => { reject(error) });
            resolve(marketPriceExcel);
        });
    }

    static async getCornResultList(year) {
        return new Promise(async (resolve, reject) => {
            let cornResultList = await ApiProxy.sendRequest('GET', `corns/result?year=${year ? year : ""}`).catch((error) => {
                reject(error);
            });
            resolve(cornResultList);
        });
    }

    static async downloadDataResult(year) {
        return new Promise(async (resolve, reject) => {
            let resultExcel = await ApiProxy.exportData('GET', `corns/download/result?year=${year}`).catch((error) => { reject(error) });
            resolve(resultExcel);
        });
    }

    static async getCornResultObjectByDate(month, year) {
        return new Promise(async (resolve, reject) => {
            let cornResultObject = await ApiProxy.sendRequest('GET', `corns/result/search?month=${!month ? '' : month}&year=${!year ? '' : year}`).catch((error) => {
                reject(error);
            });
            resolve(cornResultObject);
        });
    }

    static async getCornResultSummaryList(startDate) {
        return new Promise(async (resolve, reject) => {
            let cornResultSummaryList = await ApiProxy.sendRequest('GET', `corns/summary?startDate=${startDate}`).catch((error) => {
                return reject(error);
            });
            return resolve(cornResultSummaryList);
        });
    }

    static async getHighestLowestActualPriceObject() {
        return new Promise(async (resolve, reject) => {
            let cornResultSummaryList = await ApiProxy.sendRequest('GET', `corns/highest-lowest/actual-price`).catch((error) => {
                return reject(error);
            });
            return resolve(cornResultSummaryList);
        });
    }

}

export default Corn;