import HomeLayout from '../layouts/HomeLayout';
import LoginLayout from '../layouts/LoginLayout';
import ForgetPassword from '../pages/ForgetPassword';
import ResetPassword from '../pages/ResetPassword';
import UserPage from '../pages/User';
import LoginPage from "../pages/Login";
import PageNotFound from "../pages/PageNotFound";
import UserFormPage from '../pages/UserForm';
import ProfilePage from '../pages/Profile';
import HomePage from '../pages/Home';

import SoybeanMealPage from '../pages/SoybeanMeal';
import WheatPage from '../pages/Wheat';
import CornPage from '../pages/Corn';
import TapiocaPage from '../pages/Tapioca';
import LysinePage from '../pages/Lysine';

import SoybeanMealResultPage from '../pages/SoybeanMealResult';
import WheatResultPage from '../pages/WheatResult';
import CornResultPage from '../pages/CornResult';
import TapiocaResultPage from '../pages/TapiocaResult';
import LysineResultPage from '../pages/LysineResult';
import InventorySummaryPage from '../pages/InventorySummary';
import YearComparison from '../pages/YearComparison';

const components = {
    login: {
        url: "/login",
        page: LoginPage,
        layout: LoginLayout
    },
    home: {
        url: "/home",
        page: HomePage,
        layout: HomeLayout,
        isFirstPage: true
    },
    userForm: {
        url: "/user/:id",
        page: UserFormPage,
        layout: HomeLayout
    },
    user: {
        url: "/user",
        page: UserPage,
        layout: HomeLayout,
    },
    forgetPassword: {
        url: "/forget-password",
        page: ForgetPassword,
        layout: LoginLayout
    },
    resetPassword: {
        url: "/reset-password",
        page: ResetPassword,
        layout: LoginLayout
    },
    profile: {
        url: "/profile",
        page: ProfilePage,
        layout: HomeLayout
    },
    soybeanMeal: {
        url: "/data-management/soybean-meal",
        page: SoybeanMealPage,
        layout: HomeLayout
    },
    wheat: {
        url: "/data-management/wheat",
        page: WheatPage,
        layout: HomeLayout
    },
    corn: {
        url: "/data-management/corn",
        page: CornPage,
        layout: HomeLayout
    },
    tapioca: {
        url: "/data-management/tapioca",
        page: TapiocaPage,
        layout: HomeLayout
    },
    lysine: {
        url: "/data-management/l-lysine",
        page: LysinePage,
        layout: HomeLayout
    },
    soybeanMealResult: {
        url: "/data-purchasing/soybean-meal",
        page: SoybeanMealResultPage,
        layout: HomeLayout
    },
    wheatResult: {
        url: "/data-purchasing/wheat",
        page: WheatResultPage,
        layout: HomeLayout
    },
    cornResult: {
        url: "/data-purchasing/corn",
        page: CornResultPage,
        layout: HomeLayout
    },
    tapiocaResult: {
        url: "/data-purchasing/tapioca",
        page: TapiocaResultPage,
        layout: HomeLayout
    },
    lysineResult: {
        url: "/data-purchasing/l-lysine",
        page: LysineResultPage,
        layout: HomeLayout
    },
    inventorySummary: {
        url: "/data-purchasing/inventory-summary",
        page: InventorySummaryPage,
        layout: HomeLayout
    },
    yearComparison: {
        url: "/data-purchasing/year-comparison",
        page: YearComparison,
        layout: HomeLayout
    },
    pageNotFound: {
        page: PageNotFound
    }
};

const {
    login,
    home,
    userForm,
    user,
    forgetPassword,
    resetPassword,
    profile,
    soybeanMeal,
    wheat,
    corn,
    tapioca,
    lysine,
    soybeanMealResult,
    wheatResult,
    cornResult,
    tapiocaResult,
    lysineResult,
    inventorySummary,
    yearComparison,
    pageNotFound,
} = components;

const router = {
    reporter: [login, forgetPassword, resetPassword, profile, soybeanMealResult, wheatResult, cornResult, tapiocaResult, lysineResult, inventorySummary, yearComparison, home, pageNotFound],
    editor: [login, forgetPassword, resetPassword, profile, soybeanMeal, wheat, corn, tapioca, lysine, soybeanMealResult, wheatResult, cornResult, tapiocaResult, lysineResult, inventorySummary, yearComparison, home, pageNotFound],
    admin: [login, user,userForm, forgetPassword, resetPassword, profile, soybeanMeal, wheat, corn, tapioca, lysine, soybeanMealResult, wheatResult, cornResult, tapiocaResult, lysineResult, inventorySummary, yearComparison, home, pageNotFound],
    guest: [login, profile, forgetPassword, resetPassword, pageNotFound],
    dev: [login, user, userForm, forgetPassword, resetPassword, profile, soybeanMeal, wheat, corn, tapioca, lysine, soybeanMealResult, wheatResult, cornResult, tapiocaResult, lysineResult, inventorySummary, yearComparison, home, pageNotFound],
    TFG_Admin: [login, user, userForm, forgetPassword, resetPassword, profile, corn, cornResult, home, pageNotFound],
    TFG_Editor: [login, forgetPassword, resetPassword, profile, corn, cornResult, home, pageNotFound],
    TFG_Viewer: [login, forgetPassword, resetPassword, profile, cornResult, home, pageNotFound],

};

export default router;

let defaultUrl = '/';
for(const key in components) {
    const pageObj = components[key];
    if( pageObj.hasOwnProperty('isFirstPage') && pageObj['isFirstPage'] ) {
        defaultUrl = pageObj['url'];
        break;
    }
}
export const firstPageUrl = defaultUrl;