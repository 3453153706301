
import React from 'react';
import { useLocation } from 'react-router-dom';

import useStyles from '../assets/styles';
import NavBar from '../components/MainLayout/NavBar';
import SideBar from '../components/MainLayout/SideBar';

function HomeLayout({ children }) {
    const classes = useStyles();
    const location = useLocation();
    const [activeMenu] = React.useState(location.pathname);

    return (
        <div className={classes.root}>
            <NavBar isLoginPage={false} position="fixed"></NavBar>
            <SideBar activeMenu={activeMenu} />
            <main className={classes.childrenOfHomeLayout} >
                <div className={classes.toolbar} />
                {children}
            </main>
        </div>
    );
}

export default HomeLayout;