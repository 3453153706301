import React from 'react';
import Chart, {
    ArgumentAxis,
    Series,
    Label,
    Grid,
    ValueAxis,
    CommonSeriesSettings,
    Point,
    Size,
    Tick,
    TickInterval,
    Tooltip,
    Format,
    Animation
} from 'devextreme-react/chart';

const SummaryLineChart = (props) => {

    const { dataList, argumentField, argumentFormat, argumentType, valueFieldList } = props;

    const customizeArgumentText = ({ valueText }) => {
        return `${valueText.substring(0, 3)}`;
    }

    return (
        <Chart
            dataSource={dataList}
        >
            <Size height={150} />
            <CommonSeriesSettings argumentField={argumentField} type="line" hoverMode="none"/>
            {
                valueFieldList.map((item) => {
                    return <Series key={item.key} valueField={item.valueField} showInLegend={item.isShowInLegend}>
                        <Point visible={false}/>
                    </Series>;
                })
            }
            <ArgumentAxis argumentType={argumentType} axisDivisionFactor={10}>
                <TickInterval months={1} />
                <Label format={argumentFormat} indentFromAxis={5} rotationAngle={270} overlappingBehavior="rotate" customizeText={customizeArgumentText} />
                <Tick visible={true} />
                <Grid visible={true} />
            </ArgumentAxis>
            <ValueAxis showZero={false} visible={false}>
                <Label visible={true} />
                <Tick visible={false} />
            </ValueAxis>
            <Tooltip enabled={true}>
                <Format type="fixedPoint" precision={2}/>
            </Tooltip>
            <Animation enabled={false} />
        </Chart>
    );
}

export default SummaryLineChart;