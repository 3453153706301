import React from 'react'
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';

const Routes = () => {
    const { isLoading } = useSelector(state => state.loadingReducer);
    return (
        isLoading ? (
            <div className="flex w-full h-full justify-center items-center text-lg font-bold z-50 absolute top-0 left-0 bg-opacity-75 bg-white">
                <div className="text-center">
                    <CircularProgress color="primary" thickness={5} size="4rem" />
                    <div className="mt-2">Loading.....</div>
                </div>
            </div> ) 
        : null
    );
};

export default Routes;