import { loadAction } from './index'
import Tapioca from "../../services/Tapioca";
const { default: ActionTypes } = require("./actionType");


export function getTapiocaResultList(year) {
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return Tapioca.getTapiocaResultList(year)
        .then((tapiocaResult) => {
            dispatch({
                type: ActionTypes.GET_TAPIOCA_RESULT_LIST,
                payload: tapiocaResult.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        })
        .catch(() => {
            dispatch({
                type: ActionTypes.GET_TAPIOCA_RESULT_LIST,
                payload: []
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}

export function getTapiocaResultSummaryList(startDate) {
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return Tapioca.getTapiocaResultSummaryList(startDate).then((soybeanMealResult) => {
            dispatch({
                type: ActionTypes.GET_TAPIOCA_RESULT_LIST,
                payload: soybeanMealResult.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        }).catch(() => {
            dispatch({
                type: ActionTypes.GET_TAPIOCA_RESULT_LIST,
                payload: []
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}


