import { loadAction } from './index'
import UserService from "../../services/User";
const { default: ActionTypes } = require("./actionType");

export function authenUser() {
    return (dispatch) => {
        return UserService.getUserProfile()
        .then((userProfile) => {
            let payload = {
                userId: userProfile['data']['userId'],
                username: userProfile['data']['username'],
                firstName: userProfile['data']['firstName'],
                lastName: userProfile['data']['lastName'],
                email: userProfile['data']['email'],
                mobileNumber: userProfile['data']['mobileNumber'],
                userLevel: userProfile['data']['user_level.userLevel'],
                lastLogin: userProfile['data']['lastLogin'],
            };
            dispatch({
                type: ActionTypes.AUTHEN_USER,
                payload: payload
            });
        })
        .catch((error) => {
            dispatch({
                type: ActionTypes.AUTHEN_USER,
                payload: { userId: '', userLevel: 'guest' }
            });
        });
    }
}

export function getUserProfile() {
    return {
        type: ActionTypes.GET_USER_PROFILE_OBJECT
    }
}

export function getUserList(offset, limit) {
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return UserService.getUserList(offset, limit)
        .then((users) => {
            dispatch({
                type: ActionTypes.GET_USER_LIST,
                payload: users.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        })
        .catch(() => {
            dispatch({
                type: ActionTypes.GET_USER_LIST,
                payload: []
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}

export function getUserObject(userId){
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return UserService.getUserObject(userId)
        .then((user) => {
            dispatch({
                type: ActionTypes.GET_USER_OBJECT,
                payload: user.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        })
        .catch((error) => {
            dispatch({
                type: ActionTypes.GET_USER_OBJECT,
                payload: {userId: null}
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}

export function getUserLevelList(){
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return UserService.getUserLevelList()
        .then((userLevels) => {
            dispatch({
                type: ActionTypes.GET_USER_LEVEL_LIST,
                payload: userLevels.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        })
        .catch(() => {
            dispatch({
                type: ActionTypes.GET_USER_LEVEL_LIST,
                payload: []
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}