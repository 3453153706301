class JsonObject {

    static parseChartRate = (string) => {
        if( !string || string === '' ) {
            return {};
        }
        return JSON.parse(string.replace(/(0\.[1-9]): ([0-9]+\.+[0-9]+)/g, "\"$1\":$2"));
    }

}

export default JsonObject