import React from 'react';
import useStyles from '../assets/styles';

const LoginLayout = ({ children }) => {
    const classes = useStyles();
    
    return (
        <div className={classes.root} style={{flexDirection:'column'}}>
            <main className={`h-full flex-1`}>
                {children}
            </main>
        </div>
    );
}
export default LoginLayout;