import React from 'react';
import Chart, {
    ArgumentAxis,
    Legend,
    Label,
    Title,
    Tick,
    CommonSeriesSettings,
    SeriesTemplate,
    Animation,
    Tooltip
} from 'devextreme-react/chart';

/**
 * install: npm install devextreme@21.1 devextreme-react@21.1
 * Ref: https://js.devexpress.com/Demos/WidgetsGallery/Demo/Charts/Line/React/Light/
 * 
 * @param
 *  dataSourceList:
 *      array ของ object ที่จะนำไปแสดงในกราฟ โดย object จะอยู่ในรูปแบบ 
 *         { 
 *              key1: value1, 
 *              key2: value2, 
 *              key3: value3,
 *              ...
 *          }
 *      Ex: [ { "areaCropRound": "Special crop", "action": "Plant", "startAction": "2021-03-01", "endAction": "2021-04-29" }, { "areaCropRound": "Special crop", "action": "Harvest", "startAction": "2021-01-31", "endAction": "2021-02-28" }, ...]
 * 
 *  titleText:
 *      ชื่อของ chart
 *      Ex: Example Chart
 * 
 *  titleSubtitle:
 *      sub ชื่อของ chart
 *      Ex: Example Chart
 * 
 *  argumentField:
 *      ชื่อ key ที่จะใช้เป็น label ในแกน y
 *      Ex: key1
 * 
 *  argumentTitle:
 *      ชื่อ title ที่แสดงในแนวแกน x
 *      Ex: เดือน
 * 
 *  argumentFormat:
 *      รูปแบบการแสดงผลของ label ในแกน x ดูเพิ่มเติมได้ที่ https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxChart/Configuration/argumentAxis/label/#format
 *      Ex: month
 * 
 *  valueField:
 *      ชื่อ key ที่จะใช้แสดงเป็นแท่งในกราฟ
 *      Ex: key1
 * 
 *  legendTitle:
 *      ชื่อของ Legend ที่แสดงอยู่ข้างล่าง
 *      Ex: Example Legend
 * 
 *  rangeValue1Field:
 *      ค่าเริ่มต้นในแนวแกน x ที่ใช้แสดงกราฟ
 *      Ex: '2021-01-01', 10
 * 
 *  rangeValue2Field:
 *      ค่าสิ้นสุดในแนวแกน x ที่ใช้แสดงกราฟ
 *      Ex: '2021-12-01', 100
 * 
 * @returns 
 */

const HorizontalRangeBarChart = (props) => {

    const { dataSourceList, titleText, titleSubtitle, argumentField, argumentTitle, argumentFormat, valueField, legendTitle, rangeValue1Field, rangeValue2Field } = props;

    return (
        <Chart id="chart" dataSource={dataSourceList} barGroupPadding={0.8} rotated={true}>
            <ArgumentAxis title={argumentTitle}>
                <Label format={argumentFormat} />
                <Tick visible={false} />
            </ArgumentAxis>
            <Title text={titleText} subtitle={titleSubtitle} />
            <CommonSeriesSettings
                type="rangeBar"
                argumentField={argumentField}
                rangeValue1Field={rangeValue1Field}
                rangeValue2Field={rangeValue2Field}
                barOverlapGroup={argumentField}
            >
            </CommonSeriesSettings>
            <Legend verticalAlignment="bottom" horizontalAlignment="center">
                <Title text={legendTitle} />
            </Legend>
            <SeriesTemplate nameField={valueField} />
            <Animation enabled={false} />
            <Tooltip enabled={true} zIndex={10000000} format="monthAndDay" />
        </Chart>
    );
}

export default HorizontalRangeBarChart;