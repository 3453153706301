import { loadAction } from './index'
import SoybeanMeal from "../../services/SoybeanMeal";
const { default: ActionTypes } = require("./actionType");


export function getSoybeanMealResultList(year) {
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return SoybeanMeal.getSoybeanMealResultList(year)
        .then((soybeanMealResult) => {
            dispatch({
                type: ActionTypes.GET_SOYBEANMEAL_RESULT_LIST,
                payload: soybeanMealResult.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        })
        .catch(() => {
            dispatch({
                type: ActionTypes.GET_SOYBEANMEAL_RESULT_LIST,
                payload: []
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}


