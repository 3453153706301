import red from '@material-ui/core/colors/red';

const palette = {
    primary: {
        light: '#8EDCFF',
        main: '#3D85C6',
        dark: '#244D7C',
        contrastText: '#fff',
    },
    secondary: {
        light: '#fbde82',
        main: '#f8be0e',
        dark: '#f88d03',
        contrastText: '#000',
    },
    pacificBlue: {  
        DEFAULT: '#1696d1',
        50: '#e1f4fb',
        100: '#b3e3f5',
        200: '#82d1ee',
        300: '#55bee7',
        400: '#35b1e3',
        500: '#1ca3df',
        600: '#1696d1',
        700: '#0e83be',
        800: '#0c72aa',
        900: '#045388'
    },
    mayaBlue: {
        50: '#e0f1fe',
        100: '#b4dbfe',
        200: '#81c6fd',
        300: '#42affd',
        400: '#009efe',
        500: '#008efb',
        600: '#0080ec',
        700: '#006ed9',
        800: '#005dc7',
        900: '#003ea6',
    },
    yellow: {
        50: '#fef7e1',
        100: '#fdeab2',
        200: '#fbdd81',
        300: '#fad14d',
        400: '#f9c527',
        500: '#f8bb06',
        600: '#f8ae00',
        700: '#f89b00',
        800: '#f78a00',
        900: '#f76b00',
    },
    red: red
};

export default palette;