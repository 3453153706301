import React, { useCallback, useEffect, useState } from 'react'
import { Button, Grid, Typography, Link, Hidden } from '@material-ui/core'
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loadAction, userAction } from '../store/actions';
import Validator from '../utils/Validator'
import useStyles from '../assets/styles';
import InputPassword from '../components/InputPassword';
import UserService from '../services/User';
import AlertModal from '../components/Options/AlertModal';

import logo from '../assets/images/thaifood-logo.png';

function ResetPassword() {

    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [dataTokenObject, setDataTokenObject] = useState(null)
    const [errorMessage, setErrorMessage] = useState('');
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const comparePassword = () => {
        let isMatching = true
        if (newPassword !== confirmPassword) {
            let message = "New password and confirm password are not matching"
            isMatching = false
            return { isMatching, message }
        }
        return { isMatching }
    }

    const checkDisabledBtn = () => {
        return [newPassword, confirmPassword].some(ele => ele === "")
    }

    const actionSubmit = () => {
        history.push("/login")
    }

    const checkTokenExp = useCallback(async (token) => {
        dispatch(loadAction.displayLoadingOverlay());
        let tokenPropertyObject = await UserService.getExpToken(token)
        setDataTokenObject(tokenPropertyObject['data'])
        dispatch(loadAction.hideLoadingOverlay());
    }, [dispatch])

    useEffect(() => {
        const queryString = new URLSearchParams(location.search);
        const token = queryString.get('token');
        checkTokenExp(token)
    }, [location.search, checkTokenExp])

    const submitForm = async (event) => {

        event.preventDefault();
        setErrorMessage(null);
        dispatch(loadAction.displayLoadingOverlay());

        let resultValidateObject = Validator.validateData(newPassword, 'password', {}, "Password");
        let matchingObject = comparePassword()

        if (!resultValidateObject["isValid"] || !matchingObject["isMatching"]) {
            resultValidateObject["isValid"] ? setErrorMessage(matchingObject["message"]) : setErrorMessage(resultValidateObject["message"]);
            dispatch(loadAction.hideLoadingOverlay());
            return null
        }

        const queryString = new URLSearchParams(location.search);
        const token = queryString.get('token');
        checkTokenExp(token)

        let resetResult = await UserService.resetPassword(newPassword, dataTokenObject).catch((error) => {
            setErrorMessage(error.message);
        });

        if (resetResult && resetResult['status'].toLowerCase() === 'success') {
            if (resetResult['status'].toLowerCase() === 'success') {
                dispatch(userAction.authenUser()).then(() => {
                    setIsOpenModal(true)
                })
            }
        }
        dispatch(loadAction.hideLoadingOverlay());
    };

    const displayResetPassword = () => {

        if (dataTokenObject === null) return null;

        return (
            <form onSubmit={submitForm} className="h-full">
                <div className="h-full bg-white rounded-lg shadow-2xl flex items-center">
                    { dataTokenObject?.token !== false ? (
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <img alt="Thaifood Logo" src={logo} className="h-32 mx-auto" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h4' align="center">Reset Password</Typography>
                            </Grid>
                            <Grid item xs={12} className="h-12">
                                <Typography color="error" align="center">
                                    {errorMessage}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" spacing={2}>
                                    <Grid item xs={8}>
                                        <Typography variant="subtitle2" color="primary" align="center">
                                            Enter new password
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <InputPassword
                                            password={newPassword}
                                            setPassword={setNewPassword}
                                            labelPassword="New password"
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <InputPassword
                                            password={confirmPassword}
                                            setPassword={setConfirmPassword}
                                            labelPassword="Confirm password"
                                        />
                                    </Grid>
                                    <Grid item xs={8} className="text-center">
                                        <Link className={`cursor-pointer ${classes.link}`} variant="body2" onClick={() => history.push("/login")}>
                                            Sign in
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="text-center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    disabled={checkDisabledBtn()}
                                    className="w-40"
                                >
                                    Reset Password
                                </Button>
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <Typography variant='h4' align="center">The token has been expired or used, please request again.</Typography>
                            </Grid>
                            <Grid item xs={12} className="text-center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    onClick={() => history.push("/forget-password")}
                                >
                                    Forget Password
                                </Button>
                            </Grid>
                        </Grid>
                    ) }
                    
                </div>
            </form>
        );

    }

    return (
        <Grid container className="h-full" justifyContent="center">
            {isOpenModal ? <AlertModal title={"Message:"} content={errorMessage || "Your Password has been reset."} action={actionSubmit} buttonName={"Login"} /> : null}
            <Hidden xsDown>
                <Grid item xs={false} sm={6} md={6} lg={8} className="flex h-full">
                    <img src="/assets/images/login_cover.jpg" alt="Login" className="object-cover w-full"/>
                </Grid>
            </Hidden>
            <Grid item xs={12} sm={6} md={6} lg={4}>
                { displayResetPassword() }
            </Grid>
        </Grid >
    )
}

export default ResetPassword
