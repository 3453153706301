import React, { useEffect, useState } from "react";
import { Typography, Button, Grid, TextField } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { loadAction, snackbarAction } from "../../../store/actions";
import InventoryPriceService from "../../../services/InventoryPrice";
import { useDispatch } from "react-redux";
import { CloudDownload, CloudUpload } from "@material-ui/icons";
import FileObject from "../../../utils/FileObject";
import { acceptFileType } from "../../../configs/AcceptFileType";
import DateObject from "../../../utils/DateObject";
import ModalConfirm from "../../MainLayout/ModalConfirm";

function InventoryPriceInput(props) {
  const {
    inputType,
    getNewDataList = () => {},
    unit = "Unit THB/KG",
    dataList = [],
  } = props;
  const dispatch = useDispatch();
  const separatorList = {
    regex: /^(0|[1-9][0-9]{0,2})(\.[0-9]{0,2})?$/,
    incompleteDecimal: /^(0|[1-9][0-9]{0,2})\.$/,
  };

  const [inventoryPriceObject, setInventoryPriceObject] = useState({
    inventoryPriceDate: new Date(),
    inventoryType: inputType,
    targetPrice: "",
    inventoryPrice: "",
    week: `week` + DateObject.findWeekInMonth(new Date()),
  });
  const [inventoryPriceTemplateUrl, setInventoryPriceTemplateUrl] =
    useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [sheetDataList, setSheetDataList] = useState([]);

  useEffect(() => {
    if (inputType === "corn") {
      setInventoryPriceTemplateUrl(
        process.env.REACT_APP_CORN_INVENTORY_PRICE_TEMPLATE_URL
      );
    } else if (inputType === "wheat") {
      setInventoryPriceTemplateUrl(
        process.env.REACT_APP_WHEAT_INVENTORY_PRICE_TEMPLATE_URL
      );
    } else if (inputType === "tapioca") {
      setInventoryPriceTemplateUrl(
        process.env.REACT_APP_TAPIOCA_INVENTORY_PRICE_TEMPLATE_URL
      );
    } else if (inputType === "lysine") {
      setInventoryPriceTemplateUrl(
        process.env.REACT_APP_LYSINE_INVENTORY_PRICE_TEMPLATE_URL
      );
    } else if (inputType === "soybeanMeal") {
      setInventoryPriceTemplateUrl(
        process.env.REACT_APP_SOYBEAN_MEAL_INVENTORY_PRICE_TEMPLATE_URL
      );
    } else {
      setInventoryPriceTemplateUrl("");
    }
  }, [inputType]);

  const checkIsDisableSubmitBtn = () => {
    return (
      inventoryPriceObject.targetPrice == "" &&
      inventoryPriceObject.inventoryPrice == ""
    );
  };

  const resetForm = () => {
    setInventoryPriceObject({
      inventoryPriceDate: new Date(),
      inventoryType: inputType,
      targetPrice: "",
      inventoryPrice: "",
      week: `week` + DateObject.findWeekInMonth(new Date()),
    });
  };

  const checkDuplicateData = () => {
    const isExisted = dataList.find(
      (item) =>
        item.inventoryType === inputType &&
        item.inventoryPriceDate ===
          DateObject.convertDateTime(
            inventoryPriceObject.inventoryPriceDate,
            "YYYY-MM-DD"
          )
    );
    if (isExisted) {
      setSheetDataList([]);
      setIsConfirmModalOpen(true);
    } else {
      submitForm();
    }
  };

  const uploadExcel = (dataList = []) => {
    setIsConfirmModalOpen(false);
    dispatch(loadAction.displayLoadingOverlay());
    InventoryPriceService.insertInventoryPriceList({
      dataList: dataList,
      inventoryType: inputType,
    })
      .then((response) => {
        dispatch(
          snackbarAction.openSnackbar({
            options: { variant: "success" },
            message: `${inputType} inventory price data upload completed`,
          })
        );
        setSheetDataList([]);
        getNewDataList();
        dispatch(loadAction.hideLoadingOverlay());
      })
      .catch((error) => {
        let newError = { ...error };
        dispatch(
          snackbarAction.openSnackbar({
            options: { variant: "error" },
            message: !newError.response
              ? "An error occurred, please try again"
              : newError.response.data.message,
          })
        );
        dispatch(loadAction.hideLoadingOverlay());
      });
  };

  const submitForm = () => {
    setIsConfirmModalOpen(false);
    dispatch(loadAction.displayLoadingOverlay());
    InventoryPriceService.insertInventoryPriceObject(inventoryPriceObject)
      .then((response) => {
        dispatch(loadAction.displayLoadingOverlay());
        dispatch(
          snackbarAction.openSnackbar({
            options: { variant: "success" },
            message: "Inventory Price has been successfully added",
          })
        );
        getNewDataList();
        resetForm();
        dispatch(loadAction.hideLoadingOverlay());
      })
      .catch((error) => {
        let newError = { ...error };
        dispatch(
          snackbarAction.openSnackbar({
            options: { variant: "error" },
            message: !newError.response
              ? "An error occurred, please try again"
              : newError.response.data.message,
          })
        );
        dispatch(loadAction.hideLoadingOverlay());
      });
  };

  const onDownload = () => {
    window.location.href = inventoryPriceTemplateUrl;
  };

  const onReadExcel = async (e) => {
    const selectedFile = e.target.files[0];
    const newinputtype = inputType.charAt(0).toUpperCase() + inputType.slice(1);
    if (selectedFile !== undefined) {
      dispatch(loadAction.displayLoadingOverlay());
      if (acceptFileType.split().indexOf(selectedFile.type) === -1) {
        dispatch(
          snackbarAction.openSnackbar({
            options: { variant: "error" },
            message: "Support file type is .XLSX",
          })
        );
        dispatch(loadAction.hideLoadingOverlay());
      } else {
        let [sheetList, sheetName, sheetHeader] = await FileObject.readExcel(
          selectedFile
        );
        if (sheetList.length > 0) {
          if (inputType === sheetName) {
            const keysTemplate = ["targetPrice", "inventoryPrice"];
            const isTemplateMatched = keysTemplate.every((element) =>
              sheetHeader.includes(element)
            );
            if (!isTemplateMatched) {
              dispatch(
                snackbarAction.openSnackbar({
                  options: { variant: "error" },
                  message: `Incorrect file, please reupload with the ${newinputtype} (Inventory Price)`,
                })
              );
              dispatch(loadAction.hideLoadingOverlay());
            } else {
              let dateErr = "";
              let fieldErr = "";
              let valueErr = "";
              let ismorethanThreshold = false;
              let thresholdPrice = 99;
              if (inputType === "wheat") {
                thresholdPrice = 999;
              }
              for (let obj of sheetList) {
                let foundmoreThanThreshold = false;
                dateErr = obj["inventoryPriceDate (YYYY-MM-DD)"];
                for (let [key, value] of Object.entries(obj)) {
                  const valuecheck = parseFloat(value);
                  if (
                    key !== "inventoryPriceDate (YYYY-MM-DD)" &&
                    typeof valuecheck === "number" &&
                    (valuecheck > thresholdPrice || valuecheck < 0)
                  ) {
                    fieldErr = key;
                    valueErr = valuecheck;
                    foundmoreThanThreshold = true;
                    break;
                  }
                }
                if (foundmoreThanThreshold) {
                  ismorethanThreshold = true;
                  break;
                }
              }
              if (ismorethanThreshold) {
                dispatch(
                  snackbarAction.openSnackbar({
                    options: { variant: "error" },
                    message: `Value of ROW: ${dateErr}, COL: ${fieldErr} is ${valueErr} which is not in range`,
                  })
                );
                dispatch(loadAction.hideLoadingOverlay());
              } else {
                const isExisted = dataList.find(
                  (item) =>
                    item.inventoryType === inputType &&
                    item.inventoryPriceDate ===
                      sheetList.find(
                        (item) => item["inventoryPriceDate (YYYY-MM-DD)"]
                      )["inventoryPriceDate (YYYY-MM-DD)"]
                );
                setSheetDataList(sheetList);
                if (isExisted) {
                  setIsConfirmModalOpen(true);
                } else {
                  uploadExcel(sheetList);
                }
              }
            }
          } else {
            dispatch(
              snackbarAction.openSnackbar({
                options: { variant: "error" },
                message: `Incorrect file, please reupload with the ${newinputtype} (Inventory Price)`,
              })
            );
            dispatch(loadAction.hideLoadingOverlay());
          }
        } else {
          dispatch(
            snackbarAction.openSnackbar({
              options: { variant: "error" },
              message: `File data empty`,
            })
          );
          dispatch(loadAction.hideLoadingOverlay());
        }
      }
    }
  };

  return (
    <>
      <Grid container spacing={2} className="mb-8">
        <Grid item xs={12} sm={6} md={12} lg={6}>
          <Typography variant="h3" style={{ fontWeight: "bold" }}>
            Inventory Price
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={12} lg={6} className="text-right">
          <Grid container spacing={1} justifyContent="flex-end">
            <Grid item xs={6} sm="auto">
              <Button
                className="w-full md:w-auto"
                variant="outlined"
                size="small"
                disableElevation
                color="primary"
                disabled={!inventoryPriceTemplateUrl}
                startIcon={<CloudDownload />}
                onClick={onDownload}
              >
                Download Template
              </Button>
            </Grid>
            <Grid item xs={6} sm="auto">
              <Button
                className="w-full md:w-auto"
                variant="contained"
                size="small"
                disableElevation
                component="label"
                color="primary"
                startIcon={<CloudUpload />}
                onClick={() => {}}
              >
                Upload File
                <input
                  type="file"
                  hidden
                  accept={acceptFileType}
                  onChange={(e) => {
                    onReadExcel(e);
                  }}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <form noValidate autoComplete="off" onSubmit={(e) => {}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={5} sm={2}>
                <Typography variant="body1" className="self-center">
                  Inventory Price Date
                </Typography>
              </Grid>
              <Grid item xs={7} sm={5} md={4} lg={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    inputVariant="outlined"
                    size="small"
                    label="Date"
                    format="dd/MM/yyyy"
                    name="inventoryPriceDate"
                    fullWidth
                    onChange={(date) => {
                      setInventoryPriceObject({
                        ...inventoryPriceObject,
                        inventoryPriceDate: date,
                        week: `week` + DateObject.findWeekInMonth(date),
                      });
                    }}
                    value={inventoryPriceObject["inventoryPriceDate"]}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={5} sm={2}>
                <Typography variant="body1" className="self-center">
                  Week
                </Typography>
              </Grid>
              <Grid item xs={7} sm={5} md={4} lg={3}>
                <TextField
                  label="Week"
                  disabled
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={inventoryPriceObject.week}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={5} sm={4} md={4}>
                <Typography variant="body1" className="self-center">
                  Target Price
                </Typography>
              </Grid>
              <Grid item xs={7} sm={8} lg={6}>
                <TextField
                  label={unit}
                  placeholder={inputType === "wheat" ? "Max 999" : "Max 99"}
                  fullWidth
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  inputProps={{ step: "0.1" }}
                  value={inventoryPriceObject.targetPrice}
                  onChange={(event) => {
                    const inputNumber = event.target.value;
                    if (separatorList.regex.test(inputNumber)) {
                      if (
                        inputNumber >= 0 &&
                        inputNumber <= 99 &&
                        inputType !== "wheat"
                      ) {
                        setInventoryPriceObject({
                          ...inventoryPriceObject,
                          targetPrice: inputNumber,
                        });
                      } else if (
                        inputNumber >= 0 &&
                        inputNumber <= 999 &&
                        inputType === "wheat"
                      ) {
                        setInventoryPriceObject({
                          ...inventoryPriceObject,
                          targetPrice: inputNumber,
                        });
                      }
                    } else if (
                      separatorList.incompleteDecimal.test(inputNumber)
                    ) {
                      setInventoryPriceObject({
                        ...inventoryPriceObject,
                        targetPrice: inputNumber,
                      });
                    } else if (event.target.value === "") {
                      setInventoryPriceObject({
                        ...inventoryPriceObject,
                        targetPrice: "",
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={5} sm={4} md={4}>
                <Typography variant="body1" className="self-center">
                  Inventory Price
                </Typography>
              </Grid>
              <Grid item xs={7} sm={8} lg={6}>
                <TextField
                  label={unit}
                  placeholder={inputType === "wheat" ? "Max 999" : "Max 99"}
                  fullWidth
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  inputProps={{ step: "0.1" }}
                  value={inventoryPriceObject.inventoryPrice}
                  onChange={(event) => {
                    const inputNumber = event.target.value;
                    if (separatorList.regex.test(inputNumber)) {
                      if (
                        inputNumber >= 0 &&
                        inputNumber <= 99 &&
                        inputType !== "wheat"
                      ) {
                        setInventoryPriceObject({
                          ...inventoryPriceObject,
                          inventoryPrice: inputNumber,
                        });
                      } else if (
                        inputNumber >= 0 &&
                        inputNumber <= 999 &&
                        inputType === "wheat"
                      ) {
                        setInventoryPriceObject({
                          ...inventoryPriceObject,
                          inventoryPrice: inputNumber,
                        });
                      }
                    } else if (
                      separatorList.incompleteDecimal.test(inputNumber)
                    ) {
                      setInventoryPriceObject({
                        ...inventoryPriceObject,
                        inventoryPrice: inputNumber,
                      });
                    } else if (event.target.value === "") {
                      setInventoryPriceObject({
                        ...inventoryPriceObject,
                        inventoryPrice: "",
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="text-right">
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={checkIsDisableSubmitBtn()}
              onClick={() => {
                checkDuplicateData();
              }}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      <ModalConfirm
        isOpen={isConfirmModalOpen}
        handleOnCancle={(e) => setIsConfirmModalOpen(e)}
        handleOnConfirm={() => {
          sheetDataList?.length > 0 ? uploadExcel(sheetDataList) : submitForm();
        }}
      />
    </>
  );
}

export default InventoryPriceInput;
