const XLSX = require("xlsx");

class FileObject {

    static async readExcel(selectedFile) {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(selectedFile);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;
                const workBook = XLSX.read(bufferArray, { type: "buffer" });
                const workSheetName = workBook.SheetNames[0];
                const workSheet = workBook.Sheets[workSheetName];
                const dataList = XLSX.utils.sheet_to_json(workSheet);
                const headers = [];
                const dataheader = XLSX.utils.sheet_to_json(workSheet, { header: 1 });
                dataheader.forEach((row, index) => {
                    if (index === 0) {
                        row.forEach((header, columnIndex) => {
                            headers.push(header);
                        });
                    }
                });
                resolve([dataList, workSheetName, headers ]);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    }
}


export default FileObject;