import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import {
    IconButton,
    InputAdornment,
    TextField
} from "@material-ui/core";

function InputPassword(props) {

    const [isShowPassword, setIsShowPassword] = useState(false);

    const { password, setPassword, labelPassword } = props

    const handlePasswordVisibility = () => {
        setIsShowPassword(!isShowPassword);
    };

    return (
        <TextField
            name="password"
            type={isShowPassword ? "text" : "password"}
            label={labelPassword || "Password"}
            autoComplete="on"
            variant="outlined"
            size='small'
            required
            fullWidth
            onChange={(event) => setPassword(event.target.value)}
            value={password}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            id={`${labelPassword || "password"} open-visibility-password`}
                            aria-label="toggle password visibility"
                            onClick={handlePasswordVisibility}
                        >
                            {isShowPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    )
}

export default InputPassword
