import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { loadAction, snackbarAction } from "../../../store/actions";
import CornService from "../../../services/Corn";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateObject from "../../../utils/DateObject";

export function ModalUpdateCornForm(props) {
  const {
    dataInfo,
    isOpen,
    handleOnCancle,
    getNewDataList = () => {},
    dataList = [],
  } = props;

  const placeholdertext = "Max 99";

  const dispatch = useDispatch();
  const [cornId, setCornId] = useState(dataInfo["id"]);
  const [date, setDate] = useState(dataInfo["marketPriceDate"]);
  const [betagroLopburiPrice, setBetagroLopburiPrice] = useState(
    dataInfo["betagroLopburiPrice"]
  );
  const [betagoPhraPradaeng, setBetagoPhraPradaeng] = useState(
    dataInfo["betagoPhraPradaeng"]
  );
  const [cpSirachaPrice, setCpSirachaPrice] = useState(
    dataInfo["cpSirachaPrice"]
  );
  const [cpKabinBuriPrice, setCpKabinBuriPrice] = useState(
    dataInfo["cpKabinBuriPrice"]
  );
  const [cpRatchaburiPrice, setCpRatchaburiPrice] = useState(
    dataInfo["cpRatchaburiPrice"]
  );
  const [cpPakThongChaiPrice, setCpPakThongChaiPrice] = useState(
    dataInfo["cpPakThongChaiPrice"]
  );
  const [cpKokToomPrice, setCpKokToomPrice] = useState(
    dataInfo["cpKokToomPrice"]
  );

  const separatorList = {
    regex: /^(0|[1-9][0-9]{0,2})(\.[0-9]{0,2})?$/,
    incompleteDecimal: /^(0|[1-9][0-9]{0,2})\.$/,
  };

  useEffect(() => {
    setCornId(dataInfo["id"]);
    setDate(dataInfo["marketPriceDate"]);
    setBetagroLopburiPrice(dataInfo["betagroLopburiPrice"]);
    setBetagoPhraPradaeng(dataInfo["betagoPhraPradaeng"]);
    setCpSirachaPrice(dataInfo["cpSirachaPrice"]);
    setCpKabinBuriPrice(dataInfo["cpKabinBuriPrice"]);
    setCpRatchaburiPrice(dataInfo["cpRatchaburiPrice"]);
    setCpPakThongChaiPrice(dataInfo["cpPakThongChaiPrice"]);
    setCpKokToomPrice(dataInfo["cpKokToomPrice"]);
  }, [dataInfo]);

  const checkIsDisableSaveBtn = () => {
    return (
      betagroLopburiPrice < 0 ||
      betagoPhraPradaeng < 0 ||
      cpSirachaPrice < 0 ||
      cpKabinBuriPrice < 0 ||
      cpRatchaburiPrice < 0 ||
      cpKokToomPrice < 0 ||
      cpPakThongChaiPrice < 0
    );
  };

  const onClickSubmitForm = async () => {
    const isExisted = dataList.find((item) => {
      return (
        item.marketPriceDate ===
          DateObject.convertDateTime(date, "YYYY-MM-DD") && item.id !== cornId
      );
    });
    if (isExisted) {
      dispatch(
        snackbarAction.openSnackbar({
          options: { variant: "error" },
          message: "Cannot save change due to duplicated date",
        })
      );
      dispatch(loadAction.hideLoadingOverlay());
    } else {
      handleOnCancle(false);
      let data = {
        marketPriceDate: date,
        betagroLopburiPrice: betagroLopburiPrice,
        betagoPhraPradaeng: betagoPhraPradaeng,
        cpSirachaPrice: cpSirachaPrice,
        cpKabinBuriPrice: cpKabinBuriPrice,
        cpRatchaburiPrice: cpRatchaburiPrice,
        cpPakThongChaiPrice: cpPakThongChaiPrice,
        cpKokToomPrice: cpKokToomPrice,
      };
      dispatch(loadAction.displayLoadingOverlay());
      CornService.updateCornObject(cornId, data)
        .then((response) => {
          dispatch(
            snackbarAction.openSnackbar({
              options: { variant: "success" },
              message: " Market price data has been updated",
            })
          );
          getNewDataList();
          dispatch(loadAction.hideLoadingOverlay());
        })
        .catch((error) => {
          let newError = { ...error };
          dispatch(
            snackbarAction.openSnackbar({
              options: { variant: "error" },
              message: !newError.response
                ? "An error occurred, please try again"
                : newError.response.data.message,
            })
          );
          dispatch(loadAction.hideLoadingOverlay());
        });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleOnCancle(false);
      }}
      aria-labelledby="modal-update-form"
      className="flex justify-center items-center"
    >
      <Paper
        elevation={0}
        className="p-10"
        style={{ maxWidth: "90%", width: 620, outline: "none" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} className="pb-4">
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              Corn form
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={5} sm={4} md={4}>
                <Typography variant="body1" className="self-center">
                  Market Price Date
                </Typography>
              </Grid>
              <Grid item xs={7} sm={8} lg={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    inputVariant="outlined"
                    size="small"
                    label="Date"
                    format="yyyy-MM-dd"
                    name="marketPriceDate"
                    fullWidth
                    onChange={(date) => {
                      setDate(date);
                    }}
                    value={date}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={5} sm={4} md={4}>
                <Typography variant="body1" className="self-center">
                  Betagro Lopburi Price
                </Typography>
              </Grid>
              <Grid item xs={7} sm={8} lg={6}>
                <TextField
                  label="Unit THB"
                  placeholder={placeholdertext}
                  fullWidth
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  inputProps={{ step: "0.1" }}
                  value={betagroLopburiPrice}
                  onChange={(e) => {
                    const inputNumber = e.target.value;
                    if (separatorList.regex.test(inputNumber)) {
                      if (inputNumber >= 0 && inputNumber <= 99) {
                        setBetagroLopburiPrice(inputNumber);
                      }
                    } else if (
                      separatorList.incompleteDecimal.test(inputNumber)
                    ) {
                      setBetagroLopburiPrice(inputNumber);
                    } else if (e.target.value === "") {
                      setBetagroLopburiPrice("");
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={5} sm={4} md={4}>
                <Typography variant="body1" className="self-center">
                  Betagro Phra Pradaeng Price
                </Typography>
              </Grid>
              <Grid item xs={7} sm={8} lg={6}>
                <TextField
                  label="Unit THB"
                  placeholder={placeholdertext}
                  fullWidth
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  inputProps={{ step: "0.1" }}
                  value={betagoPhraPradaeng}
                  onChange={(e) => {
                    const inputNumber = e.target.value;
                    if (separatorList.regex.test(inputNumber)) {
                      if (inputNumber >= 0 && inputNumber <= 99) {
                        setBetagoPhraPradaeng(inputNumber);
                      }
                    } else if (
                      separatorList.incompleteDecimal.test(inputNumber)
                    ) {
                      setBetagoPhraPradaeng(inputNumber);
                    } else if (e.target.value === "") {
                      setBetagoPhraPradaeng("");
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={5} sm={4} md={4}>
                <Typography variant="body1" className="self-center">
                  CP Siracha Price
                </Typography>
              </Grid>
              <Grid item xs={7} sm={8} lg={6}>
                <TextField
                  label="Unit THB"
                  placeholder={placeholdertext}
                  fullWidth
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  inputProps={{ step: "0.1" }}
                  value={cpSirachaPrice}
                  onChange={(e) => {
                    const inputNumber = e.target.value;
                    if (separatorList.regex.test(inputNumber)) {
                      if (inputNumber >= 0 && inputNumber <= 99) {
                        setCpSirachaPrice(inputNumber);
                      }
                    } else if (
                      separatorList.incompleteDecimal.test(inputNumber)
                    ) {
                      setCpSirachaPrice(inputNumber);
                    } else if (e.target.value === "") {
                      setCpSirachaPrice("");
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={5} sm={4} md={4}>
                <Typography variant="body1" className="self-center">
                  CP Kabin Buri Price
                </Typography>
              </Grid>
              <Grid item xs={7} sm={8} lg={6}>
                <TextField
                  label="Unit THB"
                  placeholder={placeholdertext}
                  fullWidth
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  inputProps={{ step: "0.1" }}
                  value={cpKabinBuriPrice}
                  onChange={(e) => {
                    const inputNumber = e.target.value;
                    if (separatorList.regex.test(inputNumber)) {
                      if (inputNumber >= 0 && inputNumber <= 99) {
                        setCpKabinBuriPrice(inputNumber);
                      }
                    } else if (
                      separatorList.incompleteDecimal.test(inputNumber)
                    ) {
                      setCpKabinBuriPrice(inputNumber);
                    } else if (e.target.value === "") {
                      setCpKabinBuriPrice("");
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={5} sm={4} md={4}>
                <Typography variant="body1" className="self-center">
                  CP Ratchaburi Price
                </Typography>
              </Grid>
              <Grid item xs={7} sm={8} lg={6}>
                <TextField
                  label="Unit THB"
                  placeholder={placeholdertext}
                  fullWidth
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  inputProps={{ step: "0.1" }}
                  value={cpRatchaburiPrice}
                  onChange={(e) => {
                    const inputNumber = e.target.value;
                    if (separatorList.regex.test(inputNumber)) {
                      if (inputNumber >= 0 && inputNumber <= 99) {
                        setCpRatchaburiPrice(inputNumber);
                      }
                    } else if (
                      separatorList.incompleteDecimal.test(inputNumber)
                    ) {
                      setCpRatchaburiPrice(inputNumber);
                    } else if (e.target.value === "") {
                      setCpRatchaburiPrice("");
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={5} sm={4} md={4}>
                <Typography variant="body1" className="self-center">
                  CP Pak Thong Chai Price
                </Typography>
              </Grid>
              <Grid item xs={7} sm={8} lg={6}>
                <TextField
                  label="Unit THB"
                  placeholder={placeholdertext}
                  fullWidth
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  inputProps={{ step: "0.1" }}
                  value={cpPakThongChaiPrice}
                  onChange={(e) => {
                    const inputNumber = e.target.value;
                    if (separatorList.regex.test(inputNumber)) {
                      if (inputNumber >= 0 && inputNumber <= 99) {
                        setCpPakThongChaiPrice(inputNumber);
                      }
                    } else if (
                      separatorList.incompleteDecimal.test(inputNumber)
                    ) {
                      setCpPakThongChaiPrice(inputNumber);
                    } else if (e.target.value === "") {
                      setCpPakThongChaiPrice("");
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={5} sm={4} md={4}>
                <Typography variant="body1" className="self-center">
                  CP Kok Toom Price
                </Typography>
              </Grid>
              <Grid item xs={7} sm={8} lg={6}>
                <TextField
                  label="Unit THB"
                  placeholder={placeholdertext}
                  fullWidth
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  inputProps={{ step: "0.1" }}
                  value={cpKokToomPrice}
                  onChange={(e) => {
                    const inputNumber = e.target.value;
                    if (separatorList.regex.test(inputNumber)) {
                      if (inputNumber >= 0 && inputNumber <= 99) {
                        setCpKokToomPrice(inputNumber);
                      }
                    } else if (
                      separatorList.incompleteDecimal.test(inputNumber)
                    ) {
                      setCpKokToomPrice(inputNumber);
                    } else if (e.target.value === "") {
                      setCpKokToomPrice("");
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="text-right pt-4">
            <Button
              className="mr-2"
              onClick={() => {
                handleOnCancle(false);
              }}
            >
              CANCEL
            </Button>
            <Button
              className="ml-2"
              variant="contained"
              color="primary"
              onClick={() => onClickSubmitForm()}
              disabled={checkIsDisableSaveBtn()}
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
