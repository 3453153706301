import { 
    Grid,
    Typography,
    Paper
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import useStyles from '../../assets/styles';

import PriceSummaryArea from '../Areas/PriceSummaryArea';

const PriceSummaryCard = (props) => {

    const { title, unit, purchasingUrl, forecastAccuracyObj, currentMonthInventoryObj, previousMonthInventoryObj, highestLowestInventoryObj, forecastObjectList, highestLowestObj } = props;

    const classes = useStyles();
    
    return (
        <Paper className="p-4 h-full">
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} md={12} lg={6} className="h-14 items-center flex">
                    <Typography variant="h4"><Link className={classes.link} to={purchasingUrl}>{title}</Link> ({ unit })</Typography>
                </Grid>
                <Grid item xs={12}>
                    <PriceSummaryArea
                        forecastObjectList={forecastObjectList || []}
                        forecastAccuracyObj={forecastAccuracyObj}
                        currentMonthInventoryObj={currentMonthInventoryObj}
                        previousMonthInventoryObj={previousMonthInventoryObj}
                        previousMonthCovertedInventoryObj={previousMonthInventoryObj}
                        highestLowestInventoryObj={highestLowestInventoryObj}
                        highestLowestActualPriceObj={highestLowestObj}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}

export default PriceSummaryCard;