import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { loadAction, snackbarAction } from "../../../store/actions";
import TapiocaService from "../../../services/Tapioca";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateObject from "../../../utils/DateObject";

export function ModalUpdateTapiocaForm(props) {
  const {
    dataInfo,
    isOpen,
    handleOnCancle,
    getNewDataList = () => {},
    dataList = [],
  } = props;

  const dispatch = useDispatch();
  const [tapiocaId, setTapiocaId] = useState(dataInfo["id"]);
  const [date, setDate] = useState(dataInfo["marketPriceDate"]);
  const [marketPriceMax, setMarketPriceMax] = useState(
    dataInfo["marketPriceMax"]
  );
  const [marketPriceMin, setMarketPriceMin] = useState(
    dataInfo["marketPriceMin"]
  );

  const placeholdertext = "Max 99";

  const separatorList = {
    regex: /^(0|[1-9][0-9]{0,2})(\.[0-9]{0,2})?$/,
    incompleteDecimal: /^(0|[1-9][0-9]{0,2})\.$/,
  };

  useEffect(() => {
    setTapiocaId(dataInfo["id"]);
    setDate(dataInfo["marketPriceDate"]);
    setMarketPriceMax(dataInfo["marketPriceMax"]);
    setMarketPriceMin(dataInfo["marketPriceMin"]);
  }, [dataInfo]);

  const checkIsDisableSaveBtn = () => {
    return !(marketPriceMax > 0 && marketPriceMin > 0);
  };

  const onClickSubmitForm = async () => {
    const isExisted = dataList.find((item) => {
      return (
        item.marketPriceDate ===
          DateObject.convertDateTime(date, "YYYY-MM-DD") && item.id !== tapiocaId
      );
    });
    if (isExisted) {
      dispatch(
        snackbarAction.openSnackbar({
          options: { variant: "error" },
          message: "Cannot save change due to duplicated date",
        })
      );
      dispatch(loadAction.hideLoadingOverlay());
    } else {
      handleOnCancle(false);
      let data = {
        marketPriceDate: date,
        marketPriceMax: marketPriceMax,
        marketPriceMin: marketPriceMin,
      };
      dispatch(loadAction.displayLoadingOverlay());
      TapiocaService.updateTapiocaObject(tapiocaId, data)
        .then((response) => {
          dispatch(
            snackbarAction.openSnackbar({
              options: { variant: "success" },
              message: "Market Price Data has been updated",
            })
          );
          getNewDataList();
          dispatch(loadAction.hideLoadingOverlay());
        })
        .catch((error) => {
          let newError = { ...error };
          dispatch(
            snackbarAction.openSnackbar({
              options: { variant: "error" },
              message: !newError.response
                ? "An error occurred, please try again"
                : newError.response.data.message,
            })
          );
          dispatch(loadAction.hideLoadingOverlay());
        });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleOnCancle(false);
      }}
      aria-labelledby="modal-update-form"
      className="flex justify-center items-center"
    >
      <Paper
        elevation={0}
        className="p-10"
        style={{ maxWidth: "90%", width: 620, outline: "none" }}
      >
        <Grid container>
          <Grid item xs={12} className="pb-4">
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              Tapioca form
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography variant="body1" className="self-center pr-5">
                Market Price Date
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  label="Date"
                  format="dd/MM/yyyy"
                  name="marketPriceDate"
                  onChange={(date) => {
                    setDate(date);
                  }}
                  value={date}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className="self-center pr-2 mb-2 mt-2"
              >
                Market Price Max
              </Typography>
              <TextField
                label="Unit THB"
                placeholder={placeholdertext}
                variant="outlined"
                size="small"
                autoComplete="off"
                inputProps={{ step: "0.1" }}
                value={marketPriceMax}
                onChange={(e) => {
                  const inputNumber = e.target.value;
                  if (separatorList.regex.test(inputNumber)) {
                    if (inputNumber >= 0 && inputNumber <= 99) {
                      setMarketPriceMax(inputNumber);
                    }
                  } else if (
                    separatorList.incompleteDecimal.test(inputNumber)
                  ) {
                    setMarketPriceMax(inputNumber);
                  } else if (e.target.value === "") {
                    setMarketPriceMax("");
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className="self-center pr-2 mb-2 mt-2"
              >
                Market Price Min
              </Typography>
              <TextField
                label="Unit THB"
                placeholder={placeholdertext}
                variant="outlined"
                size="small"
                autoComplete="off"
                inputProps={{ step: "0.1" }}
                value={marketPriceMin}
                onChange={(e) => {
                  const inputNumber = e.target.value;
                  if (separatorList.regex.test(inputNumber)) {
                    if (inputNumber >= 0 && inputNumber <= 99) {
                      setMarketPriceMin(inputNumber);
                    }
                  } else if (
                    separatorList.incompleteDecimal.test(inputNumber)
                  ) {
                    setMarketPriceMin(inputNumber);
                  } else if (e.target.value === "") {
                    setMarketPriceMin("");
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className="text-right pt-4">
            <Button
              className="mr-2"
              onClick={() => {
                handleOnCancle(false);
              }}
            >
              CANCEL
            </Button>
            <Button
              className="ml-2"
              variant="contained"
              color="primary"
              onClick={() => onClickSubmitForm()}
              disabled={checkIsDisableSaveBtn()}
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
