import React from 'react';
import {
    LinearGauge,
    Scale,
    Label,
    SubvalueIndicator,
    ValueIndicator,
    Tick,
    Text,
    Font,
    Size,
    RangeContainer,
    Range,
    Animation
} from 'devextreme-react/linear-gauge';


/**
 * 
 * install: npm install devextreme@21.1 devextreme-react@21.1
 * Ref: https://js.devexpress.com/Demos/WidgetsGallery/Demo/Charts/Line/React/Light/
 * 
 * @param
 *  dataList:
 *      array ของ object ที่จะนำไปแสดงในกราฟ โดย object จะอยู่ในรูปแบบ 
 *         { 
 *              key1: value1, 
 *              key2: value2, 
 *              key3: value3,
 *              ...
 *          }
 *      Ex: [ { 'date': '2013-11-01', 'actualPrice': 7.903125, 'forecastValue': null, 'forecastLower': null, 'forecastUpper': null }, { 'date': '2013-12-01', 'actualPrice': 7.153260869, 'forecastValue': null, 'forecastLower': null, 'forecastUpper': null }, ...]
 * 
 *  argumentField:
 *      ชื่อ key ที่จะใช้เป็น label ในแกน x
 *      Ex: key1
 * 
 *  valueFieldList:
 *      array ของ object ที่จะนำไปแสดงเป็นเส้นในกราฟ โดย object จะอยู่ในรูปแบบ
 *          {
 *              key: <คีย์สำหรับวนลูป array ใน react>,
 *              valueField: <ชื่อของ key ใน dataList ที่จะนำมาแสดงเป็นกราฟ>,
 *              name: <ชื่อที่จะนำไปแสดงใน Legend เพื่อบอกว่าเส้นสีนี้แสดงค่าอะไร>,
 *              color: <สีของเส้นที่แสดงในกราฟ (optional)>,
 *              isShowInLegend: <ค่า true, false เพื่อกำหนดว่าเส้นนี้จะแสดงอยู่ใน Legend หรือไม่ (optional)>,
 *              type: <ประเภทของค่าที่จะแสดงในกราฟ (optional)>,
 *              rangeValue1Field: <ชื่อของ key ใน dataList ที่จะนำมาแสดงร่วมกับค่านี้ (optional)>,
 *              rangeValue2Field: <ชื่อของ key ใน dataList ที่จะนำมาแสดงร่วมกับค่านี้ (optional)>,
 *          },
 *      Ex: [ { key: 'rangeArea', rangeValue1Field: 'forecastLower', rangeValue2Field: 'forecastUpper', type: 'rangeArea', color: '#b0daff', isShowInLegend: false }, { key: "actualPrice", valueField: "actualPrice", name: 'ราคา', isShowInLegend: true }, ...]
 * 
 * @returns 
 */

/**
 * ตัวอย่างการเรียกใช้งาน
 * <SummaryLinearGauge
        dataList={[
            { 'date': '2021-04-01', 'actualPrice': 9.206558062, 'forecastValue': null, 'forecastLower': null, 'forecastUpper': null },
            { 'date': '2021-05-01', 'actualPrice': 9.322916667, 'forecastValue': null, 'forecastLower': null, 'forecastUpper': null },
            { 'date': '2021-06-01', 'actualPrice': 9.768333333, 'forecastValue': null, 'forecastLower': null, 'forecastUpper': null },
            { 'date': '2021-07-01', 'actualPrice': 10.26666667, 'forecastValue': null, 'forecastLower': null, 'forecastUpper': null },
            { 'date': '2021-08-01', 'actualPrice': null, 'forecastValue': 8.885284798223715, 'forecastLower': 5.820702068851409, 'forecastUpper': 11.94986793114859 },
            { 'date': '2021-09-01', 'actualPrice': null, 'forecastValue': 8.91806066632154, 'forecastLower': 5.9233392125219275, 'forecastUpper': 11.912782787478072 },
            { 'date': '2021-10-01', 'actualPrice': null, 'forecastValue': 8.956665846756222, 'forecastLower': 5.9732476446129095, 'forecastUpper': 11.79732235538709 },
            { 'date': '2021-11-01', 'actualPrice': null, 'forecastValue': 8.973612140274348, 'forecastLower': 6.076414470868299, 'forecastUpper': 11.6941555291317 },
            { 'date': '2021-12-01', 'actualPrice': null, 'forecastValue': 8.992833712057573, 'forecastLower': 6.248602704052163, 'forecastUpper': 11.521967295947835 },
            { 'date': '2022-01-01', 'actualPrice': null, 'forecastValue': 8.9928864349419, 'forecastLower': 6.025216105619942, 'forecastUpper': 11.745353894380058 },
        ]}
        argumentField="date"
        valueFieldList={[
            { key: 'rangeArea', rangeValue1Field: 'forecastLower', rangeValue2Field: 'forecastUpper', type: 'rangeArea', color: '#b0daff', isShowInLegend: false },
            { key: "actualPrice", valueField: "actualPrice", name: 'ราคา', isShowInLegend: true }, 
            { key: "forecastValue", valueField: "forecastValue", name: 'คำนาย', isShowInLegend: true }, 
            { key: "forecastLower", valueField: "forecastLower", name: 'ต่ำสุด', isShowInLegend: true },
            { key: "forecastUpper", valueField: "forecastUpper", name: 'สูง', isShowInLegend: true }
        ]}
    />
 */


const SummaryLinearGauge = (props) => {

    const { value, valueColor, monthOfValue, scaleColor, subvalues, startValue, endValue } = props;

    const customizeSubvalueIndicatorText = ({ valueText }) => {
        if( !subvalues || subvalues.length === 0 ) {
            return `Target Price: -`;
        }
        
        return `Target Price: ${Number(Math.round(subvalues * 100) / 100)}`;
    }

    return (
        <div className="flex items-end">
            <div className="flex flex-col text-xs">
                <div className="h-8 mb-2">
                    <div>Lowest</div>
                    <div>{ startValue }</div>
                </div>
                <div className="h-8">
                    <div>{ monthOfValue }</div>
                    <div>{ value || 0 }</div>
                </div>
            </div>
            <div className="flex-grow mx-2">
                <div>
                    <LinearGauge
                        value={value || 0}
                        subvalues={subvalues ? [subvalues] : [startValue]}
                    >
                        <Size height={80} />
                        <Scale
                            startValue={startValue}
                            endValue={endValue}
                        >
                            <Tick visible={false} />
                            <Label visible={false}/>
                        </Scale>
                        <RangeContainer offset={-20}>
                            <Range startValue={startValue} endValue={endValue} color={ scaleColor ? scaleColor : "#92000A" } />
                        </RangeContainer>
                        <SubvalueIndicator type="textCloud" offset={-20}>
                            <Text customizeText={customizeSubvalueIndicatorText}>
                                <Font size={12} />
                            </Text>
                        </SubvalueIndicator>
                        <ValueIndicator type="rangebar" color={ valueColor ? valueColor : "#00F" } />
                        <Animation enabled={false} />
                    </LinearGauge>
                </div>
            </div>
            <div className="flex flex-col text-xs">
                <div className="h-8 mb-2">
                    <div>Highest</div>
                    <div>{ endValue }</div>
                </div>
                <div className="h-8"></div>
            </div>
        </div>
    );
}

export default SummaryLinearGauge;