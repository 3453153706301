import React, { useState } from 'react'
import { Button, Grid, TextField, Typography, Link, Hidden } from '@material-ui/core'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loadAction, userAction } from '../store/actions';
import Validator from '../utils/Validator'
import useStyles from '../assets/styles';
import UserService from '../services/User';
import AlertModal from '../components/Options/AlertModal';

import logo from '../assets/images/thaifood-logo.png';

function ForgetPassword() {

    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const [email, setEmail] = useState('')
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState('');

    const checkDisabledBtn = () => {
        return email === ''
    }

    const submitForm = async (event) => {
        event.preventDefault();
        dispatch(loadAction.displayLoadingOverlay());

        let { isValid, message } = Validator.validateData(email, 'email', {}, "Email");

        if (!isValid) {
            setErrorMessage(message);
            dispatch(loadAction.hideLoadingOverlay());
            return null
        }

        let resetResult = await UserService.forgetPassword(email).catch((error) => {
            setErrorMessage(error.message);
        });

        if (resetResult && resetResult['status'].toLowerCase() === 'success' && isValid) {
            if (resetResult['status'].toLowerCase() === 'success') {
                dispatch(userAction.authenUser()).then(() => {
                    setIsOpenModal(true)
                })
            }
        }
        dispatch(loadAction.hideLoadingOverlay());
    };

    const actionSubmit = () => {
        history.push("/login")
    }

    return (
        <Grid container className="h-full" justifyContent="center">
            {isOpenModal ? <AlertModal title={"Message"} content={"Please check in Email's Inbox or Junk mail."} action={actionSubmit} buttonName={"Login"} /> : null}
            <Hidden xsDown>
                <Grid item xs={false} sm={6} md={6} lg={8} className="flex h-full">
                    <img src="/assets/images/login_cover.jpg" alt="Login" className="object-cover w-full"/>
                </Grid>
            </Hidden>
            <Grid item xs={12} sm={6} md={6} lg={4}>
                <form onSubmit={submitForm} className="h-full">
                    <div className="h-full bg-white rounded-lg shadow-2xl flex items-center">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <img alt="Thaifood Logo" src={logo} className="h-32 mx-auto" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h4' align="center">Forget Password</Typography>
                            </Grid>
                            <Grid item xs={12} className="h-12">
                                <Typography color="error" align="center">
                                    {errorMessage}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" spacing={2}>
                                    <Grid item xs={8}>
                                        <Typography variant="subtitle2" color="primary" align="left">
                                            Please enter your email address. You will receive a link to create a new password via email.
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="Email"
                                            type="text"
                                            label="Email"
                                            variant="outlined"
                                            size='small'
                                            required
                                            fullWidth
                                            onChange={(event) => setEmail(event.target.value)}
                                            value={email}
                                        />
                                    </Grid>
                                    <Grid item xs={8} className="text-center">
                                        <Link className={`cursor-pointer ${classes.link}`} variant="body2" onClick={() => history.push("/login")}>
                                            Sign in
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="text-center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    disabled={checkDisabledBtn()}
                                    className="w-40"
                                >
                                    Send
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Grid>
        </Grid >
    )
}

export default ForgetPassword
