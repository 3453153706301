const { default: ActionTypes } = require("../actions/actionType");

const initialState = {};

const userReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_USER_PROFILE: {
            return { ...state };

        } case ActionTypes.AUTHEN_USER: {
            return { ...action.payload };

        } default: {
            return { ...state };

        }
    }

}

export default userReducer;