import ApiProxy from '../utils/ApiProxy';

class WebSetting {

    static async getWebSettingList(tag) {
        return new Promise(async (resolve, reject) => {
            let inventoryPriceList = await ApiProxy.sendRequest('GET', `web-settings?tag=${tag}`).catch((error) => {
                reject(error);
            });
            resolve(inventoryPriceList);
        });
    }

    static async updateWebSettingObject(id, data) {
        return new Promise(async (resolve, reject) => {
            let updateResult = await ApiProxy.sendRequest('PUT', `web-settings/${id}`, data).catch((error) => {
                reject(error);
            });
            resolve(updateResult);
        });
    }

}

export default WebSetting;