import { 
    Grid,
    Typography,
    TextField,
    Paper,
    IconButton
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useStyles from '../../assets/styles';

import SaveIcon from '@material-ui/icons/Save';
import PriceSummaryArea from '../Areas/PriceSummaryArea';
import Currency from '../../utils/Currency';
import Validator from '../../utils/Validator';

const initFormObj = {
    exchangeRate: {
        id: "exchangeRate",
        name: "exchangeRate",
        label: "Exchange Rate",
        value: 33,
        type: "number",
        required: true,
        disabled: false,
        error: false,
        helperText: "",
        validate: {
            format: /^[1-9]\d*(\.\d+)*$/g
        }
    },
    premium: {
        id: "premium",
        name: "premium",
        label: "Premium",
        value: 89,
        type: "number",
        required: true,
        disabled: false,
        error: false,
        helperText: "",
        validate: {
            format: /^[1-9]\d*(\.\d+)*$/g
        }
    }
};

const ConvertPriceSummaryCard = (props) => {

    const { title, unit, purchasingUrl, convertToUnit, userLevel, forecastAccuracyObj, currentMonthInventoryObj, previousMonthInventoryObj, highestLowestInventoryObj, forecastObjectList, highestLowestObj, exchangeRate, premium, handleSaveButtonClick=() => {}, customCurrentMonthForecastObj=null } = props;

    const classes = useStyles();
    const [formObj, setFormObj] = useState({...initFormObj});
    const [editableCurrentMonthInventoryObj, setEditableCurrentMonthInventoryObj] = useState({});
    const [editablePreviousMonthInventoryObj, setEditablePreviousMonthInventoryObj] = useState({});
    const [editableHighestLowestInventoryObj, setEditableHighestLowestInventoryObj] = useState({});
    
    useEffect(() => {
        const newExchangeRateValueObj = {...formObj['exchangeRate']};
        newExchangeRateValueObj['value'] = exchangeRate;
        const newPremiumValueObj = {...formObj['premium']};
        newPremiumValueObj['value'] = premium;
        setFormObj({...formObj, 'exchangeRate': {...newExchangeRateValueObj}, 'premium': {...newPremiumValueObj} });

        convertPriceObject(exchangeRate, premium);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exchangeRate, premium]);

    useEffect(() => {
        setEditableCurrentMonthInventoryObj( convertPrice(currentMonthInventoryObj, formObj['exchangeRate']['value'], formObj['premium']['value']) );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentMonthInventoryObj]);
    
    useEffect(() => {
        setEditablePreviousMonthInventoryObj( convertPrice(previousMonthInventoryObj, formObj['exchangeRate']['value'], formObj['premium']['value']) );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previousMonthInventoryObj]);

    useEffect(() => {
        setEditableHighestLowestInventoryObj( {...highestLowestInventoryObj} );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [highestLowestInventoryObj]);

    const convertPrice = (inventoryObj, exchangeRateValue, premiumValue) => {
        const newInventoryObj = {...inventoryObj};
        
        if( newInventoryObj.hasOwnProperty('targetPrice') ) {
            newInventoryObj['targetPrice'] = Currency.convertPricePerUnit(convertToUnit, newInventoryObj['targetPrice'], exchangeRateValue);
            if( convertToUnit === "THB/KG->USD/Sh.To" && newInventoryObj['targetPrice'] > 0 ) {
                newInventoryObj['targetPrice'] = Currency.getDecimal(newInventoryObj['targetPrice'] - premiumValue, 2);
            }
        }
        if( newInventoryObj.hasOwnProperty('inventoryPrice') ) {
            newInventoryObj['inventoryPrice'] = Currency.convertPricePerUnit(convertToUnit, newInventoryObj['inventoryPrice'], exchangeRateValue);
            if( convertToUnit === "THB/KG->USD/Sh.To" && newInventoryObj['inventoryPrice'] > 0 ) {
                newInventoryObj['inventoryPrice'] = Currency.getDecimal(newInventoryObj['inventoryPrice'] - premiumValue, 2);
            }
        }
        if( newInventoryObj.hasOwnProperty('minInventoryPrice') ) {
            newInventoryObj['minInventoryPrice'] = Currency.convertPricePerUnit(convertToUnit, newInventoryObj['minInventoryPrice'], exchangeRateValue);
            if( convertToUnit === "THB/KG->USD/Sh.To" && newInventoryObj['minInventoryPrice'] > 0 ) {
                newInventoryObj['minInventoryPrice'] = Currency.getDecimal(newInventoryObj['minInventoryPrice'] - premiumValue, 2);
            }
        }
        if( newInventoryObj.hasOwnProperty('maxInventoryPrice') ) {
            newInventoryObj['maxInventoryPrice'] = Currency.convertPricePerUnit(convertToUnit, newInventoryObj['maxInventoryPrice'], exchangeRateValue);
            if( convertToUnit === "THB/KG->USD/Sh.To" && newInventoryObj['maxInventoryPrice'] > 0 ) {
                newInventoryObj['maxInventoryPrice'] = Currency.getDecimal(newInventoryObj['maxInventoryPrice'] - premiumValue, 2);
            }
        }

        return newInventoryObj;
    }

    const handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const newValueObj = {...formObj[name]};
        newValueObj['value'] = value;
        setFormObj({...formObj, [name]: {...newValueObj} });
    }

    const handleCalculateButtonClick = (event) => {
        const validatorObj = Validator.validateForm(formObj);
        setFormObj({...validatorObj['returnFormObj']});
        if( !validatorObj['isFormValid'] ) {
            return false;
        }

        handleSaveButtonClick(formObj['exchangeRate']['value'], formObj['premium']['value']);

        convertPriceObject(formObj['exchangeRate']['value'], formObj['premium']['value']);
    }

    const convertPriceObject = (exchangeRateValue, premiumValue) => {
        setEditableCurrentMonthInventoryObj( convertPrice(currentMonthInventoryObj, exchangeRateValue, premiumValue) );
        setEditablePreviousMonthInventoryObj( convertPrice(previousMonthInventoryObj, exchangeRateValue, premiumValue) );
    }

    const renderExchangeRateInputArea = () => {
        return (
            <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                <Grid item xs={5}>
                    <TextField
                        name={ formObj['exchangeRate']['name'] }
                        type={ formObj['exchangeRate']['number'] }
                        label={ formObj['exchangeRate']['label'] }
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        required
                        fullWidth
                        disabled={true}
                        className="bg-white"
                        value={ formObj['exchangeRate']['value'] }
                    />
                </Grid>
            </Grid>
        );
    }

    const renderPremiumExchangeRateInputArea = () => {
        return ( userLevel === "reporter" ? (
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <TextField
                            name={ formObj['exchangeRate']['name'] }
                            type={ formObj['exchangeRate']['number'] }
                            label={ formObj['exchangeRate']['label'] }
                            variant="outlined"
                            size="small"
                            autoComplete="off"
                            required
                            disabled={true}
                            fullWidth
                            className="bg-white"
                            onChange={ handleInputChange }
                            value={ formObj['exchangeRate']['value'] }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            name={ formObj['premium']['name'] }
                            type={ formObj['premium']['number'] }
                            label={ formObj['premium']['label'] }
                            variant="outlined"
                            size="small"
                            autoComplete="off"
                            required
                            disabled={true}
                            fullWidth
                            className="bg-white"
                            onChange={ handleInputChange }
                            value={ formObj['premium']['value'] }
                        />
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={5}>
                        <TextField
                            name={ formObj['exchangeRate']['name'] }
                            type={ formObj['exchangeRate']['number'] }
                            label={ formObj['exchangeRate']['label'] }
                            variant="outlined"
                            size="small"
                            autoComplete="off"
                            required
                            disabled={ formObj['exchangeRate']['disabled'] }
                            fullWidth
                            className="bg-white"
                            onChange={ handleInputChange }
                            value={ formObj['exchangeRate']['value'] }
                            helperText={ formObj['exchangeRate']['helperText'] }
                            error={ formObj['exchangeRate']['error'] }
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            name={ formObj['premium']['name'] }
                            type={ formObj['premium']['number'] }
                            label={ formObj['premium']['label'] }
                            variant="outlined"
                            size="small"
                            autoComplete="off"
                            required
                            disabled={ formObj['premium']['disabled'] }
                            fullWidth
                            className="bg-white"
                            onChange={ handleInputChange }
                            value={ formObj['premium']['value'] }
                            helperText={ formObj['premium']['helperText'] }
                            error={ formObj['premium']['error'] }
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton
                            size="small"
                            onClick={handleCalculateButtonClick}
                        >
                            <SaveIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            )
        )
    }
    
    return (
        <Paper className="p-4 h-full">
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6} className="h-14 items-center flex">
                    <Typography variant="h4"><Link className={classes.link} to={purchasingUrl}>{title}</Link> ({ unit })</Typography>
                </Grid>
                <Grid item xs={12} sm={6} className="h-14">
                    { convertToUnit === "THB/KG->USD/To" ? 
                        renderExchangeRateInputArea()
                    :
                        renderPremiumExchangeRateInputArea() 
                    }
                </Grid>
                <Grid item xs={12}>
                    <PriceSummaryArea
                        forecastObjectList={forecastObjectList || []}
                        customCurrentMonthForecastObj={customCurrentMonthForecastObj}
                        forecastAccuracyObj={forecastAccuracyObj}
                        currentMonthInventoryObj={editableCurrentMonthInventoryObj}
                        previousMonthInventoryObj={previousMonthInventoryObj}
                        previousMonthCovertedInventoryObj={editablePreviousMonthInventoryObj}
                        highestLowestInventoryObj={editableHighestLowestInventoryObj}
                        highestLowestActualPriceObj={highestLowestObj}
                    />
                </Grid>
            </Grid>
        </Paper>
    );
}

export default ConvertPriceSummaryCard;