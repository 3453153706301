import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, TextField, Typography } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import UserService from '../services/User';
import { loadAction, snackbarAction, userAction } from '../store/actions';
import Validator from '../utils/Validator';


const Profile = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const userProfileObj = useSelector(state => state.userReducer);
    const [email, setEmail] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [userLevel, setUserLevel] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isInvalid, setIsInvalid] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const [isInvalidPassword, setIsInvalidPassword] = useState(false);

    const getUserData = useCallback(() => {
        setEmail(userProfileObj.email)
        setLastName(userProfileObj.lastName)
        setFirstName(userProfileObj.firstName)
        setUserLevel(userProfileObj.userLevel)
        setMobileNumber(userProfileObj.mobileNumber)
    }, [userProfileObj]);

    useEffect(() => {
        if (!userProfileObj) {
            history.push("/");
            return;
        }
        getUserData()
    }, [getUserData, history, userProfileObj])

    const handleChangeEmail = (emailValue) => {
        setEmail(emailValue)
        setIsInvalidEmail(false)
    }

    const submitForm = () => {
        if (!firstName || !lastName || !email || !mobileNumber) {
            setIsInvalid(true)
        } else if (!Validator.validateData(email, 'email').isValid) {
            dispatch(
                snackbarAction.openSnackbar({ options: { variant: "warning" }, message: "Invalid E-mail" })
            )
            setIsInvalid(true)
            setIsInvalidEmail(true)
        } else if (!Validator.validateData(mobileNumber, 'mobileNumber').isValid) {
            dispatch(
                snackbarAction.openSnackbar({ options: { variant: "warning" }, message: "Invalid phone number" })
            )
            setIsInvalid(true)
            setMobileNumber("")
        } else {
            dispatch(loadAction.displayLoadingOverlay());
            const updateData = {
                email: email,
                lastName: lastName,
                firstName: firstName,
                mobileNumber: mobileNumber,
            }
            updateUserObj(updateData)
        }
    }

    const updateUserObj = async (data) => {
        return await UserService.updateUserObject(userProfileObj.userId, data).then((response) => {
            dispatch(userAction.authenUser())
            dispatch(snackbarAction.openSnackbar({ options: { variant: "success" }, message: "Profile has been updated" }))
            dispatch(loadAction.hideLoadingOverlay());
        }).catch((error) => {
            let newError = { ...error }
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            dispatch(loadAction.hideLoadingOverlay());
        });
    }

    const submitChangePassword = () => {
        if (!password || !confirmPassword || !oldPassword) {
            setIsInvalidPassword(true)
        } else if (password !== confirmPassword) {
            dispatch(snackbarAction.openSnackbar({ options: { variant: "warning" }, message: "Confirm password doesn't match" }))
            setIsInvalidPassword(true)
            setConfirmPassword("")
        } else if (!Validator.validateData(password, 'password').isValid) {
            dispatch(
                snackbarAction.openSnackbar({ options: { variant: "warning" }, message: "Use at least 8 characters, including uppercases, lowercases, numbers and symbols" })
            )
            setPassword("")
            setConfirmPassword("")
            setIsInvalidPassword(true)
        } else {
            dispatch(loadAction.displayLoadingOverlay());
            const updatePasswordData = {
                newPassword: password,
                currentPassword: oldPassword
            }
            updatePassword(updatePasswordData)
        }
    }

    const updatePassword = async (data) => {
        return await UserService.updateUserPassword(data).then((response) => {
            setPassword("");
            setOldPassword("");
            setConfirmPassword("");
            setIsInvalidPassword(false);
            dispatch(snackbarAction.openSnackbar({ options: { variant: "success" }, message: "Password has been updated" }))
            dispatch(loadAction.hideLoadingOverlay());
        }).catch((error) => {
            let newError = { ...error }
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            dispatch(loadAction.hideLoadingOverlay());
        });
    }

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
                <Typography variant="h3" style={{ fontWeight: 'bold' }}>Profile</Typography>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={0} style={{ width: '100%', padding: '48px' }}>
                    <form noValidate autoComplete="off">
                        <Grid item xs={12} className="pb-8">
                            <Typography variant="h3" style={{ fontWeight: 'bold' }}>User Profile</Typography>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField label="ชื่อ" margin="dense" variant="outlined" color="secondary" fullWidth error={isInvalid && !firstName} value={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField label="นามสกุล" margin="dense" variant="outlined" color="secondary" fullWidth error={isInvalid && !lastName} value={lastName} onChange={(e) => { setLastName(e.target.value) }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField label="หมายเลขโทรศัพท์" margin="dense" variant="outlined" color="secondary" fullWidth error={isInvalid && !mobileNumber} value={mobileNumber} onChange={(e) => { setMobileNumber(e.target.value.replace(/[^\d]/g, "")) }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField type="email" label="E-mail" margin="dense" variant="outlined" color="secondary" fullWidth error={isInvalidEmail || (isInvalid && !email)} value={email} onChange={(e) => { handleChangeEmail(e.target.value) }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Grid container alignItems='center' justifyContent='space-between'>
                                    <Grid item xs={10} sm={10} md={10}>
                                        <TextField label="ระดับผู้ใช้งาน" select margin="dense" variant="outlined" color="secondary" fullWidth disabled={true} value={userLevel}>
                                            <MenuItem value={userLevel}>{userLevel}</MenuItem>
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            <Box component={Grid} item xs={4} display={{ xs: "none", md: "block" }} />

                            <Grid item xs={12} className="text-right pt-8">
                                <Button variant="contained" color="primary" onClick={() => submitForm()}>
                                    {"SAVE"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={0} style={{ width: '100%', padding: '48px' }}>
                    <form noValidate autoComplete="off">
                        <Grid item xs={12} className="pb-8">
                            <Typography variant="h3" style={{ fontWeight: 'bold' }}>Change Password</Typography>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4} className="self-center text-left">
                                <FormControl error={isInvalidPassword && !password} color="secondary" variant="outlined" fullWidth margin="dense" style={{ borderRadius: '4px' }}>
                                    <InputLabel htmlFor="passwordField">Password</InputLabel>
                                    <OutlinedInput id="passwordField" type={showPassword ? 'text' : 'password'} labelWidth={65} value={password} onChange={(e) => setPassword(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end" onClick={() => { setShowPassword(!showPassword) }}>
                                                <IconButton aria-label="toggle password visibility" edge="end">
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl error={isInvalidPassword && !confirmPassword} color="secondary" variant="outlined" fullWidth margin="dense" style={{ borderRadius: '4px' }}>
                                    <InputLabel htmlFor="confirmPasswordField">Confirm Password</InputLabel>
                                    <OutlinedInput id="confirmPasswordField" type={showPassword ? 'text' : 'password'} labelWidth={120} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end" onClick={() => { setShowPassword(!showPassword) }}>
                                                <IconButton aria-label="toggle password visibility" edge="end">
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Box component={Grid} item xs={4} display={{ xs: "none", md: "block" }} />
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl error={isInvalidPassword && !oldPassword} color="secondary" variant="outlined" fullWidth margin="dense" style={{ borderRadius: '4px' }}>
                                    <InputLabel htmlFor="oldPasswordField">Old Password</InputLabel>
                                    <OutlinedInput id="oldPasswordField" type={showPassword ? 'text' : 'password'} labelWidth={120} value={oldPassword} onChange={(e) => setOldPassword(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end" onClick={() => { setShowPassword(!showPassword) }}>
                                                <IconButton aria-label="toggle password visibility" edge="end">
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} className="text-right pt-8">
                                <Button variant="contained" color="primary" onClick={() => submitChangePassword()}>
                                    {"UPDATE"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default Profile;