import React, { useState } from 'react';
import { Typography, Button, Grid, Modal, Paper } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export function ModalDownloadForecastResult(props) {
    const { isOpen, handleOnCancle, handleDownload = () => {}} = props;

    const [date, setDate] = useState(new Date())

    const onClickDownload = () => {
        const fullYear = new Date(date).getFullYear() 
        handleOnCancle(false)
        handleDownload(fullYear)
    }

    return (
        <Modal open={isOpen} onClose={() => { handleOnCancle(false) }} aria-labelledby="modal-update-form" className="flex justify-center items-center">
            <Paper elevation={0} className="p-10" style={{ maxWidth: '90%', width: 620, outline: 'none' }}>
                <Grid container>
                    <Grid container item xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="body1" className="self-center pr-5">Year</Typography>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    views={["year"]}
                                    margin="normal"
                                    label="Date"
                                    format="yyyy"
                                    name="Year"
                                    onChange={(date) => { setDate(date) }}
                                    value={date}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="text-right pt-4">
                        <Button className="mr-2" onClick={() => { handleOnCancle(false) }}>
                            CANCEL
                        </Button>
                        <Button className="ml-2" variant="contained" color="primary" onClick={() => onClickDownload()}>
                            DOWNLOAD
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal> 
    )
}