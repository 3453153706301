import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Button, Grid, Tab, Tabs, Paper } from '@material-ui/core';
import useStyles from '../assets/styles';
import SoybeanMealService from '../services/SoybeanMeal';
import InventoryPriceService from '../services/InventoryPrice';
import MaterialEventService from '../services/MaterialEvent';
import EnhancedTable from '../components/Table/EnhancedTable';
import ModalDelete from "../components/MainLayout/ModalDelete";
import { soybeanMealAction, inventoryPriceAction, materialEventAction, dataActivityAction, loadAction, snackbarAction } from '../store/actions';
import SoybeanMealInput from '../components/DataManagement/Input/SoybeanMealInput';
import InventoryPriceInput from '../components/DataManagement/Input/InventoryPriceInput';
import TabPanel from '../components/Tab/TabPanel';
import EventInput from '../components/DataManagement/Input/EventInput';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ModalUpdateMaterialEventForm } from '../components/DataManagement/ModalForm/ModalUpdateMaterialEventForm';
import { ModalUpdateInventoryPriceForm } from '../components/DataManagement/ModalForm/InventoryPriceForm';
import { ModalDownloadMarketPrice } from '../components/DataManagement/ModalForm/ModalDownloadMarketPrice';
import { ModalDownloadEvent } from '../components/DataManagement/ModalForm/ModalDownloadEvent';
import { ModalDownloadInventoryPrice } from '../components/DataManagement/ModalForm/ModalDownloadInventoryPrice';
import RefreshIcon from '@material-ui/icons/Refresh';

const SoybeanMeal = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [rowSelectedSoybeanMealList, setRowSelectedSoybeanMealList] = useState([]);
    const [rowSelectedInventoryPriceList, setRowSelectedInventoryPriceList] = useState([]);
    const [rowSelectedMaterialEventList, setRowSelectedMaterialEventList] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [indexOfTab, setIndexOfTab] = useState(0);

    const [selectedDateMarketPrice, setSelectedDateMarketPrice] = useState(null)
    const [selectedDateMaterialEvent, setSelectedDateMaterialEvent] = useState(null)
    const [selectedDateInventoryPrice, setSelectedDateInventoryPrice] = useState(null)
    const [selectedDateActivities, setSelectedDateActivities] = useState(null)

    const [isOpenModalUpdateMaterialEventForm, setIsOpenModalUpdateMaterialEventForm] = useState(false)
    const [eventOject, setEventObject] = useState({})

    const [isOpenModalUpdateInventoryPriceForm, setIsOpenModalUpdateInventoryPriceForm] = useState(false)
    const [inventoryPriceOject, setInventoryPriceObject] = useState({})

    const [isOpenModalDownloadMarketPrice, setIsOpenModalDownloadMarketPrice] = useState(false)
    const [isOpenModalDownloadInventoryPrice, setIsOpenModalDownloadInventoryPrice] = useState(false)
    const [isOpenModalDownloadEvent, setIsOpenModalDownloadEvent] = useState(false)
    
    const soybeanMealList = useSelector(state => state.soybeanMealReducer);
    const inventoryPriceList = useSelector(state => state.inventoryPriceReducer);
    const dataActivityList = useSelector(state => state.dataActivityReducer);
    const materialEventList = useSelector(state => state.materialEventReducer);

    const getSoybeanMealListData = useCallback(() => {
        dispatch(loadAction.displayLoadingOverlay())
        dispatch(soybeanMealAction.getSoybeanMealList(0, 0, "", ""));
        dispatch(inventoryPriceAction.getInventoryPriceList(0, 0, "", "", "soybeanMeal"));
        dispatch(dataActivityAction.getDataActivityList(0, 0, "", "", "soybeanMeal"));
        dispatch(materialEventAction.getMaterialEventList(0, 0, "", "soybeanMeal"));
        setSelectedDateMarketPrice(null)
        setSelectedDateMaterialEvent(null)
        setSelectedDateInventoryPrice(null)
        setSelectedDateActivities(null)

    }, [dispatch]);

    useEffect(() => {
        getSoybeanMealListData()
    }, [getSoybeanMealListData])

    const headCellsSoybeanMealMarketPrice = [
        { id: 'tradeDate', label: 'Trade Date', numeric: false, disablePadding: false, isDate: true, isBoolean: false, className: 'w-48', headAlign: 'center', dateFormat: "YYYY-MM-DD", rawDateFormat: "YYYYMMDD" },
        { id: 'contractYear', label: 'Contract Year', numeric: true, disablePadding: false, isDate: false, isBoolean: false },
        { id: 'contractMonth', label: 'Contract Months', numeric: true, disablePadding: false, isDate: false, isBoolean: false },
        { id: 'globexOpenPrice', label: 'Globex Open Price (USD/To)', numeric: true, disablePadding: false, isDate: false, isBoolean: false, isDecimal: true, decimalNum:2, isDivideTen: true },
    ]

    const headCellsSoybeanMealInventoryPrice = [
        { id: 'inventoryPriceDate', label: 'Date', numeric: false, disablePadding: false, isDate: true, isBoolean: false, dateFormat: "YYYY-MM-DD", className: 'w-48', headAlign: 'center' },
        { id: 'week', label: 'Week', numeric: false, disablePadding: false, isDate: false, isBoolean: false },
        { id: 'inventoryPrice', label: 'Inventory Price (THB/KG)', numeric: true, disablePadding: false, isDate: false, isBoolean: false, isDecimal: true, decimalNum:2, isDivideTen: false },
        { id: 'targetPrice', label: 'Target Price (THB/KG)', numeric: true, disablePadding: false, isDate: false, isBoolean: false, isDecimal: true, decimalNum:2, isDivideTen: false },
    ]

    const headCellsSoybeanMealActivity = [
        { id: 'tableName', label: 'Table', numeric: false, disablePadding: false, isDate: false, isBoolean: false },
        { id: 'actionType', label: 'Type', numeric: false, disablePadding: false, isDate: false, isBoolean: false },
        { id: 'actionTime', label: 'Last Activities', numeric: false, disablePadding: false, isDate: true, isBoolean: false, dateFormat: "YYYY-MM-DD HH:mm", className: 'w-64', headAlign: 'center' },
        { id: 'userEmail', label: 'User E-mail', numeric: false, disablePadding: false, isDate: false, isBoolean: false },
        { id: 'actionDetail', label: 'Detail', numeric: false, disablePadding: false, isDate: false, isBoolean: false },
    ]

    const headCellsSoybeanMealEvent = [
        { id: 'period', label: 'Datetime', numeric: false, disablePadding: false, isDate: false, isBoolean: false },
        { id: 'categories', label: 'Categories', numeric: false, disablePadding: false, isDate: false, isBoolean: false },
        { id: 'event', label: 'Event', numeric: false, disablePadding: false, isDate: false, isBoolean: false, className: 'w-64', headAlign: 'center' },
        { id: 'impact', label: 'Impact', numeric: false, disablePadding: false, isDate: false, isBoolean: false },
    ]

    const handleTableMarketPriceClick = (data) => {
    }

    const handleTableMaterialEventClick = (data) => {
        setEventObject(data)
        setIsOpenModalUpdateMaterialEventForm(true)
    }

    const handleTableInventoryPriceClick = (data) => {
        setInventoryPriceObject(data)
        setIsOpenModalUpdateInventoryPriceForm(true)
    }

    const handleRowSeletionInventoryPrice = (selectedData) => {
        setRowSelectedInventoryPriceList(selectedData)
        setRowSelectedSoybeanMealList([])
        setRowSelectedMaterialEventList([])
    }

    const handleRowSeletionMaterialEvent = (selectedData) => {
        setRowSelectedMaterialEventList(selectedData)
        setRowSelectedSoybeanMealList([])
        setRowSelectedInventoryPriceList([])
    }

    const deleteInventoryPriceList = async () => {
        let inventoryPriceIdList = [];
        for (let obj of rowSelectedInventoryPriceList) {
            inventoryPriceIdList.push(obj.id)
        }
        setIsDeleteModalOpen(false)
        dispatch(loadAction.displayLoadingOverlay())
        InventoryPriceService.deleteInventoryList({ "idList": inventoryPriceIdList }, "soybeanMeal").then((response) => {
            getSoybeanMealListData();
            setRowSelectedInventoryPriceList([])
            dispatch(loadAction.hideLoadingOverlay());
            dispatch(snackbarAction.openSnackbar({ options: { variant: "success" }, message: "Data has been Deleted." }))
        }).catch((error) => {
            let newError = { ...error }
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            dispatch(loadAction.hideLoadingOverlay());
        });

    }

    const deleteMaterialEventList = async () => {
        let materialEventIdList = [];
        for (let obj of rowSelectedMaterialEventList) {
            materialEventIdList.push(obj.id)
        }
        setIsDeleteModalOpen(false)
        dispatch(loadAction.displayLoadingOverlay())
        MaterialEventService.deleteMaterialEventList({ "idList": materialEventIdList }, "soybeanMeal").then((response) => {
            getSoybeanMealListData()
            setRowSelectedMaterialEventList([])
            dispatch(loadAction.hideLoadingOverlay());
            dispatch(snackbarAction.openSnackbar({ options: { variant: "success" }, message: "Data has been Deleted." }))
        }).catch((error) => {
            let newError = { ...error }
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            dispatch(loadAction.hideLoadingOverlay());
        });

    }

    const soybeanMealTable = () => {
        return (
            <EnhancedTable tableDataList={soybeanMealList} headCellList={headCellsSoybeanMealMarketPrice} handleTableClick={handleTableMarketPriceClick} handleRowSeletion={ () => {}} rowSelectedList={[]} isCheckBox={false} defaultOrder="desc" defaultOrderBy="tradeDate"/>
        )
    }

    const soybeanMealInventoryPriceTable = () => {
        return (
            <EnhancedTable tableDataList={inventoryPriceList} headCellList={headCellsSoybeanMealInventoryPrice} handleTableClick={handleTableInventoryPriceClick} handleRowSeletion={handleRowSeletionInventoryPrice} isCheckBox={true} rowSelectedList={rowSelectedInventoryPriceList} defaultOrder="desc" defaultOrderBy="inventoryPriceDate"/>
        )
    }

    const activitiesTable = () => {
        return (
            <EnhancedTable tableDataList={dataActivityList} headCellList={headCellsSoybeanMealActivity} handleTableClick={() => { }} handleRowSeletion={() => { }} isCheckBox={false} rowSelectedList={rowSelectedSoybeanMealList} />
        )
    }

    const eventTable = () => {
        return (
            <EnhancedTable tableDataList={materialEventList} headCellList={headCellsSoybeanMealEvent} handleTableClick={handleTableMaterialEventClick} handleRowSeletion={handleRowSeletionMaterialEvent} isCheckBox={true} rowSelectedList={rowSelectedMaterialEventList} defaultOrder="desc" defaultOrderBy="period"/>
        )
    }

    const handleChangeDate = (date, selectedDateType) => {
        let newDate = new Date(date)
        let selectedMonth = String(newDate.getMonth() + 1).padStart(2, "0")
        let selectedYear = newDate.getFullYear()

        dispatch(loadAction.displayLoadingOverlay())
        if (selectedDateType === "marketPrice") {
            setSelectedDateMarketPrice(date)
            dispatch(soybeanMealAction.getSoybeanMealList(0, 0, selectedMonth, selectedYear));
        } else if (selectedDateType === "inventoryPrice") {
            setSelectedDateInventoryPrice(date)
            dispatch(inventoryPriceAction.getInventoryPriceList(0, 0, selectedMonth, selectedYear, "soybeanMeal"));
        } else if (selectedDateType === "materialEvent") {
            setSelectedDateMaterialEvent(date)
            dispatch(materialEventAction.getMaterialEventList(0, 0, `${selectedYear}-${selectedMonth}`, "soybeanMeal"));
        } else {
            setSelectedDateActivities(date)
            dispatch(dataActivityAction.getDataActivityList(0, 0, selectedMonth, selectedYear, "soybeanMeal"));
        }
        dispatch(loadAction.hideLoadingOverlay());
    }

    const rightHeaderSoybeanMealSearch = () => {
        return (
            rowSelectedSoybeanMealList.length < 1 ?
                <Grid item xs={12} className="mb-3">
                    <Grid container justifyContent='space-between'>
                        <Grid item className="mr-3">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    views={["year", "month"]}
                                    label="Month/Year"
                                    format="MM/yyyy"
                                    name="marketPriceDate"
                                    onChange={(date) => { handleChangeDate(date, "marketPrice") }}
                                    value={selectedDateMarketPrice}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <Button className="w-full md:w-auto mt-5 ml-2" variant="outlined" size="small" disableElevation color="primary"  startIcon={<RefreshIcon/>} onClick={ () => { getSoybeanMealListData()}}>
                                Refresh
                            </Button>
                        </Grid>
                        <Grid item className="mt-3">
                            <Button variant="contained" color="primary" onClick={() => { setIsOpenModalDownloadMarketPrice(true) }}>
                                Download Data
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                :
                <Grid item xs={12} className="flex items-center flex-row justify-end mb-3">

                </Grid>
        )
    }

    const rightHeaderInventoryPriceSearch = () => {
        return (
            rowSelectedInventoryPriceList.length < 1 ?
                <Grid item xs={12} className="mb-3">
                    <Grid container justifyContent='space-between'>
                        <Grid item className="mr-3">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    views={["year", "month"]}
                                    label="Month/Year"
                                    format="MM/yyyy"
                                    name="marketPriceDate"
                                    onChange={(date) => { handleChangeDate(date, "inventoryPrice") }}
                                    value={selectedDateInventoryPrice}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <Button className="w-full md:w-auto mt-5 ml-2" variant="outlined" size="small" disableElevation color="primary"  startIcon={<RefreshIcon/>} onClick={ () => { getSoybeanMealListData()}}>
                                Refresh
                            </Button>
                        </Grid>
                        <Grid item className="mt-3">
                            <Button variant="contained" color="primary" onClick={() => { setIsOpenModalDownloadInventoryPrice(true) }}>
                                Download Data
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                :
                <Grid item xs={12} className="flex items-center flex-row justify-end">
                    <Typography variant="body1" className="pr-4">{rowSelectedInventoryPriceList.length + " selected items"}</Typography>
                    <Button variant="contained" className={classes.deleteBtn} onClick={() => { setIsDeleteModalOpen(true) }}>
                        DELETE
                    </Button>
                </Grid>
        )
    }

    const rightHeaderMaterialEventSearch = () => {
        return (
            rowSelectedMaterialEventList.length < 1 ?
                <Grid item xs={12} className="mb-3">
                    <Grid container justifyContent='space-between'>
                        <Grid item className="mr-3">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    views={["year", "month"]}
                                    label="Month/Year"
                                    format="MM/yyyy"
                                    name="marketPriceDate"
                                    onChange={(date) => { handleChangeDate(date, "materialEvent") }}
                                    value={selectedDateMaterialEvent}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <Button className="w-full md:w-auto mt-5 ml-2" variant="outlined" size="small" disableElevation color="primary"  startIcon={<RefreshIcon/>} onClick={ () => { getSoybeanMealListData()}}>
                                Refresh
                            </Button>
                        </Grid>
                        <Grid item className="mt-3">
                            <Button variant="contained" color="primary" onClick={() => { setIsOpenModalDownloadEvent(true) }}>
                                Download Data
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                :
                <Grid item xs={12} className="flex items-center flex-row justify-end">
                    <Typography variant="body1" className="pr-4">{rowSelectedMaterialEventList.length + " selected items"}</Typography>
                    <Button variant="contained" className={classes.deleteBtn} onClick={() => { setIsDeleteModalOpen(true) }}>
                        DELETE
                    </Button>
                </Grid>
        )
    }

    const rightHeaderActivitySearch = () => {
        return (
            <Grid item xs={12} className="self-center text-left mb-3">
                <Grid container alignItems='flex-start'>
                    <Grid item className="mr-3">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                views={["year", "month"]}
                                margin="normal"
                                label="Month/Year"
                                format="MM/yyyy"
                                name="marketPriceDate"
                                onChange={(date) => { handleChangeDate(date, "activities") }}
                                value={selectedDateActivities}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <Button className="w-full md:w-auto mt-8 ml-2" variant="outlined" size="small" disableElevation color="primary"  startIcon={<RefreshIcon/>} onClick={ () => { getSoybeanMealListData()}}>
                                Refresh
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const onClickSelectedTab = (event, newValue) => {
        setIndexOfTab(newValue);
    };

    const handleClickConfirmDelete = () => {

        if (rowSelectedInventoryPriceList.length > 0) {
            return deleteInventoryPriceList()
        } else {
            return deleteMaterialEventList()
        }
    }

    const handleDownloadMarketPrice = (startDate, endDate) => {
        dispatch(loadAction.displayLoadingOverlay())
        SoybeanMealService.downloadDataMarketPrice(startDate, endDate).then((response) => {
            dispatch(snackbarAction.openSnackbar({ options: { variant: "success" }, message: "Download Completed" }))
            dispatch(loadAction.hideLoadingOverlay());
        }).catch((error) => {
            let newError = {...error}
            dispatch( snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            dispatch(loadAction.hideLoadingOverlay());
        });
    }

    const handleDownloadInventoryPrice = (inventoryType = "soybeanMeal", startDate, endDate) => {
        dispatch(loadAction.displayLoadingOverlay())
        InventoryPriceService.downloadDataInventoryPrice(inventoryType, startDate, endDate).then((response) => {
            dispatch(snackbarAction.openSnackbar({ options: { variant: "success" }, message: "Download Completed" }))
            dispatch(loadAction.hideLoadingOverlay());
        }).catch((error) => {
            let newError = {...error}
            dispatch( snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            dispatch(loadAction.hideLoadingOverlay());
        });
    }

    const handleDownloadEvent = (material = "soybeanMeal", startDate, endDate) => {
        dispatch(loadAction.displayLoadingOverlay())
        MaterialEventService.downloadDataEvent(material, startDate, endDate).then((response) => {
            dispatch(snackbarAction.openSnackbar({ options: { variant: "success" }, message: "Download Completed" }))
            dispatch(loadAction.hideLoadingOverlay());
        }).catch((error) => {
            let newError = {...error}
            dispatch( snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            dispatch(loadAction.hideLoadingOverlay());
        });
    }

    return (
        <Grid container spacing={2} justifyContent="center">
            <Paper square className="w-full mb-1" position="static" style={{zIndex:300}}>
                <Tabs value={indexOfTab} onChange={onClickSelectedTab} variant="fullWidth" indicatorColor="primary" textColor="primary">
                    <Tab label="Market Price" />
                    <Tab label="Event" />
                    <Tab label="Inventory Price" />
                    <Tab label="Activities" />
                </Tabs>
            </Paper>
            <Paper elevation={0} className="w-full">
                <TabPanel value={indexOfTab} index={0}>
                    <Grid item className="mb-10">
                        <SoybeanMealInput lastUpdate={soybeanMealList[0]?.['updatedAt']} />
                    </Grid>
                    <Grid item>
                        {rightHeaderSoybeanMealSearch()}
                        {soybeanMealTable()}
                    </Grid>
                </TabPanel>
                <TabPanel value={indexOfTab} index={1}>
                    <Grid item className="mb-10">
                        <EventInput materialType="soybeanMeal" getNewDataList={getSoybeanMealListData} />
                    </Grid>
                    <Grid item>
                        {rightHeaderMaterialEventSearch()}
                        {eventTable()}
                    </Grid>
                </TabPanel>
                <TabPanel value={indexOfTab} index={2}>
                    <Grid item className="mb-10">
                        <InventoryPriceInput inputType="soybeanMeal" getNewDataList={getSoybeanMealListData} dataList={inventoryPriceList}/>
                    </Grid>
                    <Grid item>
                        {rightHeaderInventoryPriceSearch()}
                        {soybeanMealInventoryPriceTable()}
                    </Grid>
                </TabPanel>
                <TabPanel value={indexOfTab} index={3}>
                    {rightHeaderActivitySearch()}
                    {activitiesTable()}
                </TabPanel>
            </Paper>
            <ModalDelete isOpen={isDeleteModalOpen} handleOnCancle={(e) => setIsDeleteModalOpen(e)} handleOnConfirm={handleClickConfirmDelete} />
            <ModalUpdateMaterialEventForm dataInfo={eventOject} isOpen={isOpenModalUpdateMaterialEventForm} handleOnCancle={(e) => setIsOpenModalUpdateMaterialEventForm(e)} getNewDataList={getSoybeanMealListData} />
            <ModalUpdateInventoryPriceForm dataInfo={inventoryPriceOject} isOpen={isOpenModalUpdateInventoryPriceForm} handleOnCancle={(e) => setIsOpenModalUpdateInventoryPriceForm(e)} getNewDataList={getSoybeanMealListData} dataList={inventoryPriceList} />
            <ModalDownloadMarketPrice isOpen={isOpenModalDownloadMarketPrice} handleOnCancle={(e) => setIsOpenModalDownloadMarketPrice(e)} handleDownload={handleDownloadMarketPrice} isSoybeanMealDate={true}/>
            <ModalDownloadEvent material="soybeanMeal" isOpen={isOpenModalDownloadEvent} handleOnCancle={(e) => setIsOpenModalDownloadEvent(e)} handleDownload={handleDownloadEvent}/>
            <ModalDownloadInventoryPrice inventoryType="soybeanMeal" isOpen={isOpenModalDownloadInventoryPrice} handleOnCancle={(e) => setIsOpenModalDownloadInventoryPrice(e)} handleDownload={handleDownloadInventoryPrice}/>
        </Grid>
    );
}

export default SoybeanMeal;