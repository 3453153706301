import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { Typography, Grid, Paper, FormGroup, FormControl, Switch, FormControlLabel, TextField, MenuItem } from '@material-ui/core';
import SoybeanMealService from '../services/SoybeanMeal';
import WheatService from '../services/Wheat';
import CornService from '../services/Corn';
import TapiocaService from '../services/Tapioca';
import LysineService from '../services/Lysine';
import { inventorySummaryAction, snackbarAction  } from '../store/actions';
import LineChart from '../components/Chart/LineChart'
import InputMultipleSelect from '../components/InputMultipleSelect';


const YearComparison = () => {
    const dispatch = useDispatch();
    const [inventoryPriceList, setInventoryPriceList] = useState([])
    const [isCheckedShowMarketPrice, setIsCheckedShowMarketPrice] = useState(false)
    const [yearList, setYearList] = useState([]);
    const [selectedYearList, setSelectedYearList] = useState([]);
    const unit = "THB/KG";

    const [marketPriceList, setMarketPriceList] = useState([])
    const [materialName, setMaterialName] = useState("")
    const inventorySummaryList = useSelector(state => state.inventorySummaryReducer);

    const material = [
        {
            key : "soybeanMeal",
            name: "Soybean meal"
        },
        {
            key : "wheat",
            name: "Wheat"
        },
        {
            key : "corn",
            name: "Corn"
        },
        {
            key : "tapioca",
            name: "Tapioca"
        },
        {
            key : "lysine",
            name: "L-lysine"
        },
    ]

    const getMarketPriceList = useCallback((materialName) => {
        if(materialName === "soybeanMeal"){
            SoybeanMealService.getSoybeanMealResultList(new Date().getFullYear()).then(res => {
                setMarketPriceList(res.data)
            }).catch((error) => {
                let newError = { ...error }
                dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            });
        }else if(materialName === "wheat"){
            WheatService.getWheatResultList(new Date().getFullYear()).then(res => {
                setMarketPriceList(res.data)
            }).catch((error) => {
                let newError = { ...error }
                dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            });
        }else if(materialName === "corn"){
            CornService.getCornResultList(new Date().getFullYear()).then(res => {
                setMarketPriceList(res.data)
            }).catch((error) => {
                let newError = { ...error }
                dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            });
        }else if(materialName === "tapioca"){
            TapiocaService.getTapiocaResultList(new Date().getFullYear()).then(res => {
                setMarketPriceList(res.data)
            }).catch((error) => {
                let newError = { ...error }
                dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            });
        }else if(materialName === "lysine"){
            LysineService.getLysineResultList(new Date().getFullYear()).then(res => {
                setMarketPriceList(res.data)
            }).catch((error) => {
                let newError = { ...error }
                dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            });
        }else{
            setMarketPriceList([])
        } 
    }, [dispatch]);

    const prepareChartDataList = useCallback((mergedDataList, marketPriceList) => {
        const newInventoryPriceList = [];
        let addedYearList = [];
        let materialKey
        if(materialName === 'soybeanMeal'){
            materialKey = 'soybeanMealInventory'
        }else if(materialName === 'wheat'){
            materialKey = 'wheatInventory'
        }else if(materialName === 'corn'){
            materialKey = 'cornInventory'
        }else if(materialName === 'tapioca'){
            materialKey = 'tapiocaInventory'
        }else if(materialName === 'lysine'){
            materialKey = 'lysineInventory'
        }else {
            materialKey = ''
        }

        for(const item of mergedDataList) {
            let newInventoryPriceObj = {};
            
            const fillFullDate = item['dateTimeQueryType'] ? item['dateTimeQueryType'] + '-01' : null

            const year = new Date(fillFullDate).getFullYear();

            if(fillFullDate){
                const newActualPriceKey = `actualPrice_${year}`;
                const month = new Date(fillFullDate).getMonth();
                const isExistedMonth = newInventoryPriceList.find(x => x.month === month )

                if(!isExistedMonth){
                    newInventoryPriceObj['month'] = new Date(fillFullDate).getMonth();
                    newInventoryPriceObj['date'] = fillFullDate
                    newInventoryPriceObj[newActualPriceKey] = item[materialKey];
                    if(newInventoryPriceList.indexOf(newInventoryPriceObj === -1)){
                        if(newInventoryPriceObj[newActualPriceKey]){
                            newInventoryPriceList.push(newInventoryPriceObj);
                        }
                    }
                }else{
                    newInventoryPriceObj['marketPrice'] = item['actualPrice']
                    isExistedMonth[newActualPriceKey] = item[materialKey];
                }
                
                if(addedYearList.indexOf(year) === -1){
                    addedYearList.push(year);
                }
            }

        }

        newInventoryPriceList.sort(( a, b) => a.date - b.date)

        let newList = [...newInventoryPriceList]

        for ( let item of newList){
            const isExistedActualPrice = marketPriceList.find(x => new Date(x.date).getMonth() === new Date(item.date).getMonth())
            item['marketPrice'] = isExistedActualPrice?.['actualPrice'] || null
        }

        addedYearList.sort( (a, b) => a - b);
        setYearList(addedYearList);
        setInventoryPriceList(newList);
    },[materialName])

    useEffect(() => {
        if(materialName.length > 0){
            getMarketPriceList(materialName)
            if(materialName === 'soybeanMeal' || materialName === 'wheat' ){
                setIsCheckedShowMarketPrice(false)
            }
        }
    }, [materialName, getMarketPriceList])

    useEffect(() => {
        dispatch(inventorySummaryAction.getInventorySummaryList("month"));
    }, [dispatch])

    useEffect(() => {
        if(inventorySummaryList.length > 0 && marketPriceList.length > 0){
            const mergedData = inventorySummaryList.concat(marketPriceList)
            prepareChartDataList([...mergedData], [...marketPriceList]);
        }
    }, [inventorySummaryList, marketPriceList, prepareChartDataList])

    const generateChartValueFieldList = () => {
        const valueFieldList = [];
        if( selectedYearList.length > 0 ) {
            if( isCheckedShowMarketPrice ) {  
                valueFieldList.push({ key: `marketPrice`, valueField: `marketPrice`, name: `Market Price`, isShowInLegend: true });
            }
            for(const year of selectedYearList) {
                valueFieldList.push({ key: `actualPrice_${year}`, valueField: `actualPrice_${year}`, name: `Inventory Price ${year}`, isShowInLegend: true });
            }
        }
        return valueFieldList;
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Paper elevation={0} className="p-2 md:p-4 h-full">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h3" style={{ fontWeight: 'bold' }}>Year Comparison </Typography>
                                    <Typography variant="h5" style={{ fontWeight: 'normal' }}> (Please select material and year) </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
                                <Grid item xs={3}>
                                    <TextField label="Material" select size="small" variant="outlined" fullWidth value={materialName} onChange={(e) => { setMaterialName(e.target.value) }}>
                                        {material.map((item) => {
                                            return (
                                                <MenuItem key={item.name} value={item.key}>{item.name}</MenuItem>
                                            )
                                        })}
                                    </TextField>
                                </Grid>
                                <Grid item xs="auto">
                                    <InputMultipleSelect
                                        label="Year"
                                        selectItemList={yearList}
                                        selectedValueList={selectedYearList}
                                        handleSelectChange={(event) => setSelectedYearList(event.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center">
                                <FormControl component="fieldset">
                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            value="start"
                                            control={<Switch color="primary" onChange={(e) => { setIsCheckedShowMarketPrice(e.target.checked) }} checked={isCheckedShowMarketPrice}/>}
                                            label="Show market price"
                                            labelPlacement="start"
                                            disabled={!materialName || materialName === 'soybeanMeal' || materialName === 'wheat'}
                                        />
                                    </FormGroup>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <LineChart
                                argumentRangeStart={inventoryPriceList[0]?.date}
                                argumentRangeEnd={inventoryPriceList[inventoryPriceList.length - 1]?.['date']}
                                dataList={inventoryPriceList}
                                argumentField="date"
                                argumentType="datetime"
                                argumentFormat="month"
                                argumentTitle="Month"
                                argumentConstantLineList={[]}
                                valueTitle={`Inventory price (${unit})`}
                                valueFieldList={generateChartValueFieldList()}
                                isStartFromZero={false}
                                isDisplayPoint={true}
                                unitType="THB"
                            />
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default YearComparison;