const { default: ActionTypes } = require("../actions/actionType");

const initialState = {
    notifications: []
};

const snackBarReducer = function(state = initialState, action){
    
    switch (action.type) {
        case ActionTypes.OPEN_SNACKBAR:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: action.key,
                        ...action.notification
                    }
                ]
            };

        case ActionTypes.CLOSE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.map(notification =>
                    action.dismissAll || notification.key === action.key
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                )
            };

        case ActionTypes.REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(
                    notification => notification.key !== action.key
                )
            };

        default:
            return state;
    }
};

export default snackBarReducer ;