import ApiProxy from '../utils/ApiProxy';

class Tapioca {

    static async getTapiocaList(offset = 0, limit = 0, month, year) {
        return new Promise(async (resolve, reject) => {
            let tapiocaList = await ApiProxy.sendRequest('GET', `tapiocas?offset=${offset}&limit=${limit}&month=${!month ? '' : month}&year=${!year ? '' : year}`).catch((error) => {
                reject(error);
            });
            resolve(tapiocaList);
        });
    }

    static async insertTapiocaObject(data) {
        return new Promise(async (resolve, reject) => {
            let insertResult = await ApiProxy.sendRequest('POST', 'tapiocas', data).catch((error) => {
                reject(error);
            });
            resolve(insertResult);
        });
    }

    static async insertTapiocaList(data) {
        return new Promise(async (resolve, reject) => {
            let insertResult = await ApiProxy.sendRequest('POST', 'tapiocas/upload', data).catch((error) => {
                reject(error);
            });
            resolve(insertResult);
        });
    }

    static async updateTapiocaObject(tapiocaId, data) {
        return new Promise(async (resolve, reject) => {
            let updateResult = await ApiProxy.sendRequest('PUT', `tapiocas/${tapiocaId}`, data).catch((error) => {
                reject(error);
            });
            resolve(updateResult);
        });
    }

    static async deleteTapiocaList(data) {
        return new Promise(async (resolve, reject) => {
            let updateResult = await ApiProxy.sendRequest('DELETE', 'tapiocas', data).catch((error) => {
                reject(error);
            });
            resolve(updateResult);
        });
    }

    static async downloadDataMarketPrice(startDate, endDate) {
        return new Promise(async (resolve, reject) => {
            let marketPriceExcel = await ApiProxy.exportData('GET', `tapiocas/download?startDate=${startDate}&endDate=${endDate}`).catch((error) => { reject(error) });
            resolve(marketPriceExcel);
        });
    }

    static async getTapiocaResultList(year) {
        return new Promise(async (resolve, reject) => {
            let tapiocaResultList = await ApiProxy.sendRequest('GET', `tapiocas/result?year=${year ? year : ""}`).catch((error) => {
                reject(error);
            });
            resolve(tapiocaResultList);
        });
    }

    static async downloadDataResult(year) {
        return new Promise(async (resolve, reject) => {
            let resultExcel = await ApiProxy.exportData('GET', `tapiocas/download/result?year=${year}`).catch((error) => { reject(error) });
            resolve(resultExcel);
        });
    }

    static async getTapiocaResultObjectByDate(month, year) {
        return new Promise(async (resolve, reject) => {
            let tapiocaResultObject = await ApiProxy.sendRequest('GET', `tapiocas/result/search?month=${!month ? '' : month}&year=${!year ? '' : year}`).catch((error) => {
                reject(error);
            });
            resolve(tapiocaResultObject);
        });
    }

    static async getTapiocaResultSummaryList(startDate) {
        return new Promise(async (resolve, reject) => {
            let cornResultSummaryList = await ApiProxy.sendRequest('GET', `tapiocas/summary?startDate=${startDate}`).catch((error) => {
                return reject(error);
            });
            return resolve(cornResultSummaryList);
        });
    }

    static async getHighestLowestActualPriceObject() {
        return new Promise(async (resolve, reject) => {
            let cornResultSummaryList = await ApiProxy.sendRequest('GET', `tapiocas/highest-lowest/actual-price`).catch((error) => {
                return reject(error);
            });
            return resolve(cornResultSummaryList);
        });
    }
}

export default Tapioca;