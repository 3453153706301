import React, { useEffect, useState } from 'react';
import { 
    Dialog, 
    DialogContent, 
    DialogTitle, 
    Typography,
    useMediaQuery,
    IconButton
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import MaterialEvent from '../../services/MaterialEvent';


function EventModal(props) {
    const { isDisplayDialog, materialType, handleCloseDialog, date } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [errorMessage, setErrorMessage] = useState(null);
    const [eventText, setEventText] = useState("")

    useEffect(() => {
        if( isDisplayDialog ) {
            if( materialType.length > 0 ) {
                MaterialEvent.getMaterialEventList('','','',materialType).then(resultObj => {
                    const data = resultObj['data'];
                    let eventObject = data.find(item => { return new Date(item.period).toDateString() === new Date(date).toDateString()})
                    if(eventObject){
                        setEventText(eventObject['event'])
                    }else{
                        setEventText("No data...")
                    }
                }).catch(error => {
                    setErrorMessage( !error.response ? "An error occurred, please try again" : error.response.data.message );
                });
            }
        }
    }, [isDisplayDialog, materialType, date]);

    return (
        <Dialog open={isDisplayDialog} aria-labelledby="form-dialog-title" fullWidth fullScreen={fullScreen} maxWidth="md" onClose={handleCloseDialog}>
            <DialogTitle disableTypography className="flex items-center justify-between">
                <div>
                    <Typography
                        variant='h3'
                        color="primary"
                    >
                        Event Indicator
                    </Typography>
                </div>
                <div>
                    <IconButton size="small" onClick={handleCloseDialog}>
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                { eventText.length > 0 ? (
                    eventText
                ) : (
                    <div>
                        { errorMessage ? (
                            <Typography variant="body1" color="error">{errorMessage}</Typography>
                        ) : (
                            <Typography variant="body1">No data...</Typography>
                        ) }
                    </div>
                ) }
            </DialogContent>
        </Dialog>
    )
}

export default EventModal