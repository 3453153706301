import React from "react";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox
} from "@material-ui/core";

function InputMultipleSelect(props) {

    const { label, selectItemList, selectedValueList, handleSelectChange } = props

    return (
        <FormControl
            variant="outlined"
            size="small"
            fullWidth
        >
            <InputLabel id="mutiple-checkbox">{label}</InputLabel>
            <Select
                labelId="mutiple-checkbox"
                id="select-mutiple-checkbox"
                multiple
                value={selectedValueList}
                onChange={handleSelectChange}
                renderValue={(selected) => selected.join(', ')}
                label={label}
            >
                {selectItemList.map((item) => (
                    <MenuItem dense key={item} value={item}>
                        <Checkbox size="small" disableRipple checked={selectedValueList.indexOf(item) > -1} />
                        {item}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default InputMultipleSelect;
