import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Button, Grid } from '@material-ui/core';
import useStyles from '../assets/styles';
import UserService from '../services/User';
import EnhancedTable from '../components/Table/EnhancedTable';
import ModalDelete from "../components/MainLayout/ModalDelete";
import HeaderSearch from "../components/MainLayout/HeaderSearch";
import { userAction, loadAction, snackbarAction } from '../store/actions';

const User = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [searchToggle, setSearchToggle] = useState(0);
    const [searchFullName, setSearchFullName] = useState("");
    const [rowSelectedList, setRowSelectedList] = useState([]);
    const [userIdSelectedList, setUserIdSelectedList] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const userList = useSelector(state => state.usersReducer);
    const userProfileObj = useSelector(state => state.userReducer);

    const getUserListData = useCallback(() => {
        dispatch(loadAction.displayLoadingOverlay())
        dispatch(userAction.getUserList(0, 0));
    }, [dispatch]);

    useEffect(() => {
        getUserListData()
    }, [getUserListData])

    const headCells = [
        { id: 'fullName', label: 'Name', numeric: false, disablePadding: false, isDate: false, isBoolean: false },
        { id: 'createdAt', label: 'Create Date', numeric: false, disablePadding: false, isDate: true, isBoolean: false, dateFormat: "DD Month YYYY HH:mmน.", className: 'w-64', headAlign: 'center' },
        { id: 'lastLogin', label: 'Last Login', numeric: false, disablePadding: false, isDate: true, isBoolean: false, dateFormat: "DD Month YYYY HH:mmน.", className: 'w-64', headAlign: 'center' },
    ]

    const handleTableClick = (data) => {
        if (userProfileObj.userId === data.userId) {
            history.push("/profile");
        } else {
            history.push("/user/" + data.userId);
        }
    }

    const handleRowSeletion = (selectedData) => {
        setRowSelectedList(selectedData)
    }

    const handleQuickSearch = (value) => {
        setSearchToggle(!searchToggle);
        setSearchFullName(value)
    }

    const onClickDeleteUserButton = (event) => {
        let userIdList = [];
        for (let obj of rowSelectedList) {
            userIdList.push(obj.userId)
        }
        setUserIdSelectedList(userIdList)
        if (userIdList.length > 0) setIsDeleteModalOpen(true)
    }

    const deleteUserList = async () => {
        setIsDeleteModalOpen(false)
        dispatch(loadAction.displayLoadingOverlay())
        UserService.deleteUserList({ "userIdList": userIdSelectedList }).then((response) => {
            dispatch(userAction.getUserList());
            setRowSelectedList([])
            setUserIdSelectedList([])
            dispatch(snackbarAction.openSnackbar({ options: { variant: "success" }, message: "User has been successfully deleted" }))
        }).catch((error) => {
            let newError = {...error}
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            dispatch(loadAction.hideLoadingOverlay());
        });
    }

    const rightHeaderSearch = () => {
        return (
            rowSelectedList.length < 1 ?
                <Grid item xs={12} className="self-center text-right">
                    <Button component={Link} to={'/user/create'} variant="contained" color="primary">
                        Create User
                    </Button>
                </Grid>
            :
                <Grid item xs={12} className="flex items-center flex-row justify-end">
                    <Typography variant="body1" className="pr-4">{rowSelectedList.length + " selected items"}</Typography>
                    <Button variant="contained" className={classes.deleteBtn} onClick={onClickDeleteUserButton}>
                        DELETE
                    </Button>
                </Grid>
        )
    }

    const handleSearchClear = () => {
        dispatch(loadAction.displayLoadingOverlay())
        setRowSelectedList([])
        setSearchToggle(!searchToggle)
        setSearchFullName("")
        dispatch(userAction.getUserList(0, 0));
    }

    return (
        <Grid container spacing={2} justifyContent="center">
            <HeaderSearch tittle="User Management" quickSearchValue={searchFullName} handleQuickSearch={handleQuickSearch} rightHeaderSearch={rightHeaderSearch} handleSearchClear={handleSearchClear} />
            <Grid item xs={12}>
                <EnhancedTable tableDataList={userList.filter((item) => { return ( String(item.fullName.toLowerCase()).includes(searchFullName.toLowerCase()) && item.userId !== userProfileObj.userId) })} headCellList={headCells} handleTableClick={handleTableClick} handleRowSeletion={handleRowSeletion} isCheckBox={true} rowSelectedList={rowSelectedList} searchToggle={searchToggle} />
            </Grid>
            <ModalDelete isOpen={isDeleteModalOpen} handleOnCancle={(e) => setIsDeleteModalOpen(e)} handleOnConfirm={() => { deleteUserList() }} />
        </Grid>
    );
}

export default User;