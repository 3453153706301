import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { loadAction, userAction } from '../store/actions';
import Validator from '../utils/Validator'
import {
    Hidden,
    Grid,
    TextField,
    Typography,
    Link,
    Button
} from "@material-ui/core";
import useStyles from '../assets/styles';
import InputPassword from "../components/InputPassword";
import UserService from "../services/User";
import { firstPageUrl } from '../configs/PermissionRoutes';
import logo from '../assets/images/thaifood-logo.png';

const Login = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const checkDisabledBtn = () => {
        return [email, password].some(ele => ele === '')
    }

    const submitForm = async (event) => {
        event.preventDefault();
        dispatch(loadAction.displayLoadingOverlay());

        let { isValid, message } = Validator.validateData(email, 'email', {}, "Email");

        if (!isValid) {
            setErrorMessage(message);
            dispatch(loadAction.hideLoadingOverlay());
            return null
        }
        let loginResult = await UserService.login(email, password).catch((error) => {
            setErrorMessage(error.message);
        });

        if (loginResult && loginResult['status'].toLowerCase() === 'success') {
            dispatch(userAction.authenUser()).then(() => {
                dispatch(loadAction.hideLoadingOverlay());
                props.history.push(firstPageUrl);
            })
        } else {
            setErrorMessage('Email or Password is wrong');
            dispatch(loadAction.hideLoadingOverlay());
        }
    };

    return (
        <Grid container className="h-full">
            <Hidden xsDown>
                <Grid item xs={false} sm={6} md={6} lg={8} className="flex h-full">
                    <img src="/assets/images/login_cover.jpg" alt="Login" className="object-cover w-full"/>
                </Grid>
            </Hidden>
            <Grid item xs={12} sm={6} md={6} lg={4}>
                <form onSubmit={submitForm} className="h-full">
                    <div className="h-full bg-white rounded-lg shadow-2xl flex items-center">
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <img alt="Thaifood Logo" src={logo} className="h-32 mx-auto" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h4' align="center">Sign In</Typography>
                            </Grid>
                            <Grid item xs={12} className="h-12">
                                <Typography color="error" align="center">
                                    {errorMessage}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" spacing={2}>
                                    <Grid item xs={8}>
                                        <TextField
                                            name="Email"
                                            type="text"
                                            label="Email"
                                            variant="outlined"
                                            size='small'
                                            required
                                            fullWidth
                                            onChange={(event) => setEmail(event.target.value)}
                                            value={email}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <InputPassword
                                            password={password}
                                            setPassword={setPassword}
                                        />
                                    </Grid>
                                    <Grid item xs={8} className="text-center">
                                        <Link className={`cursor-pointer ${classes.link}`} variant="body2" onClick={() => props.history.push("/forget-password")}>
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className="text-center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disableElevation
                                    disabled={checkDisabledBtn()}
                                    className="w-40"
                                >
                                    Sign In
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </form>
            </Grid>
        </Grid >
    );
}

export default Login;