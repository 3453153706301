import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { snackbarAction } from '../../store/actions'

const FunctionalNotifier = () => {

    const dispatch = useDispatch();
    const notifications = useSelector(state => state.snackBarReducer.notifications);
    const [displayed, setDisplayed] = useState([]);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = id => { setDisplayed(prevDisplayed => [...prevDisplayed, id]); };
    const removeDisplayed = id => {
        setDisplayed(prevDisplayed => [...prevDisplayed.filter(key => id !== key)]);
    };

    useEffect(() => {
        (notifications || []).forEach(
            ({ key, message, options = {}, dismissed = false }) => {
                if (dismissed) return closeSnackbar(key);
                if (displayed.includes(key)) return;
                enqueueSnackbar(message, {
                    key,
                    ...options,
                    onClose: (event, reason, key) => {
                        if (options.onClose) {
                            options.onClose(event, reason, key);
                        }
                    },
                    onExited: (event, key) => {
                        dispatch(snackbarAction.removeSnackbar(key));
                        removeDisplayed(key);
                    }
                });
                storeDisplayed(key);
            }
        );
    }, [notifications, closeSnackbar, dispatch, displayed, enqueueSnackbar]);

    return null;
};

export default FunctionalNotifier;
