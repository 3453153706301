import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import useStyles from '../assets/styles';
import {firstPageUrl} from '../configs/PermissionRoutes';
import { loadAction, userAction } from '../store/actions';

const PageNotFound = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory()
    const userProfile = useSelector(state => state.userReducer);
    const currentRole = userProfile["userLevel"];
    const goTo = currentRole !== "guest" ? firstPageUrl : '/login';

    useEffect(() => {
        if(!userProfile['userId']){
            history.push("/login");
        }
    }, [userProfile, history]);

    useEffect(() => {
        dispatch(loadAction.displayLoadingOverlay());
        dispatch(userAction.authenUser());
        dispatch(loadAction.hideLoadingOverlay());
    }, [dispatch]);
    
    return (
        <Grid container>
            <Grid item xs={12} className="pt-60" style={{height: '100vh', justifyContent: 'center', textAlign: 'center'}}>
                <Typography variant="h1" className="pb-4">SORRY, PAGE NOT FOUND</Typography><br/>
                <Link className={`cursor-pointer ${classes.pageNotFoundBtn}`} to={goTo} >
                    GO BACK
                </Link>
            </Grid>
        </Grid>
    );
}

export default PageNotFound;