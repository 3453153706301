import { loadAction } from './index'
import Corn from "../../services/Corn";
const { default: ActionTypes } = require("./actionType");


export function getCornList(offset, limit, month, year) {
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return Corn.getCornList(offset, limit, month, year)
        .then((corns) => {
            dispatch({
                type: ActionTypes.GET_CORN_LIST,
                payload: corns.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        })
        .catch(() => {
            dispatch({
                type: ActionTypes.GET_CORN_LIST,
                payload: []
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}


