import { loadAction } from './index'
import Corn from "../../services/Corn";
const { default: ActionTypes } = require("./actionType");


export function getCornResultList(year) {
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return Corn.getCornResultList(year)
        .then((cornResult) => {
            dispatch({
                type: ActionTypes.GET_CORN_RESULT_LIST,
                payload: cornResult.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        })
        .catch(() => {
            dispatch({
                type: ActionTypes.GET_CORN_RESULT_LIST,
                payload: []
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}


export function getCornResultSummaryList(startDate) {
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return Corn.getCornResultSummaryList(startDate).then((soybeanMealResult) => {
            dispatch({
                type: ActionTypes.GET_CORN_RESULT_LIST,
                payload: soybeanMealResult.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        }).catch(() => {
            dispatch({
                type: ActionTypes.GET_CORN_RESULT_LIST,
                payload: []
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}

