import React, { useState } from 'react';
import { Button, Grid, Modal, Paper, TextField, MenuItem } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

export function ModalDownloadInventorySummary(props) {
    const { isOpen, handleOnCancle, handleDownload = () => {}} = props;

    const [year, setYear] = useState(new Date())
    const [queryType, setQueryType] = useState("Week")

    const onClickDownload = () => {
        handleOnCancle(false)
        handleDownload(queryType.toLowerCase(), new Date(year).getFullYear())
    }

    const selectedQueryType = ["Week", "Month", "Quarter", "Year"]

    return (
        <Modal open={isOpen} onClose={() => { handleOnCancle(false) }} aria-labelledby="modal-update-form" className="flex justify-center items-center">
            <Paper elevation={0} className="p-10" style={{ maxWidth: '90%', width: 620, outline: 'none' }}>
                <Grid container>
                    <Grid container item xs={12}>
                    <Grid item xs={12} className="mt-3">
                            <TextField label="Unit" select size="small" variant="outlined" value={queryType} onChange={(e) => { setQueryType(e.target.value) }}>
                                {selectedQueryType.map((name, index) => {
                                    return (
                                        <MenuItem key={index} value={name}>{name}</MenuItem>
                                    )
                                })}
                            </TextField>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    autoOk
                                    inputVariant="outlined"
                                    size="small"
                                    views={["year"]}
                                    label="Year"
                                    format="yyyy"
                                    name="marketPriceYear"
                                    className="ml-2"
                                    value={year}
                                    onChange={(date) => { setYear(date) }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className="text-right pt-4">
                        <Button className="mr-2" onClick={() => { handleOnCancle(false) }}>
                            CANCEL
                        </Button>
                        <Button className="ml-2" variant="contained" color="primary" onClick={() => onClickDownload()}>
                            DOWNLOAD
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal> 
    )
}