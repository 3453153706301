import ApiProxy from '../utils/ApiProxy';

class UserService {

    static async login(email, password) {

        return new Promise(async (resolve, reject) => {
            let data = {
                'email': email,
                'password': password,
            };
            let loginResult = await ApiProxy.sendRequest('POST', 'users/login', data).catch((error) => {
                return reject({
                    'status': 'fail',
                    'message': error.response?.data.message || error.message
                });
            });

            if (!loginResult) {
                return reject({
                    'status': 'fail',
                    'message': ''
                });
            }

            return resolve({ ...loginResult });
        });
    }

    static async updateUserProfile(dataProfiles) {
        let dataProfileValues = {}
        for (let dataProfile in dataProfiles) {
            dataProfileValues = { ...dataProfileValues, [dataProfile]: dataProfiles[dataProfile].value }
        }
        return new Promise(async (resolve, reject) => {
            let userProfile = await ApiProxy.sendRequest('PUT', 'users', dataProfileValues).catch((error) => {
                reject(error);
            });

            resolve(userProfile);
        })
    }

    static async updateUserPassword(dataObj) {
        return new Promise(async (resolve, reject) => {
            let userData = await ApiProxy.sendRequest('PUT', 'users/password', dataObj).catch((error) => {
                reject(error);
            });
            resolve(userData);
        })
    }

    static async getUserProfile() {
        return new Promise(async (resolve, reject) => {
            let userProfile = await ApiProxy.sendRequest('GET', 'users/me').catch((error) => {
                reject();
            });
            resolve(userProfile);
        });
    }

    static async logoutUser() {

        return new Promise(async (resolve, reject) => {
            await ApiProxy.sendRequest('GET', 'users/logout').catch((error) => {
                reject(error);
            });

            resolve();
        })
    }

    static async getExpToken(query) {

        return new Promise(async (resolve, reject) => {
            let dataToken = await ApiProxy.sendRequest('GET', `users/check-exp?token=${query}`).catch((error) => {
                reject();
            });

            resolve(dataToken);
        });
    }

    static async resetPassword(password, dataToken) {

        return new Promise(async (resolve, reject) => {
            let data = {
                'password': password,
                'dataToken': dataToken
            };
            let resetResult = await ApiProxy.sendRequest('POST', 'users/reset-password', data).catch((error) => {
                return reject({
                    'status': 'fail',
                    'message': error.response?.data.message || error.message
                });
            });

            if (!resetResult) {
                return reject({
                    'status': 'fail',
                    'message': ''
                });
            }
            return resolve({
                'status': 'success',
                'message': resetResult.message
            });
        });
    }

    static async forgetPassword(email) {

        return new Promise(async (resolve, reject) => {
            let data = {
                email
            };
            let resetResult = await ApiProxy.sendRequest('POST', 'users/forget-password', data).catch((error) => {
                return reject({
                    'status': 'fail',
                    'message': error.response?.data.message || error.message
                });
            });

            if (!resetResult) {
                return reject({
                    'status': 'fail',
                    'message': ''
                });
            }
            return resolve(resetResult);
        });
    }

    static async getUserList(offset = 0, limit = 0) {
        return new Promise(async (resolve, reject) => {
            let userList = await ApiProxy.sendRequest('GET', `users?offset=${!offset ? '' : offset}&limit=${!limit ? '' : limit}`).catch((error) => {
                reject(error);
            });
            resolve(userList);
        });
    }

    static async getUserObject(userId) {
        return new Promise(async (resolve, reject) => {
            if (userId === 'create') {
                resolve({});
            }
            let userObj = await ApiProxy.sendRequest('GET', `users/${userId}`).catch((error) => {
                reject(error);
            });
            resolve(userObj);
        });
    }

    static async getUserLevelList() {
        return new Promise(async (resolve, reject) => {
            let userLevelList = await ApiProxy.sendRequest('GET', `user-levels`).catch((error) => { reject(error) });
            resolve(userLevelList);
        });
    }

    static async insertUserObject(data) {
        return new Promise(async (resolve, reject) => {
            let insertResult = await ApiProxy.sendRequest('POST', 'users', data).catch((error) => {
                reject(error);
            });
            resolve(insertResult);
        });
    }

    static async updateUserObject(userId, data) {
        return new Promise(async (resolve, reject) => {
            let updateResult = await ApiProxy.sendRequest('PUT', `users/${userId}`, data).catch((error) => {
                reject(error);
            });
            resolve(updateResult);
        });
    }

    static async deleteUserList(data) {
        return new Promise(async (resolve, reject) => {
            let updateResult = await ApiProxy.sendRequest('DELETE', 'users', data).catch((error) => {
                reject(error);
            });
            resolve(updateResult);
        });
    }

}

export default UserService;