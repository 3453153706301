import React, { memo } from "react";
import {
    Grid
} from "@material-ui/core";

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import CircleGauge from '../Chart/CircleGauge';

function PriceSuggestionResultArea(props) {

    const { priceSuggestionObj, unit, unitType="THB" } = props;

    const renderPriceSuggestionResult = () => {
        let percentText;
        let inputText;
        const userInputPrice = priceSuggestionObj['userInputPrice'];
        const targetPrice = priceSuggestionObj['targetPrice'];
        const minValue = priceSuggestionObj['minValue'];
        const maxValue = priceSuggestionObj['maxValue'];
        const suggestionPercentage = priceSuggestionObj['suggestionPercentage'];
        const diffPercentage = priceSuggestionObj['diffPercentage'];
        const chartScale = priceSuggestionObj['chartScale'];

        if( isNaN(targetPrice) ) {
            inputText = <div className="w-full bg-gray-100 border border-gray-400 text-gray-700 px-2 py-1 text-center rounded">Target price not available</div>;
            percentText = <div>โอกาสที่ราคาจะสูง (<ArrowDropUpIcon style={{ color: "#BE4B31"}}/>) กว่า  {Number(userInputPrice).toFixed(2)} {unitType} เป็น {(100 - Number(suggestionPercentage)).toFixed(2)}%</div>;
        } else {
            if(userInputPrice < targetPrice){
                inputText = <div className="w-full bg-green-100 border border-green-400 text-green-700 px-2 py-1 text-center rounded">Lower than target {Math.abs(diffPercentage).toFixed(2)}%</div>;
                percentText = <div>โอกาสที่ราคาจะต่ำ (<ArrowDropDownIcon style={{ color: "#5DA22F"}}/>) กว่า {Number(userInputPrice).toFixed(2)} {unitType} เป็น {Number(suggestionPercentage).toFixed(2)}% </div>;
            } else {
                inputText = <div className="w-full bg-red-100 border border-red-400 text-red-700 px-2 py-1 text-center rounded">Higher than target {Math.abs(diffPercentage).toFixed(2)}%</div>;
                percentText = <div>โอกาสที่ราคาจะสูง (<ArrowDropUpIcon style={{ color: "#BE4B31"}}/>) กว่า  {Number(userInputPrice).toFixed(2)} {unitType} เป็น {(100 - Number(suggestionPercentage)).toFixed(2)}%</div>;
            }
        }

        return (
            <Grid container spacing={1}>
                <Grid item xs={6} className="text-xs">
                    {percentText}
                </Grid>
                <Grid item xs={6}>
                    { inputText }
                </Grid>
                <Grid item xs={12}>
                    <CircleGauge 
                        unit={unit}
                        startValue={minValue} 
                        endValue={maxValue} 
                        value={userInputPrice} 
                        targetValue={targetPrice} 
                        valueScale={chartScale}
                        unitType={unitType}
                    />
                </Grid>
                { targetPrice > maxValue || targetPrice < minValue ? (
                    <Grid item xs={12}>
                        <h5 className="text-red-600">* Target Out-bounded</h5>
                    </Grid>
                ) : (null) }
                { userInputPrice > maxValue || userInputPrice < minValue ? (
                    <Grid item xs={12}>
                        <h5 className="text-red-600">* Input Out-bounded</h5>
                    </Grid>
                ) : (null) }
            </Grid>
        );
    }

    return priceSuggestionObj ? renderPriceSuggestionResult() : null ;
}

export default memo(PriceSuggestionResultArea);