import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'

function AlertModal({ title, content, action = () => { }, buttonName }) {

    const [open, setOpen] = useState(true);

    const handleActionButton = () => {
        action()
        setOpen(false);
    };

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle>
                <Typography
                    variant='h3'
                    color="primary"
                >
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent className="h-20">
                <Typography>
                    {content}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleActionButton} color="primary" variant="contained">{buttonName}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AlertModal