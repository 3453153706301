import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
  MenuItem,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { loadAction, snackbarAction } from "../../../store/actions";
import WheatService from "../../../services/Wheat";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateObject from "../../../utils/DateObject";

export function ModalUpdateWheatForm(props) {
  const {
    dataInfo,
    isOpen,
    handleOnCancle,
    getNewDataList = () => {},
    dataList = [],
  } = props;

  const dispatch = useDispatch();
  const [wheatId, setWheatId] = useState(dataInfo["id"]);
  const [date, setDate] = useState(dataInfo["updatedMarketPriceDate"]);
  const [month, setMonth] = useState(dataInfo["marketPriceDate"]?.substring(4));
  const [year, setYear] = useState(
    dataInfo["marketPriceDate"]?.substring(0, 4)
  );
  const [marketPrice, setMarketPrice] = useState(dataInfo["marketPrice"]);

  const placeholdertext = "Max 999";

  const separatorList = {
    regex: /^(0|[1-9][0-9]{0,2})(\.[0-9]{0,2})?$/,
    incompleteDecimal: /^(0|[1-9][0-9]{0,2})\.$/,
  };

  useEffect(() => {
    setWheatId(dataInfo["id"]);
    setDate(dataInfo["updatedMarketPriceDate"]);
    setMonth(dataInfo["marketPriceDate"]?.substring(4));
    setYear(dataInfo["marketPriceDate"]?.substring(0, 4));
    setMarketPrice(dataInfo["marketPrice"]);
  }, [dataInfo]);

  const checkIsDisableSaveBtn = () => {
    return !(marketPrice > 0 && month && year);
  };

  const onClickSubmitForm = async () => {
    const isExisted = dataList.find((item) => {
      return (
        item.updatedMarketPriceDate ===
          DateObject.convertDateTime(date, "YYYY-MM-DD") &&
        item.marketPriceDate === String(`${year}${month}`) &&
        item.id !== wheatId
      );
    });
    if (isExisted) {
      dispatch(
        snackbarAction.openSnackbar({
          options: { variant: "error" },
          message: "Cannot save change due to duplicated date",
        })
      );
      dispatch(loadAction.hideLoadingOverlay());
    } else {
      handleOnCancle(false);
      let data = {
        updatedMarketPriceDate: date,
        marketPriceDate: `${year}${month}`,
        marketPrice: marketPrice,
      };
      dispatch(loadAction.displayLoadingOverlay());
      WheatService.updateWheatObject(wheatId, data)
        .then((response) => {
          dispatch(
            snackbarAction.openSnackbar({
              options: { variant: "success" },
              message: "Market price data has been updated",
            })
          );
          getNewDataList();
          dispatch(loadAction.hideLoadingOverlay());
        })
        .catch((error) => {
          let newError = { ...error };
          dispatch(
            snackbarAction.openSnackbar({
              options: { variant: "error" },
              message: !newError.response
                ? "An error occurred, please try again"
                : newError.response.data.message,
            })
          );
          dispatch(loadAction.hideLoadingOverlay());
        });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleOnCancle(false);
      }}
      aria-labelledby="modal-update-form"
      className="flex justify-center items-center"
    >
      <Paper
        elevation={0}
        className="p-10"
        style={{ maxWidth: "90%", width: 620, outline: "none" }}
      >
        <Grid container>
          <Grid item xs={12} className="pb-4">
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              Wheat form
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography variant="body1" className="self-center pr-5">
                Market Price Date
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  label="Date"
                  format="dd/MM/yyyy"
                  name="marketPriceDate"
                  onChange={(date) => {
                    setDate(date);
                  }}
                  value={date}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className="self-center pr-2 mb-2 mt-2"
              >
                Market Price Year
              </Typography>
              <TextField
                type="tel"
                label="Year"
                variant="outlined"
                size="small"
                autoComplete="off"
                inputProps={{ maxLength: 4 }}
                value={year}
                onChange={(e) => {
                  setYear(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className="self-center pr-2 mb-2 mt-2"
              >
                Market Price Month
              </Typography>
              <Grid item xs={4}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Grid item xs={10}>
                    <TextField
                      label="Month"
                      select
                      margin="dense"
                      variant="outlined"
                      value={month}
                      fullWidth
                      onChange={(e) => {
                        setMonth(e.target.value);
                      }}
                    >
                      {DateObject.MONTH_OBJECT_LIST.map((monthObject) => {
                        return (
                          <MenuItem
                            key={monthObject.value}
                            value={monthObject.value}
                          >
                            {monthObject.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className="self-center pr-2 mb-2 mt-2"
              >
                Market Price
              </Typography>
              <TextField
                label="Unit USD"
                placeholder={placeholdertext}
                variant="outlined"
                size="small"
                autoComplete="off"
                value={marketPrice}
                onChange={(e) => {
                  const inputNumber = e.target.value;
                  if (separatorList.regex.test(inputNumber)) {
                    if (inputNumber >= 0 && inputNumber <= 999) {
                      setMarketPrice(inputNumber);
                    }
                  } else if (
                    separatorList.incompleteDecimal.test(inputNumber)
                  ) {
                    setMarketPrice(inputNumber);
                  } else if (inputNumber === "") {
                    setMarketPrice("");
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className="text-right pt-4">
            <Button
              className="mr-2"
              onClick={() => {
                handleOnCancle(false);
              }}
            >
              CANCEL
            </Button>
            <Button
              className="ml-2"
              variant="contained"
              color="primary"
              onClick={() => onClickSubmitForm()}
              disabled={checkIsDisableSaveBtn()}
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
