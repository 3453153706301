import { 
    Grid,
    Typography
} from '@material-ui/core';

import InventoryPrice from '../services/InventoryPrice';
import WebSetting from '../services/WebSetting';

import { useDispatch, useSelector } from 'react-redux';
import { snackbarAction, priceSummaryAction } from '../store/actions';

import { useEffect, useState } from 'react';
import DateObject from '../utils/DateObject';
import PriceSummaryCard from '../components/Cards/PriceSummaryCard';
import ConvertPriceSummaryCard from '../components/Cards/ConvertPriceSummaryCard';

const initInventoryObj = {
    soybeanMeal: {},
    wheat: {},
    corn: {},
    tapioca: {},
    lysine: {}
};

const Home = () => {

    const dispatch = useDispatch();
    const userProfile = useSelector(state => state.userReducer);
    const priceSummaryReducer = useSelector(state => state.priceSummaryReducer);
    const [currentMonthInventoryObj, setCurrentMonthInventoryObj] = useState({...initInventoryObj});
    const [previousMonthInventoryObj, setPreviousMonthInventoryObj] = useState({...initInventoryObj});
    const [highestLowestInventoryObj, setHighestLowestInventoryObj] = useState({...initInventoryObj});
    const [priceSummaryObj, setPriceSummaryObj] = useState({...initInventoryObj});
    const [settingId, setSettingId] = useState('');
    const [exchangeValueObj, setExchangeValueObj] = useState({ exchangeRate: 33, premium: 89 });

    useEffect(() => {
        getAllWeekInventorySummaryList();
        getHighestLowestInventorySummaryList();
        getPriceSummaryObject();
        getExchangeValueObject();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sumValueInventoryObj = (sourceObj, addObj) => {
        const newInventoryObj = {
            week: null,
            inventoryPriceDate: null,
            targetPrice: null,
            inventoryPrice: null,
            countTargetPrice: 0,
            countInventoryPrice: 0
        };

        newInventoryObj['week'] = addObj['week'];
        newInventoryObj['inventoryPriceDate'] = addObj['inventoryPriceDate'];
        if( sourceObj.hasOwnProperty('targetPrice') ) {
            newInventoryObj['targetPrice'] = sourceObj['targetPrice'];
            if( addObj['targetPrice'] ) {
                newInventoryObj['targetPrice'] = Math.round( (newInventoryObj['targetPrice'] + addObj['targetPrice']) * 100) / 100;
                newInventoryObj['countTargetPrice'] = sourceObj['countTargetPrice'] + 1;
            }
        } else {
            newInventoryObj['targetPrice'] = addObj['targetPrice'];
            newInventoryObj['countTargetPrice'] = 1;
        }
        if( sourceObj.hasOwnProperty('inventoryPrice') ) {
            newInventoryObj['inventoryPrice'] = sourceObj['inventoryPrice'];
            if( addObj['inventoryPrice'] ) {
                newInventoryObj['inventoryPrice'] = Math.round( (newInventoryObj['inventoryPrice'] + addObj['inventoryPrice']) * 100) / 100;
                newInventoryObj['countInventoryPrice'] = sourceObj['countInventoryPrice'] + 1;
            }
        } else {
            newInventoryObj['inventoryPrice'] = addObj['inventoryPrice'];
            newInventoryObj['countInventoryPrice'] = 1;
        }
        
        return {...newInventoryObj};
    }

    const averageInventoryObj = (inventoryObj) => {

        for( const key in inventoryObj ) {
            const sourceObj = inventoryObj[key];
            sourceObj['targetPrice'] = sourceObj.hasOwnProperty('targetPrice') && sourceObj['countTargetPrice'] > 0 ? Math.round( (sourceObj['targetPrice'] / sourceObj['countTargetPrice']) * 100) / 100 : 0;
            sourceObj['inventoryPrice'] = sourceObj.hasOwnProperty('inventoryPrice') && sourceObj['countInventoryPrice'] > 0 ? Math.round( (sourceObj['inventoryPrice'] / sourceObj['countInventoryPrice']) * 100) / 100 : 0;
        }

    }

    const getAllWeekInventorySummaryList = () => {
        const startDateObj = DateObject.addMonths(new Date(), -1);
        const startDate = DateObject.convertDateTime(startDateObj, 'YYYY-MM-01');
        const endDateObj = DateObject.getLastDayOfMonth( DateObject.getDateTime('YYYY-MM-DD') );
        const endDate = DateObject.convertDateTime(endDateObj, 'YYYY-MM-DD');
        InventoryPrice.getAllWeekInventorySummaryList(startDate, endDate).then(resultObj => {
            const inventoryList = resultObj['data'];
            if( inventoryList.length > 0 ) {
                let newCurrentMonthInventoryObj = {...initInventoryObj};
                let newPreviousMonthInventoryObj = {...initInventoryObj};

                const currentMonth = parseInt(DateObject.getDateTime('MM'));
                for( const inventoryObj of inventoryList ) {
                    const inventoryMonth = parseInt( DateObject.convertDateTime(inventoryObj['inventoryPriceDate'], 'MM') );
                    const inventoryType = inventoryObj['inventoryType'];

                    if( currentMonth === inventoryMonth ) {
                        newCurrentMonthInventoryObj[inventoryType] = sumValueInventoryObj(newCurrentMonthInventoryObj[inventoryType], inventoryObj);
                    } else {
                        newPreviousMonthInventoryObj[inventoryType] = sumValueInventoryObj(newPreviousMonthInventoryObj[inventoryType], inventoryObj);
                    }
                }

                averageInventoryObj(newCurrentMonthInventoryObj);
                averageInventoryObj(newPreviousMonthInventoryObj);

                setCurrentMonthInventoryObj({...newCurrentMonthInventoryObj});
                setPreviousMonthInventoryObj({...newPreviousMonthInventoryObj});
            }

        }).catch(error => {
            const newError = { ...error }
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
        });
    }

    const getHighestLowestInventorySummaryList = () => {
        InventoryPrice.getHighestLowestInventorySummaryList().then(resultObj => {
            const inventoryList = resultObj['data'];
            if( inventoryList.length > 0 ) {
                const newHighestLowestInventoryObj = {...initInventoryObj};
                
                for( const inventoryObj of inventoryList ) {
                    const inventoryType = inventoryObj['inventoryType'];

                    newHighestLowestInventoryObj[inventoryType] = inventoryObj;
                }

                setHighestLowestInventoryObj({...newHighestLowestInventoryObj});
            }

        }).catch(error => {
            const newError = { ...error }
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
        });
    }

    const getExchangeValueObject = () => {
        WebSetting.getWebSettingList('exchange_value_object').then(response => {
            const responseObj = response['data'][0];
            setExchangeValueObj({...JSON.parse(responseObj['value'])});
            setSettingId(responseObj['id']);
        }).catch(error => {
            const newError = { ...error }
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
        });
    }

    const getPriceSummaryObject = () => {
        const startDateObj = DateObject.addMonths(new Date(), -13);
        const startDate = DateObject.convertDateTime(startDateObj, 'YYYY-MM-DD');
        dispatch( priceSummaryAction.getPriceSummaryObject(startDate, 12) );
    }

    useEffect(() => {
        if( priceSummaryReducer && Object.keys(priceSummaryReducer).length > 0 ) {
            setPriceSummaryObj({...priceSummaryReducer});
        }
    }, [priceSummaryReducer]);

    const handleSaveButtonClick = (newExchangeRate, newPremium) => {
        const newExchangeValueObj = {...exchangeValueObj};
        newExchangeValueObj['exchangeRate'] = newExchangeRate;
        newExchangeValueObj['premium'] = newPremium;
        const updateValue = JSON.stringify(newExchangeValueObj);
        WebSetting.updateWebSettingObject(settingId, { value: updateValue }).then(response => {
            setExchangeValueObj(newExchangeValueObj);
        } ).catch(error => {
            const newError = { ...error }
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
        });

    }
    
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h4">Price Summary</Typography>
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
                <ConvertPriceSummaryCard
                    title="Soybean Meal"
                    unit="USD/To"
                    purchasingUrl="/data-purchasing/soybean-meal"
                    convertToUnit="THB/KG->USD/Sh.To"
                    forecastAccuracyObj={priceSummaryObj['soybeanMeal']['accuracyObj']}
                    forecastObjectList={priceSummaryObj['soybeanMeal']['forecastObjectList']}
                    customCurrentMonthForecastObj={priceSummaryObj['soybeanMeal']['customCurrentMonthForecastObj']}
                    highestLowestObj={priceSummaryObj['soybeanMeal']['highestLowestObj']}
                    currentMonthInventoryObj={currentMonthInventoryObj['soybeanMeal']}
                    previousMonthInventoryObj={previousMonthInventoryObj['soybeanMeal']}
                    highestLowestInventoryObj={highestLowestInventoryObj['soybeanMeal']}
                    exchangeRate={exchangeValueObj['exchangeRate']}
                    premium={exchangeValueObj['premium']}
                    handleSaveButtonClick={handleSaveButtonClick}
                    userLevel={userProfile['userLevel']}
                />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
                <PriceSummaryCard
                    title="Corn"
                    unit="Baht/KG"
                    purchasingUrl="/data-purchasing/corn"
                    forecastAccuracyObj={priceSummaryObj['corn']['accuracyObj']}
                    forecastObjectList={priceSummaryObj['corn']['forecastObjectList']}
                    highestLowestObj={priceSummaryObj['corn']['highestLowestObj']}
                    currentMonthInventoryObj={currentMonthInventoryObj['corn']}
                    previousMonthInventoryObj={previousMonthInventoryObj['corn']}
                    highestLowestInventoryObj={highestLowestInventoryObj['corn']}
                />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
                <ConvertPriceSummaryCard
                    title="Wheat"
                    unit="USD/To"
                    purchasingUrl="/data-purchasing/wheat"
                    convertToUnit="THB/KG->USD/To"
                    forecastAccuracyObj={priceSummaryObj['wheat']['accuracyObj']}
                    forecastObjectList={priceSummaryObj['wheat']['forecastObjectList']}
                    highestLowestObj={priceSummaryObj['wheat']['highestLowestObj']}
                    currentMonthInventoryObj={currentMonthInventoryObj['wheat']}
                    previousMonthInventoryObj={previousMonthInventoryObj['wheat']}
                    highestLowestInventoryObj={highestLowestInventoryObj['wheat']}
                    exchangeRate={exchangeValueObj['exchangeRate']}
                />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
                <PriceSummaryCard
                    title="Tapioca"
                    unit="Baht/KG"
                    purchasingUrl="/data-purchasing/tapioca"
                    forecastAccuracyObj={priceSummaryObj['tapioca']['accuracyObj']}
                    forecastObjectList={priceSummaryObj['tapioca']['forecastObjectList']}
                    highestLowestObj={priceSummaryObj['tapioca']['highestLowestObj']}
                    currentMonthInventoryObj={currentMonthInventoryObj['tapioca']}
                    previousMonthInventoryObj={previousMonthInventoryObj['tapioca']}
                    highestLowestInventoryObj={highestLowestInventoryObj['tapioca']}
                />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
                <PriceSummaryCard
                    title="L-lysine"
                    unit="Baht/KG"
                    purchasingUrl="/data-purchasing/l-lysine"
                    forecastAccuracyObj={priceSummaryObj['lysine']['accuracyObj']}
                    forecastObjectList={priceSummaryObj['lysine']['forecastObjectList']}
                    highestLowestObj={priceSummaryObj['lysine']['highestLowestObj']}
                    currentMonthInventoryObj={currentMonthInventoryObj['lysine']}
                    previousMonthInventoryObj={previousMonthInventoryObj['lysine']}
                    highestLowestInventoryObj={highestLowestInventoryObj['lysine']}
                />
            </Grid>
            {/* <Grid item xs={12} lg={6} xl={4}>
                <SoybeanMealSummaryCard
                    forecastAccuracyObj={forecastAccuracyObj['soybeanMeal']}
                    currentMonthInventoryObj={currentMonthInventoryObj['soybeanMeal']}
                    previousMonthInventoryObj={previousMonthInventoryObj['soybeanMeal']}
                    highestLowestInventoryObj={highestLowestInventoryObj['soybeanMeal']}
                />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
                <CornSummaryCard
                    forecastAccuracyObj={forecastAccuracyObj['corn']}
                    currentMonthInventoryObj={currentMonthInventoryObj['corn']}
                    previousMonthInventoryObj={previousMonthInventoryObj['corn']}
                    highestLowestInventoryObj={highestLowestInventoryObj['corn']}
                />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
                <WheatSummaryCard
                    forecastAccuracyObj={forecastAccuracyObj['wheat']}
                    currentMonthInventoryObj={currentMonthInventoryObj['wheat']}
                    previousMonthInventoryObj={previousMonthInventoryObj['wheat']}
                    highestLowestInventoryObj={highestLowestInventoryObj['wheat']}
                />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
                <TapiocaSummaryCard
                    forecastAccuracyObj={forecastAccuracyObj['tapioca']}
                    currentMonthInventoryObj={currentMonthInventoryObj['tapioca']}
                    previousMonthInventoryObj={previousMonthInventoryObj['tapioca']}
                    highestLowestInventoryObj={highestLowestInventoryObj['tapioca']}
                />
            </Grid>
            <Grid item xs={12} lg={6} xl={4}>
                <LysineSummaryCard
                    forecastAccuracyObj={forecastAccuracyObj['lysine']}
                    currentMonthInventoryObj={currentMonthInventoryObj['lysine']}
                    previousMonthInventoryObj={previousMonthInventoryObj['lysine']}
                    highestLowestInventoryObj={highestLowestInventoryObj['lysine']}
                />
            </Grid> */}
        </Grid>
    );
}

export default Home;