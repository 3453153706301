import React from 'react'
import { Breadcrumbs, Typography, Link } from '@material-ui/core'
import { useHistory } from 'react-router'

function CustomLink(props) {

    const { dataObject } = props
    const history = useHistory()

    return (
        <>
            {
                dataObject['isCurrent']
                    ?
                    <Typography color="textPrimary">{dataObject['label']}</Typography>
                    :
                    <Link color='primary' style={{ cursor: 'pointer' }} onClick={() => history.push(dataObject['url'])} >
                        {dataObject['label']}
                    </Link>
            }
        </>
    )
}

function BreadcrumdsLink(props) {

    const { dataBreadcrumdsList } = props


    return (
        <Breadcrumbs aria-label="breadcrumb">
            {
                dataBreadcrumdsList.map((dataObject) => <CustomLink dataObject={dataObject} key={Math.random()} />)
            }
        </Breadcrumbs>
    )
}

export default BreadcrumdsLink
