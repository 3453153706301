import { loadAction } from './index'
import Tapioca from "../../services/Tapioca";
const { default: ActionTypes } = require("./actionType");


export function getTapiocaList(offset, limit, month, year) {
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return Tapioca.getTapiocaList(offset, limit, month, year)
        .then((tapiocas) => {
            dispatch({
                type: ActionTypes.GET_TAPIOCA_LIST,
                payload: tapiocas.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        })
        .catch(() => {
            dispatch({
                type: ActionTypes.GET_TAPIOCA_LIST,
                payload: []
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}


