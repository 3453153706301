const { default: ActionTypes } = require("../actions/actionType");

const initialState = {};

const userDetailReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_USER_OBJECT: {
            if(action.payload === null){
                return { ...state }
            }else{
                return { ...action.payload }
            }
        }
        default: {
            return { ...state }
        }
    }
}

export default userDetailReducer;