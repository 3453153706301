import React from "react";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@material-ui/core";

function InputSelect(props) {

    const { label, selectItemList, selectedValue, handleSelectChange } = props

    return (
        <FormControl
            variant="outlined"
            size="small"
            fullWidth
        >
            <InputLabel id="single">{label}</InputLabel>
            <Select
                labelId="single"
                id="select-single"
                value={selectedValue}
                onChange={handleSelectChange}
                label={label}
            >
                {selectItemList.map((item) => (
                    <MenuItem dense key={item} value={item}>
                        {item}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default InputSelect;