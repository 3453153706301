import { combineReducers } from 'redux';
import userReducer from './user.reducer';
import usersReducer from './users.reducer';
import loadingReducer from './loading.reducer';
import snackBarReducer from './snackbar.reducer';
import userLevelReducer from './userLevel.reducer';
import userDetailReducer from './userDetail.reducer';

import dataActivityReducer from './dataActivity.reducer';
import inventoryPriceReducer from './inventoryPrice.reducer';
import materialEventReducer from './materialEvent.reducer';
import cornReducer from './corn.reducer';
import wheatReducer from './wheat.reducer';
import tapiocaReducer from './tapioca.reducer';
import lysineReducer from './lysine.reducer';
import soybeanMealReducer from './soybeanMeal.reducer';

import cornResultReducer from './cornResult.reducer';
import lysineResultReducer from './lysineResult.reducer';
import soybeanMealResultReducer from './soybeanMealResult.reducer';
import tapiocaResultReducer from './tapiocaResult.reducer';
import wheatResultReducer from './wheatResult.reducer';

import inventorySummaryReducer from './inventorySummary.reducer';

import priceSummaryReducer from './priceSummary.reducer';

import sideBarReducer from './sideBar.reducer';

const reducer = combineReducers({
    userReducer,
    usersReducer,
    loadingReducer,
    snackBarReducer,
    userLevelReducer,
    userDetailReducer,
    cornReducer,
    dataActivityReducer,
    inventoryPriceReducer,
    materialEventReducer,
    wheatReducer,
    tapiocaReducer,
    lysineReducer,
    soybeanMealReducer,
    cornResultReducer,
    lysineResultReducer,
    soybeanMealResultReducer,
    tapiocaResultReducer,
    wheatResultReducer,
    inventorySummaryReducer,
    sideBarReducer,
    priceSummaryReducer
});

export default reducer;