import React from "react";
import {
    ButtonGroup,
    Button,
} from "@material-ui/core";

const filterTimeList = [
    { label: '6 month', value: -6 },
    { label: '1 Year', value: -12 },
    { label: '2 Year', value: -24 },
    { label: 'ALL', value: 0 },
];

function FilterTimeButton(props) {

    const { selectedValue, handleButtonClick } = props

    return (
        <ButtonGroup size="small" disableElevation color="primary">
            { filterTimeList.map((filterTimeObj, index) => {
                return (<Button variant={ (selectedValue === filterTimeObj['value']) ? 'contained' : 'outlined' } key={index} onClick={() => { handleButtonClick(filterTimeObj['value']) }}>{filterTimeObj['label']}</Button>);
            }) }
        </ButtonGroup>
    )
}

export default FilterTimeButton
