import ApiProxy from '../utils/ApiProxy';

class Lysine {

    static async getLysineList(offset = 0, limit = 0, month, year) {
        return new Promise(async (resolve, reject) => {
            let lysineList = await ApiProxy.sendRequest('GET', `lysines?offset=${offset}&limit=${limit}&month=${!month ? '' : month}&year=${!year ? '' : year}`).catch((error) => {
                reject(error);
            });
            resolve(lysineList);
        });
    }

    static async insertLysineObject(data) {
        return new Promise(async (resolve, reject) => {
            let insertResult = await ApiProxy.sendRequest('POST', 'lysines', data).catch((error) => {
                reject(error);
            });
            resolve(insertResult);
        });
    }

    static async insertLysineList(data) {
        return new Promise(async (resolve, reject) => {
            let insertResult = await ApiProxy.sendRequest('POST', 'lysines/upload', data).catch((error) => {
                reject(error);
            });
            resolve(insertResult);
        });
    }

    static async updateLysineObject(lysineId, data) {
        return new Promise(async (resolve, reject) => {
            let updateResult = await ApiProxy.sendRequest('PUT', `lysines/${lysineId}`, data).catch((error) => {
                reject(error);
            });
            resolve(updateResult);
        });
    }

    static async deleteLysineList(data) {
        return new Promise(async (resolve, reject) => {
            let updateResult = await ApiProxy.sendRequest('DELETE', 'lysines', data).catch((error) => {
                reject(error);
            });
            resolve(updateResult);
        });
    }

    static async downloadDataMarketPrice(startDate, endDate) {
        return new Promise(async (resolve, reject) => {
            let marketPriceExcel = await ApiProxy.exportData('GET', `lysines/download?startDate=${startDate}&endDate=${endDate}`).catch((error) => { reject(error) });
            resolve(marketPriceExcel);
        });
    }

    static async getLysineResultList(year) {
        return new Promise(async (resolve, reject) => {
            let lysineResultList = await ApiProxy.sendRequest('GET', `lysines/result?year=${year ? year : ""}`).catch((error) => {
                reject(error);
            });
            resolve(lysineResultList);
        });
    }

    static async downloadDataResult(year) {
        return new Promise(async (resolve, reject) => {
            let resultExcel = await ApiProxy.exportData('GET', `lysines/download/result?year=${year}`).catch((error) => { reject(error) });
            resolve(resultExcel);
        });
    }

    static async getLysineResultObjectByDate(month, year) {
        return new Promise(async (resolve, reject) => {
            let lysineResultObject = await ApiProxy.sendRequest('GET', `lysines/result/search?month=${!month ? '' : month}&year=${!year ? '' : year}`).catch((error) => {
                reject(error);
            });
            resolve(lysineResultObject);
        });
    }

    static async getLysineResultSummaryList(startDate) {
        return new Promise(async (resolve, reject) => {
            let lysineResultSummaryList = await ApiProxy.sendRequest('GET', `lysines/summary?startDate=${startDate}`).catch((error) => {
                return reject(error);
            });
            return resolve(lysineResultSummaryList);
        });
    }

    static async getHighestLowestActualPriceObject() {
        return new Promise(async (resolve, reject) => {
            let lysineResultSummaryList = await ApiProxy.sendRequest('GET', `lysines/highest-lowest/actual-price`).catch((error) => {
                return reject(error);
            });
            return resolve(lysineResultSummaryList);
        });
    }

}

export default Lysine;