const { default: ActionTypes } = require("../actions/actionType");

const initialState = {
    isLoading: false
};

const loadingReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.LOADING: {
            return { ...state, isLoading: action.payload.isLoading };
        } default: {
            return state
        }
    }
}

export default loadingReducer;