import { makeStyles } from '@material-ui/core/styles';

const sideMenuWidth = 250;

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: 16,
        width: '100%',
        height: '100vh',
        display: 'flex',
        overflow: 'auto',
        overflowX: 'hidden',
        justifyContent: 'center',
        backgroundColor: 'rgb(238, 238, 238)',
        fontFamily: `"Niramit", "Helvetica", "Arial", sans-serif`,
    },
    container: {
        flexGrow: 1,
        minHeight: '100vh',
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3),
            width: `calc(100vw - ${sideMenuWidth}px)`,
        }
    },
    backToTop: {
        right: '2vw',
        bottom: '2vh',
        position: 'fixed'
    },
    appBar: {
        width: '100%',
        color: 'white',
        border: 'unset',
        boxShadow: 'unset',
        backgroundColor: theme.palette.primary.main,
    },
    appBarHome: {
        color: 'white',
        border: 'unset',
        boxShadow: 'unset',
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            marginLeft: sideMenuWidth,
            width: `calc(100% - ${sideMenuWidth}px)`,
        },
    },
    appBarHomeMiniMenu: {
        color: 'white',
        border: 'unset',
        boxShadow: 'unset',
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${(theme.spacing(7) + 1)}px)`,
        },
    },
    sideBarItemActive: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
            color: 'white',
            backgroundColor: theme.palette.primary.light,
        },
    },
    sideBarItemActiveIcon: {
        color: 'white',
    },
    childrenOfHomeLayout: {
        flexGrow: 1,
        padding: theme.spacing(1),
        maxWidth: '100%',
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            width: `calc(100% - ${sideMenuWidth}px)`,
        },
    },
    loginModal: {
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(11),
            boxShadow: 'none',
        },
        [theme.breakpoints.up('xs')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(11),
        }
    },
    backgroundLogin: {
        [theme.breakpoints.down('xs')]: {
            backgroundColor: '#fff',
            backgroundImage: 'none'
        },
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage: 'url("/assets/images/login_background.svg")',
        backgroundPosition: 'bottom'
    },
    menuButton: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        '&:focus': {
            outline: 'unset'
        }
    },
    toolbar: theme.mixins.toolbar,
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: sideMenuWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
        },
    },
    drawerPaper: {
        width: sideMenuWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    custompaper: {
        width: theme.spacing(16),
        height: theme.spacing(16),
    },
    customTextHead: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 30,
        lineHeight: 1,
    },
    customSubText: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 21,
        lineHeight: 1,
        color: "rgba(33, 33, 33, 0.87)"
    },
    link: {
        color: "#4285F6"
    },
    advanceSearchBtn: {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        backgroundColor: 'white',
        boxShadow: 'unset',
        "&:hover": {
            border: '1px solid rgba(0, 0, 0, 1)',
            backgroundColor: 'white',
            boxShadow: 'unset',
        }
    },
    deleteBtn: {
        backgroundColor: theme.palette.red[500],
        color: theme.palette.getContrastText(theme.palette.red[500]),
        '&:hover': {
            backgroundColor: theme.palette.red[700],
        }
    },
    tableContainer: {
        minWidth: '750'
    },
    RightDrawerTableContainer: {
        width: `100%`,
        [theme.breakpoints.down('sm')]: {
            width: `calc(100vw + 200px)`,
        }
    },
    mainPaper: {
        maxWidth: '95vw',
        [theme.breakpoints.up('md')]: {
            maxWidth: `calc(100vw - 350px)`,
        }
    },
    pageNotFoundBtn: {
        padding: '12px',
        background: 'red',
        color: 'white',
        borderRadius: '5px',
        backgroundColor: theme.palette.mayaBlue[500],
    },
    headerSearchTitle: {
        paddingBottom: '12px',
        [theme.breakpoints.up('md')]: {
            paddingBottom: '0px'
        }
    }
}));

export default useStyles;
