import { loadAction } from './index'
import MaterialEvent from "../../services/MaterialEvent";
const { default: ActionTypes } = require("./actionType");


export function getMaterialEventList(offset, limit, period, material) {
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return MaterialEvent.getMaterialEventList(offset, limit, period, material)
        .then((materialEvent) => {
            dispatch({
                type: ActionTypes.GET_MATERIAL_EVENT_LIST,
                payload: materialEvent.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        })
        .catch(() => {
            dispatch({
                type: ActionTypes.GET_MATERIAL_EVENT_LIST,
                payload: []
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}


