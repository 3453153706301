import { loadAction } from './index'
import InventoryPrice from "../../services/InventoryPrice";
const { default: ActionTypes } = require("./actionType");


export function getInventoryPriceList(offset, limit, month, year, inventoryType) {
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return InventoryPrice.getInventoryPriceList(offset, limit, month, year, inventoryType)
        .then((inventoryPrice) => {
            dispatch({
                type: ActionTypes.GET_INVENTORY_PRICE_LIST,
                payload: inventoryPrice.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        })
        .catch(() => {
            dispatch({
                type: ActionTypes.GET_INVENTORY_PRICE_LIST,
                payload: []
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}


