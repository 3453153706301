import { Checkbox, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useStyles from '../../assets/styles';
import DateObject from '../../utils/DateObject';

function shortMonthComparator(a, b, orderBy) {
    const months = DateObject.SHORT_MONTH_EN_LIST_UPPER_CASE
        return months.indexOf(a[orderBy]) - months.indexOf(b[orderBy]);
}

function descendingComparator(a, b, orderBy) {
    if (!b[orderBy]) { return -1 }
    if (!a[orderBy]) { return 1 }
    if (b[orderBy] < a[orderBy]) { return -1 }
    if (b[orderBy] > a[orderBy]) { return 1 }
    return 0;
}

function getComparator(order, orderBy) {
    if(orderBy === 'contract'){
        return order === 'asc'
        ? (a, b) => shortMonthComparator(a, b, orderBy)
        : (a, b) => -shortMonthComparator(a, b, orderBy);
    }else{
        return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
    }
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCellList, isCheckBox } = props;
    const createSortHandler = (property) => (event) => { onRequestSort(event, property) };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox" align={isCheckBox ? 'right' : 'center'}>
                    {isCheckBox ? <Checkbox
                        onChange={onSelectAllClick}
                        checked={rowCount > 0 && numSelected === rowCount}
                        inputProps={{ 'aria-label': 'select all desserts' }}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                    /> : <h6>&nbsp;&nbsp;#&nbsp;&nbsp;</h6>}
                </TableCell>
                {headCellList.map((headCell, index) => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={(orderBy === headCell.id) || (orderBy === headCell.sort) ? order : false}
                        align={headCell.numeric && !headCell.isUrgencyStatus ? 'right' : headCell.headAlign ? headCell.headAlign : 'center'}
                        style={{ borderLeftWidth: 2, borderColor: 'white', borderStyle: 'solid', backgroundColor: headCell.cellColor ? headCell.cellColor : '#D2EEF7'}}
                    >
                        <TableSortLabel
                            className={headCell.className ? headCell.className : ''}
                            active={(orderBy === headCell.id) || (orderBy === headCell.sort)}
                            onClick={createSortHandler(headCell.sort || headCell.id)}
                            direction={(orderBy === headCell.id) || (orderBy === headCell.sort) ? order : 'asc'}
                        >
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

export default function EnhancedTable(props) {
    const { searchToggle = 0, headCellList = [], tableDataList = [], isCheckBox = false, isEnableClick = true, isRightDrawer = false, rowSelectedList = [], handleTableClick = () => { }, allRows = null, topTitleHead = "", isSortAble = true,customPaper={}, defaultOrder = "asc", defaultOrderBy = "" } = props;
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState(defaultOrder);
    const [orderBy, setOrderBy] = useState(defaultOrderBy);
    const [rowsPerPage, setRowsPerPage] = useState(!allRows ? 10 : allRows);
    const [selectedItem, setSelectedItem] = useState(rowSelectedList);

    const toggleSorting = (event, property, getOrder) => {
        if(isSortAble){
            let isAsc
            if(!getOrder){
                isAsc = orderBy === property && order === 'asc';
            }else{
                isAsc = (getOrder === 'desc') ? true : false
            }
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }else{
            return null
        }
    };

    useEffect(() => {
        if(!rowSelectedList.length){
            if((order !== defaultOrder) || (orderBy !== defaultOrderBy)){
                toggleSorting(null, defaultOrderBy, defaultOrder)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, rowSelectedList])

    useEffect(() => {
        if (rowSelectedList.length !== selectedItem.length) {
            setSelectedItem(rowSelectedList)
        }
    }, [rowSelectedList, selectedItem])

    useEffect(() => {
        setPage(0)
    }, [searchToggle])

    const handleSelectAllClick = (event) => {
        let newSelectedList = [];
        if (event.target.checked) {
            newSelectedList = tableDataList.map((n) => n);
        }
        setSelectedItem(newSelectedList);
        props.handleRowSeletion(newSelectedList)
    };

    const handleSelect = (event, dataRow) => {
        let newSelectedList = [];
        const selectedIndex = selectedItem.indexOf(dataRow);
        if (selectedIndex === -1) {
            newSelectedList = newSelectedList.concat(selectedItem, dataRow);
        } else if (selectedIndex === 0) {
            newSelectedList = newSelectedList.concat(selectedItem.slice(1));
        } else if (selectedIndex === selectedItem.length - 1) {
            newSelectedList = newSelectedList.concat(selectedItem.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelectedList = newSelectedList.concat(selectedItem.slice(0, selectedIndex), selectedItem.slice(selectedIndex + 1));
        }
        setSelectedItem(newSelectedList);
        props.handleRowSeletion(newSelectedList)
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const checkIsSelectedItem = (rowDataObj) => selectedItem.indexOf(rowDataObj) !== -1;

    return (
        <Paper className="w-full mb-4" style={{ overflowX: 'auto',...customPaper }}>
            <TableContainer className={!isRightDrawer ? classes.TableContainer : classes.RightDrawerTableContainer}>
                <Grid container justifyContent="space-between">
                    <Grid item>
                        {!topTitleHead ? null : <Typography variant="h5" className="pt-2 pb-2 pl-4" style={{ fontWeight: 'bold' }}>{topTitleHead}</Typography>}
                    </Grid>
                </Grid>
                <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
                    <EnhancedTableHead order={order} orderBy={orderBy} numSelected={selectedItem.length} headCellList={headCellList} rowCount={tableDataList.length} classes={classes}
                        onRequestSort={toggleSorting}
                        onSelectAllClick={handleSelectAllClick}
                        isCheckBox={isCheckBox}
                    />
                    {!tableDataList || tableDataList.length < 1 ?
                        <TableBody>
                            <TableRow hover role="checkbox">
                                <TableCell colSpan={!headCellList ? 1 : headCellList.length + 1} component="th" scope="row" className={isEnableClick ? "cursor-pointer" : "cursor-default"} align={'center'}>
                                    No information found
                                </TableCell>
                            </TableRow>
                        </TableBody>
                        :
                        <TableBody>
                            {stableSort(tableDataList, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = checkIsSelectedItem(row);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index} selected={isItemSelected} aria-checked={isItemSelected} >
                                            <TableCell padding="checkbox" onClick={(event) => isCheckBox ? handleSelect(event, row) : () => { }} align="center">
                                                {isCheckBox ? <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} /> : (index + 1) + (page * rowsPerPage)}
                                            </TableCell>
                                            {
                                                headCellList.map((headCell, index) => {
                                                    let content = '';
                                                    if( !row[headCell.id] && headCell.numeric && !headCell.isUrgencyStatus ) {
                                                        content = '-';
                                                    } else if (!row[headCell.id] && !headCell.numeric) {
                                                        content = '-';
                                                    }else if (headCell.numeric && headCell.isDecimal && !headCell.isDivideTen){
                                                        content = Number(row[headCell.id]) ? Number(parseFloat(row[headCell.id]).toFixed(headCell.decimalNum)) : Number(row[headCell.id])
                                                    }else if (headCell.numeric && headCell.isDecimal && headCell.isDivideTen){
                                                        content = Number(row[headCell.id]) ? Number(parseFloat((row[headCell.id]) / 10).toFixed(headCell.decimalNum)) : Number(row[headCell.id])
                                                    }else if ( !headCell.isDate && !headCell.percentage ) {
                                                        if( isNaN(row[headCell.id]) && typeof row[headCell.id] !== 'object' ) {
                                                            content = row[headCell.id].replace(/<br \/>/g, '\n');
                                                        } else {
                                                            content = row[headCell.id];
                                                        }
                                                    } else if ( !headCell.isDate && headCell.percentage ) {
                                                        content = row[headCell.id]+"%";
                                                    } else if ( headCell.isDate && !headCell.percentage ) {
                                                        let rawDate = row[headCell.id];
                                                        if( headCell.rawDateFormat === 'YYYYMMDD' ) {
                                                            rawDate = `${rawDate.substring(0, 4)}-${rawDate.substring(4, 6)}-${rawDate.substring(6, 8)}`;
                                                        } else if( headCell.rawDateFormat === 'YYYYMM' ) {
                                                            rawDate = `${rawDate.substring(0, 4)}-${rawDate.substring(4, 6)}-01`;
                                                        }
                                                        
                                                        content = DateObject.convertDateTime(rawDate, headCell.dateFormat);
                                                    } 
                                                    
                                                    return (
                                                        <TableCell onClick={headCell.isButton ? () => null : (e) => handleTableClick(row)} component="th" id={labelId} scope="row" className="cursor-pointer break-words" key={index} align={headCell.numeric && !headCell.isUrgencyStatus ? 'right' : (headCell.rowAlign ? headCell.rowAlign : 'left') } style={{ borderLeftWidth: 1, borderColor: '#e5e7eb', borderStyle: 'solid', whiteSpace: "pre-line", backgroundColor: headCell.cellColor ? headCell.cellColor : "" }} >
                                                            { content }
                                                        </TableCell>
                                                    )
                                                
                                                })
                                            }
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    }
                </Table>
            </TableContainer>
            <TablePagination page={page} component="div" rowsPerPage={rowsPerPage} count={tableDataList.length} rowsPerPageOptions={[5, 10, 15, 20, 25, 30, 50, 100]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper >
    );
}