import { Typography, Grid, Box } from '@material-ui/core';
import DateObject from '../../../utils/DateObject';

function SoybeanMealInput(props) {

    const { lastUpdate } = props
    return (
        <Grid item xs={12}>
            <Grid item className='mb-5'>
                <Typography variant="h3" style={{ fontWeight: 'bold' }}>Last Update: {lastUpdate ? DateObject.convertDateTime(lastUpdate, "YYYY-MM-DD") : `-`}</Typography>
            </Grid>
            <Box component={Grid} item xs={4} display={{ xs: "none", md: "block" }} />
        </Grid>
    )
}

export default SoybeanMealInput