import { Box, Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Paper, TextField, Typography } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BreadcrumdsLink from '../components/Options/BreadcrumdsLink';
import UserService from '../services/User';
import { loadAction, snackbarAction, userAction } from '../store/actions';
import Validator from '../utils/Validator';

const UserForm = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const isCreate = props.match.params.id === 'create' ? true : false;
    const userObj = useSelector(state => state.userDetailReducer);
    const userLevelList = useSelector(state => state.userLevelReducer);
    const [email, setEmail] = useState("");
    const [lastName, setLastName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [userLevelId, setUserLevelId] = useState("");
    const [isInvalid, setIsInvalid] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const [isUpdateDefault, setIsUpdateDefault] = useState(true);
    const [dataBreadcrumdsList, setDataBreadcrumdsList] = useState([]);
    const [password, setPassword] = useState(!isCreate ? "••••••••" : "");
    const [confirmPassword, setConfirmPassword] = useState(!isCreate ? "••••••••" : "");
    const { default: ActionTypes } = require("../store/actions/actionType");

    const getUserData = useCallback((userId) => {
        dispatch(loadAction.displayLoadingOverlay())
        dispatch(userAction.getUserObject(userId));
        dispatch(userAction.getUserLevelList());
    }, [dispatch]);

    useEffect(() => {
        if (!props.match.params.id) {
            history.push("/user");
            return;
        }
        getUserData(props.match.params.id)
    }, [getUserData, props.match.params.id, history])

    useEffect(() => {
        if (isUpdateDefault && !isCreate) {
            if (userObj.userId === null) {
                dispatch({ type: ActionTypes.GET_USER_OBJECT, payload: {} });
                dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: "An error occurred, please try again" }))
                history.push("/user");
                return;
            }
            setEmail(userObj.email || email)
            setLastName(userObj.lastName || lastName)
            setFirstName(userObj.firstName || firstName)
            setUserLevelId(userObj.userLevelId || userLevelId)
            setMobileNumber(userObj.mobileNumber || mobileNumber)
            setDataBreadcrumdsList([
                { label: 'User Management', url: '/user', isCurrent: false },
                { label: (userObj.firstName || firstName) + " " + (userObj.lastName || lastName), url: '', isCurrent: true },
            ])
        } else {
            setDataBreadcrumdsList([
                { label: 'User Management', url: '/user', isCurrent: false },
                { label: 'Create User', url: '', isCurrent: true },
            ])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, userObj, ActionTypes.GET_USER_OBJECT, email, firstName, history, isCreate, isUpdateDefault, lastName, mobileNumber])

    const submitForm = () => {
        setIsUpdateDefault(false)
        if (!firstName || !lastName || !email || !mobileNumber || !userLevelId || !password || !confirmPassword ) {
            setIsInvalid(true)
        } else if (!Validator.validateData(email, 'email').isValid) {
            dispatch(
                snackbarAction.openSnackbar({ options: { variant: "warning" }, message: "Invalid E-mail" })
            )
            setIsInvalid(true)
            setIsInvalidEmail(true)
        } else if (!Validator.validateData(mobileNumber, 'mobileNumber').isValid) {
            dispatch(
                snackbarAction.openSnackbar({ options: { variant: "warning" }, message: "Invalid phone number" })
            )
            setIsInvalid(true)
            setMobileNumber("")
        } else if (isCreate && (password !== confirmPassword)) {
            dispatch(
                snackbarAction.openSnackbar({ options: { variant: "warning" }, message: "Confirm password doesn't match" })
            )
            setIsInvalid(true)
            setConfirmPassword("")
        } else if (isCreate && (!Validator.validateData(password, 'password').isValid)) {
            dispatch(
                snackbarAction.openSnackbar({ options: { variant: "warning" }, message: "Use at least 8 characters, including uppercases, lowercases, numbers and symbols" })
            )
            setIsInvalid(true)
            setPassword("")
            setConfirmPassword("")
        } else {
            dispatch(loadAction.displayLoadingOverlay())
            if (!isCreate) {
                const updateData = {
                    email: email,
                    lastName: lastName,
                    firstName: firstName,
                    userLevelId: userLevelId,
                    mobileNumber: mobileNumber,
                }
                updateUserObj(updateData)
            } else {
                const insertData = {
                    email: email,
                    password: password,
                    lastName: lastName,
                    firstName: firstName,
                    userLevelId: userLevelId,
                    mobileNumber: mobileNumber,
                }
                insertUserObj(insertData)
            }
        }
    }

    const handleChangeEmail = (emailValue) => {
        setEmail(emailValue)
        setIsInvalidEmail(false)
    }

    const insertUserObj = async (data) => {
        UserService.insertUserObject(data).then((response) => {
            dispatch(snackbarAction.openSnackbar({ options: { variant: "success" }, message: "User has been successfully created" }))
            dispatch(loadAction.hideLoadingOverlay());
            history.push("/user");
        }).catch((error) => {
            let newError = { ...error }
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            dispatch(loadAction.hideLoadingOverlay());
        });
    }

    const updateUserObj = async (data) => {
        UserService.updateUserObject(props.match.params.id, data).then((response) => {
            dispatch(snackbarAction.openSnackbar({ options: { variant: "success" }, message: "Profile has been updated" }))
            dispatch(loadAction.hideLoadingOverlay());
            history.push("/user");
        }).catch((error) => {
            let newError = { ...error };
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            dispatch(loadAction.hideLoadingOverlay());
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} className="self-center">
                <Typography variant="h3" style={{ fontWeight: 'bold' }}>User Management</Typography>
            </Grid>
            <Grid item xs={12}>
                <BreadcrumdsLink dataBreadcrumdsList={dataBreadcrumdsList} />
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={0} style={{ width: '100%', padding: '48px' }}>
                    <form noValidate autoComplete="off" onSubmit={(e) => { }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField label="ชื่อ" margin="dense" variant="outlined" color="secondary" fullWidth error={isInvalid && !firstName} value={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField label="นามสกุล" margin="dense" variant="outlined" color="secondary" fullWidth error={isInvalid && !lastName} value={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField type="email" label="E-mail" margin="dense" variant="outlined" color="secondary" fullWidth error={isInvalidEmail || (isInvalid && !email)} value={email} onChange={(e) => { handleChangeEmail(e.target.value) }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <TextField label="หมายเลขโทรศัพท์" margin="dense" variant="outlined" color="secondary" fullWidth error={isInvalid && !mobileNumber} value={mobileNumber} onChange={(e) => { setMobileNumber(e.target.value.replace(/[^\d]/g, "")) }} />
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                                <Grid container alignItems='center' justifyContent='space-between'>
                                    <Grid item xs={10} sm={10} md={10}>
                                        <TextField label="ระดับผู้ใช้งาน" select  defaultValue={""} margin="dense" variant="outlined" color="secondary" fullWidth error={isInvalid && !userLevelId} value={userLevelId} onChange={(e) => { setUserLevelId(e.target.value) }}>
                                            {userLevelList.map((userLevelObj, index) => {
                                                if (userLevelObj.userLevelId !== 0) {
                                                    return (
                                                        <MenuItem key={index} value={userLevelObj.userLevelId}>{userLevelObj.userLevel}</MenuItem>
                                                    )
                                                }
                                                return null
                                            })}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box component={Grid} item xs={4} display={{ xs: "none", md: "block" }} />

                            <Grid item xs={12} sm={6} md={4} className="self-center text-left">
                                <FormControl error={isInvalid && !password} variant="outlined" color="secondary" disabled={!isCreate} fullWidth margin="dense" style={{ borderRadius: '4px' }}>
                                    <InputLabel htmlFor="passwordField">Password</InputLabel>
                                    <OutlinedInput id="passwordField" type={showPassword ? 'text' : 'password'} labelWidth={65} value={password} onChange={(e) => setPassword(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end" onClick={() => { setShowPassword(!showPassword) }}>
                                                <IconButton aria-label="toggle password visibility" edge="end">
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <FormControl error={isInvalid && !confirmPassword} variant="outlined" color="secondary" disabled={!isCreate} fullWidth margin="dense" style={{ borderRadius: '4px' }}>
                                    <InputLabel htmlFor="confirmPasswordField">Confirm Password</InputLabel>
                                    <OutlinedInput id="confirmPasswordField" type={showPassword ? 'text' : 'password'} labelWidth={120} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end" onClick={() => { setShowPassword(!showPassword) }}>
                                                <IconButton aria-label="toggle password visibility" edge="end">
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Box component={Grid} item xs={4} display={{ xs: "none", md: "block" }} />
                            <Grid item xs={12} className="text-right pt-8">
                                <Button variant="contained" color="primary" onClick={() => submitForm()}>
                                    {isCreate ? "INSERT" : "SAVE"}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default UserForm;