import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AssessmentIcon from '@material-ui/icons/Assessment';
import StorageTwoToneIcon from '@material-ui/icons/StorageTwoTone';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import HomeIcon from '@material-ui/icons/Home';

const dottedSmallIcon = () => {
    return <FiberManualRecordIcon fontSize="small"/>
}

const Menu = {
    home: {
        name: "Home",
        key: 'home',
        icon: HomeIcon,
        link: "/home"
    },
    dataPurchasing: {
        name: "Data Purchasing",
        key: 'dataPurchasing',
        icon: AssessmentIcon,
        link: "/data-purchasing",
        subMenuList: [
            {
                name: "Soybean meal",
                key: "soybeanMeal",
                icon: dottedSmallIcon,
                link: "/data-purchasing/soybean-meal",
            },
            {
                name: "Wheat",
                key: "wheat",
                icon: dottedSmallIcon,
                link: "/data-purchasing/wheat",
            },
            {
                name: "Corn",
                key: "corn",
                icon: dottedSmallIcon,
                link: "/data-purchasing/corn",
            },
            
            {
                name: "Tapioca",
                key: "tapioca",
                icon: dottedSmallIcon,
                link: "/data-purchasing/tapioca",
            },
            {
                name: "L-lysine",
                key: "l-lysine",
                icon: dottedSmallIcon,
                link: "/data-purchasing/l-lysine",
            },
            {
                name: "Inventory Summary",
                key: "inventorySummary",
                icon: dottedSmallIcon,
                link: "/data-purchasing/inventory-summary",
            },
            {
                name: "Year Comparison",
                key: "yearComparison",
                icon: dottedSmallIcon,
                link: "/data-purchasing/year-comparison",
            },
        ]
    },
    dataPurchasingForCustomer: {
        name: "Data Purchasing",
        key: 'dataPurchasing',
        icon: AssessmentIcon,
        link: "/data-purchasing",
        subMenuList: [
            {
                name: "Corn",
                key: "corn",
                icon: dottedSmallIcon,
                link: "/data-purchasing/corn",
            }
        ]
    },
    dataManagementForCustomer: {
        name: "Data Management",
        key: 'dataManagement',
        icon: StorageTwoToneIcon,
        link: "/data-management",
        subMenuList: [
            {
                name: "Corn",
                key: "corn",
                icon: dottedSmallIcon,
                link: "/data-management/corn",
            },
        ]
    },
    dataManagement: {
        name: "Data Management",
        key: 'dataManagement',
        icon: StorageTwoToneIcon,
        link: "/data-management",
        subMenuList: [
            {
                name: "Soybean meal",
                key: "soybeanMeal",
                icon: dottedSmallIcon,
                link: "/data-management/soybean-meal",
            },
            {
                name: "Wheat",
                key: "wheat",
                icon: dottedSmallIcon,
                link: "/data-management/wheat",
            },
            {
                name: "Corn",
                key: "corn",
                icon: dottedSmallIcon,
                link: "/data-management/corn",
            },
            {
                name: "Tapioca",
                key: "tapioca",
                icon: dottedSmallIcon,
                link: "/data-management/tapioca",
            },
            {
                name: "L-lysine",
                key: "l-lysine",
                icon: dottedSmallIcon,
                link: "/data-management/l-lysine",
            },
        ]
    },
    userManagement: {
        name: "User Management",
        key: 'management',
        icon: SupervisorAccountIcon,
        link: "/user"
    }
};

const {
    home,
    dataPurchasing,
    userManagement,
    dataManagement,
    dataManagementForCustomer,
    dataPurchasingForCustomer
} = Menu;

const privateMenu = {
    reporter: [ home, dataPurchasing ],
    editor: [ home, dataPurchasing, dataManagement ],
    admin: [ home, dataPurchasing, dataManagement, userManagement ],
    guest: [],
    dev: [ home, dataPurchasing, dataManagement, userManagement ],
    TFG_Viewer: [ home, dataPurchasingForCustomer ],
    TFG_Editor: [ home, dataPurchasingForCustomer, dataManagementForCustomer ],
    TFG_Admin: [ home, dataPurchasingForCustomer, dataManagementForCustomer, userManagement ],
};

export default privateMenu