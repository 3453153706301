const { default: ActionTypes } = require("../actions/actionType");

const initialState = [ ];

const inventoryPriceReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.GET_INVENTORY_PRICE_LIST: {
            if(action.payload === null){
                return [ ...state ]
            }else{
                return [ ...action.payload ]
            }
        }
        default: {
            return [ ...state ]
        }
    }

}

export default inventoryPriceReducer;