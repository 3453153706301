import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Grid,
  Modal,
  Paper,
  TextField,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { loadAction, snackbarAction } from "../../../store/actions";
import InventoryPriceService from "../../../services/InventoryPrice";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DateObject from "../../../utils/DateObject";

export function ModalUpdateInventoryPriceForm(props) {
  const {
    dataInfo,
    isOpen,
    handleOnCancle,
    getNewDataList = () => {},
    dataList = [],
  } = props;
  const separatorList = {
    regex: /^(0|[1-9][0-9]{0,2})(\.[0-9]{0,2})?$/,
    incompleteDecimal: /^(0|[1-9][0-9]{0,2})\.$/,
  };
  const dispatch = useDispatch();
  const [inventoryPriceId, setInventoryPriceId] = useState(dataInfo["id"]);
  const [date, setDate] = useState(dataInfo["inventoryPriceDate"]);
  const [targetPrice, setTargetPrice] = useState(dataInfo["targetPrice"]);
  const [inventoryPrice, setInventoryPrice] = useState(
    dataInfo["inventoryPrice"]
  );
  const [week, setWeek] = useState(dataInfo["week"]);

  useEffect(() => {
    setInventoryPriceId(dataInfo["id"]);
    setDate(dataInfo["inventoryPriceDate"]);
    setTargetPrice(dataInfo["targetPrice"]);
    setInventoryPrice(dataInfo["inventoryPrice"]);
    setWeek(dataInfo["week"]);
  }, [dataInfo]);

  const checkIsDisableSaveBtn = () => {
    return targetPrice < 0 || inventoryPrice < 0;
  };

  const onClickSubmitForm = async () => {
    const isExisted = dataList.find(
      (item) =>
        item.inventoryType === dataInfo["inventoryType"] &&
        item.inventoryPriceDate ===
          DateObject.convertDateTime(date, "YYYY-MM-DD") &&
        item.id !== inventoryPriceId
    );
    if (isExisted) {
      dispatch(
        snackbarAction.openSnackbar({
          options: { variant: "error" },
          message: "Cannot save change due to duplicated date",
        })
      );
      dispatch(loadAction.hideLoadingOverlay());
    } else {
      handleOnCancle(false);
      let data = {
        inventoryPriceDate: date,
        targetPrice: targetPrice,
        inventoryPrice: inventoryPrice,
        week: week,
      };
      dispatch(loadAction.displayLoadingOverlay());
      InventoryPriceService.updateInventoryPriceObject(inventoryPriceId, data)
        .then((response) => {
          dispatch(
            snackbarAction.openSnackbar({
              options: { variant: "success" },
              message: "Inventory data has been updated.",
            })
          );
          getNewDataList();
          dispatch(loadAction.hideLoadingOverlay());
        })
        .catch((error) => {
          let newError = { ...error };
          dispatch(
            snackbarAction.openSnackbar({
              options: { variant: "error" },
              message: !newError.response
                ? "Sorry , there was error."
                : newError.response.data.message,
            })
          );
          dispatch(loadAction.hideLoadingOverlay());
        });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={() => {
        handleOnCancle(false);
      }}
      aria-labelledby="modal-update-form"
      className="flex justify-center items-center"
    >
      <Paper
        elevation={0}
        className="p-10"
        style={{ maxWidth: "90%", width: 620, outline: "none" }}
      >
        <Grid container>
          <Grid item xs={12} className="pb-4">
            <Typography variant="h3" style={{ fontWeight: "bold" }}>
              Inventory Price form
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <Grid item xs={12}>
              <Typography variant="body1" className="self-center pr-5">
                Inventory Price Date
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  label="Date"
                  format="dd/MM/yyyy"
                  name="inventoryPriceDate"
                  onChange={(date) => {
                    setDate(date);
                    setWeek(`week` + DateObject.findWeekInMonth(date));
                  }}
                  value={date}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className="self-center pr-2 mb-2 mt-2"
              >
                Week
              </Typography>
              <TextField
                label="Week"
                size="small"
                variant="outlined"
                disabled
                value={week}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className="self-center pr-2 mb-2 mt-2"
              >
                Target Price
              </Typography>
              <TextField
                label="Target Price"
                placeholder={
                  dataInfo["inventoryType"] === "wheat" ? "Max 999" : "Max 99"
                }
                variant="outlined"
                size="small"
                autoComplete="off"
                inputProps={{ step: "0.1" }}
                value={targetPrice}
                onChange={(e) => {
                  const inputNumber = e.target.value;
                  if (separatorList.regex.test(inputNumber)) {
                    if (
                      inputNumber >= 0 &&
                      inputNumber <= 99 &&
                      dataInfo["inventoryType"] !== "wheat"
                    ) {
                      setTargetPrice(inputNumber);
                    } else if (
                      inputNumber >= 0 &&
                      inputNumber <= 999 &&
                      dataInfo["inventoryType"] === "wheat"
                    ) {
                      setTargetPrice(inputNumber);
                    }
                  } else if (
                    separatorList.incompleteDecimal.test(inputNumber)
                  ) {
                    setTargetPrice(inputNumber);
                  } else if (e.target.value === "") {
                    setTargetPrice("");
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                className="self-center pr-2 mb-2 mt-2"
              >
                Inventory Price
              </Typography>
              <TextField
                label="Inventory Price"
                placeholder={
                  dataInfo["inventoryType"] === "wheat" ? "Max 999" : "Max 99"
                }
                variant="outlined"
                size="small"
                autoComplete="off"
                inputProps={{ step: "0.1" }}
                value={inventoryPrice}
                onChange={(e) => {
                  const inputNumber = e.target.value;
                  if (separatorList.regex.test(inputNumber)) {
                    if (
                      inputNumber >= 0 &&
                      inputNumber <= 99 &&
                      dataInfo["inventoryType"] !== "wheat"
                    ) {
                      setInventoryPrice(inputNumber);
                    } else if (
                      inputNumber >= 0 &&
                      inputNumber <= 999 &&
                      dataInfo["inventoryType"] === "wheat"
                    ) {
                      setInventoryPrice(inputNumber);
                    }
                  } else if (
                    separatorList.incompleteDecimal.test(inputNumber)
                  ) {
                    setInventoryPrice(inputNumber);
                  } else if (e.target.value === "") {
                    setInventoryPrice("");
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} className="text-right pt-4">
            <Button
              className="mr-2"
              onClick={() => {
                handleOnCancle(false);
              }}
            >
              CANCEL
            </Button>
            <Button
              className="ml-2"
              variant="contained"
              color="primary"
              onClick={() => onClickSubmitForm()}
              disabled={checkIsDisableSaveBtn()}
            >
              SAVE
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  );
}
