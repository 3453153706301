const { default: ActionTypes } = require("./actionType");

export function displayLoadingOverlay() {
    return (dispatch) => {
        let payload = {
            isLoading: true
        };
        dispatch({
            type: ActionTypes.LOADING,
            payload
        });
    }
}

export function hideLoadingOverlay() {
    return (dispatch) => {
        let payload = {
            isLoading: false
        };
        dispatch({
            type: ActionTypes.LOADING,
            payload
        });
    }
}