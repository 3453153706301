import ApiProxy from '../utils/ApiProxy';

class MaterialEvent {

    static async getMaterialEventList(offset = 0, limit = 0, period, material) {
        return new Promise(async (resolve, reject) => {
            let materialEventList = await ApiProxy.sendRequest('GET', `material-events?offset=${offset}&limit=${limit}&period=${!period ? '' : period}&material=${material}`).catch((error) => {
                reject(error);
            });
            resolve(materialEventList);
        });
    }

    static async insertMaterialEventObject(data) {
        return new Promise(async (resolve, reject) => {
            let insertResult = await ApiProxy.sendRequest('POST', 'material-events', data).catch((error) => {
                reject(error);
            });
            resolve(insertResult);
        });
    }

    static async insertMaterialEventList(data) {
        return new Promise(async (resolve, reject) => {
            let insertResult = await ApiProxy.sendRequest('POST', 'material-events/upload', data).catch((error) => {
                reject(error);
            });
            resolve(insertResult);
        });
    }

    static async updateMaterialEventObject(materialEventId, data) {
        return new Promise(async (resolve, reject) => {
            let updateResult = await ApiProxy.sendRequest('PUT', `material-events/${materialEventId}`, data).catch((error) => {
                reject(error);
            });
            resolve(updateResult);
        });
    }

    static async deleteMaterialEventList(data, material) {
        return new Promise(async (resolve, reject) => {
            let updateResult = await ApiProxy.sendRequest('DELETE', `material-events?material=${material}`, data).catch((error) => {
                reject(error);
            });
            resolve(updateResult);
        });
    }

    static async downloadDataEvent(material, startDate, endDate) {
        return new Promise(async (resolve, reject) => {
            let eventPriceExcel = await ApiProxy.exportData('GET', `material-events/download?material=${material}&startDate=${startDate}&endDate=${endDate}`).catch((error) => { reject(error) });
            resolve(eventPriceExcel);
        });
    }
}

export default MaterialEvent;