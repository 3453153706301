import { loadAction } from './index'
import DataActivity from "../../services/DataActivity";
const { default: ActionTypes } = require("./actionType");


export function getDataActivityList(offset, limit, month, year, inputType) {
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return DataActivity.getDataActivityList(offset, limit, month, year, inputType)
        .then((dataActivitys) => {
            dispatch({
                type: ActionTypes.GET_DATA_ACTIVITY_LIST,
                payload: dataActivitys.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        })
        .catch(() => {
            dispatch({
                type: ActionTypes.GET_DATA_ACTIVITY_LIST,
                payload: []
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}


