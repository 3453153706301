const cdf = require('@stdlib/stats-base-dists-normal-cdf');

class Calulate {

    static async calPriceSuggestion(inputPrice, forecastValue, forecastRmse) {
        let suggestPredict = cdf(inputPrice, forecastValue, forecastRmse);
        let percentage = suggestPredict * 100

        return percentage.toFixed(2)
    }

    static findAverageFromObjectList(dataList, key) {
        if( !dataList || dataList.length === 0 ) {
            return NaN;
        }

        let newDataList = dataList.filter(item => {
            return (item[key] !== null)
        })

        return Number(newDataList.reduce((total, dataObj) => Number(total) + Number(dataObj[key]), 0) / newDataList.length);
    }
}


export default Calulate;