import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useCallback } from 'react';
import { Button, Grid, Tab, Tabs, Paper, TextField, MenuItem } from '@material-ui/core';
import EnhancedTable from '../components/Table/EnhancedTable';
import { loadAction, snackbarAction, inventorySummaryAction } from '../store/actions';
import TabPanel from '../components/Tab/TabPanel';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import LineChart from '../components/Chart/LineChart';
import { ModalDownloadInventorySummary } from '../components/DataManagement/ModalForm/ModalDownloadInventorySummary';
import InventoryPrice from '../services/InventoryPrice';
import RefreshIcon from '@material-ui/icons/Refresh';

const initInventoryObj = {
    soybeanMeal: {},
    wheat: {},
    corn: {},
    tapioca: {},
    lysine: {}
};

const InventorySummary = () => {
    const dispatch = useDispatch();

    const [indexOfTab, setIndexOfTab] = useState(0);

    const [isOpenModalDownloadSummary, setIsOpenModalDownloadSummary] = useState(false)
    const [queryType, setQueryType] = useState("Month")
    const [year, setYear] = useState(null)
    const [highestLowestInventoryObj, setHighestLowestInventoryObj] = useState({...initInventoryObj});
    
    const inventorySummaryList = useSelector(state => state.inventorySummaryReducer);

    const getHighestLowestInventorySummaryList = useCallback(() => {
        InventoryPrice.getHighestLowestInventorySummaryList().then(resultObj => {
            const inventoryList = resultObj['data'];
            if( inventoryList.length > 0 ) {
                const newHighestLowestInventoryObj = {...initInventoryObj};
                
                for( const inventoryObj of inventoryList ) {
                    const inventoryType = inventoryObj['inventoryType'];

                    newHighestLowestInventoryObj[inventoryType] = inventoryObj;
                }

                setHighestLowestInventoryObj({...newHighestLowestInventoryObj});
            }

        }).catch(error => {
            const newError = { ...error }
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
        });
    }, [dispatch]);

    const getInventorySummaryListData = useCallback(() => {
        dispatch(loadAction.displayLoadingOverlay())
        dispatch(inventorySummaryAction.getInventorySummaryList("month"));
        setQueryType("Month")
        setYear(null)
        getHighestLowestInventorySummaryList();
    }, [dispatch, getHighestLowestInventorySummaryList]);

    useEffect(() => {
        getInventorySummaryListData()
    }, [getInventorySummaryListData])


    const headCellsInventorySummary = [
        { id: 'dateTimeQueryType', label: 'Date', numeric: false, disablePadding: false, isDate: false, isBoolean: false, dateFormat: "DD Month YYYY", className: 'w-32', headAlign: 'center', isEditAble: false },
        { id: 'soybeanMealTarget', label: 'Target Price Soybean Meal', numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2, cellColor: "rgba(244, 208, 169, 0.45)" },
        { id: 'soybeanMealInventory', label: 'Inventory Price Soybean Meal', numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2, cellColor: "rgba(244, 208, 169, 0.45)"},
        { id: 'wheatTarget', label: 'Target Price Wheat', numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2, cellColor: "rgba(191, 215, 175, 0.45)" },
        { id: 'wheatInventory', label: 'Inventory Price Wheat', numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2, cellColor: "rgba(191, 215, 175, 0.45)" },
        { id: 'cornTarget', label: 'Target Price Corn', numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2, cellColor: "rgba(61, 133, 198, 0.45)" },
        { id: 'cornInventory', label: 'Inventory Price Corn', numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2, cellColor: "rgba(61, 133, 198, 0.45)" },
        { id: 'tapiocaTarget', label: 'Target Price Tapioca', numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2, cellColor: "rgba(221, 193, 92, 0.45)" },
        { id: 'tapiocaInventory', label: 'Inventory Price Tapioca', numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2, cellColor: "rgba(221, 193, 92, 0.45)" },
        { id: 'lysineTarget', label: 'Target Price L-lysine', numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2, cellColor: "rgba(223, 151, 149, 0.45)" },
        { id: 'lysineInventory', label: 'Inventory Price L-lysine', numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2, cellColor: "rgba(223, 151, 149, 0.45)" },
    ]

    const selectedQueryType = ["Week", "Month", "Quarter", "Year"]

    const inventorySummaryTable = () => {
        return (
            <EnhancedTable tableDataList={inventorySummaryList.filter((item) => { return ( String(item.dateTimeQueryType).includes( year ? String(new Date(year).getFullYear()) : "")) })} headCellList={headCellsInventorySummary} handleTableClick={ () => {}} handleRowSeletion={ () => {}} isCheckBox={false} rowSelectedList={[]} defaultOrder="desc" defaultOrderBy="dateTimeQueryType"/>
        )
    }

    // const getHighestLowestInventorySummaryList = () => {
    //     InventoryPrice.getHighestLowestInventorySummaryList().then(resultObj => {
    //         const inventoryList = resultObj['data'];
    //         if( inventoryList.length > 0 ) {
    //             const newHighestLowestInventoryObj = {...initInventoryObj};
                
    //             for( const inventoryObj of inventoryList ) {
    //                 const inventoryType = inventoryObj['inventoryType'];

    //                 newHighestLowestInventoryObj[inventoryType] = inventoryObj;
    //             }

    //             setHighestLowestInventoryObj({...newHighestLowestInventoryObj});
    //         }

    //     }).catch(error => {
    //         const newError = { ...error }
    //         dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
    //     });
    // }

    const handleChangeFiltered = (queryType) => {
        dispatch(loadAction.displayLoadingOverlay())
        setQueryType(queryType)
        dispatch(inventorySummaryAction.getInventorySummaryList(queryType.toLowerCase()));
        dispatch(loadAction.hideLoadingOverlay());
    }

    const rightHeaderInventorySearch = () => {
        return (
            <Grid item xs={12} className="mb-3">
                <Grid container  alignItems='center' justifyContent="space-between">
                    <Grid item xs={8} className="mt-2">
                        <TextField className="mt-2" label="Unit" select size="small" variant="outlined" value={queryType} onChange={(e) => { handleChangeFiltered(e.target.value) }}>
                            {selectedQueryType.map((name, index) => {
                                return (
                                    <MenuItem key={index} value={name}>{name}</MenuItem>
                                )
                            })}
                        </TextField>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                autoOk
                                inputVariant="outlined"
                                size="small"
                                views={["year"]}
                                label="Year"
                                format="yyyy"
                                name="marketPriceYear"
                                className="ml-2 mt-2 w-1/2"
                                value={year}
                                onChange={(date) => { setYear(date) }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <Button className="md:w-auto ml-2 mt-2" variant="outlined" size="small" disableElevation color="primary"  startIcon={<RefreshIcon/>} onClick={ () => { getInventorySummaryListData()}}>
                            Refresh
                        </Button>
                    </Grid>
                    <Grid item className="mt-2 text-right">
                        <Button variant="contained" color="primary" onClick={() => { setIsOpenModalDownloadSummary(true) }}>
                            Download Data
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const filterInventorySearch = (valueString) => {
        const material = valueString.replace('Inventory', '');

        return (
            <Grid container className="mb-3" justifyContent="space-between">
                <Grid item xs={6} className="mt-3">
                    <Grid item xs={12} className="mb-5">
                        <span className="text-sm font-bold bg-green-100 border border-green-100">
                            Minimum inventory price : { highestLowestInventoryObj[material]['minInventoryPrice'] || 0 }
                        </span>
                    </Grid>
                    <Grid item xs={12}>
                        <span className="text-sm font-bold bg-red-100 border border-red-100">
                            Maximum inventory price : { highestLowestInventoryObj[material]['maxInventoryPrice'] || 0 }
                        </span>
                    </Grid>
                </Grid>
                <Grid item xs={6} className="mt-3 text-right">
                    <TextField label="Unit" select size="small" variant="outlined" value={queryType} onChange={(e) => { handleChangeFiltered(e.target.value) }}>
                        {selectedQueryType.map((name, index) => {
                            return (
                                <MenuItem key={index} value={name}>{name}</MenuItem>
                            )
                        })}
                    </TextField>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            autoOk
                            inputVariant="outlined"
                            size="small"
                            views={["year"]}
                            label="Year"
                            format="yyyy"
                            name="marketPriceYear"
                            className="ml-2"
                            value={year}
                            onChange={(date) => { setYear(date) }}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
        )
    }

    const onClickSelectedTab = (event, newValue) => {
        setIndexOfTab(newValue);
    };


    const handleDownloadSummary = (queryType, year) => {
        dispatch(loadAction.displayLoadingOverlay())
        InventoryPrice.downloadDataInventorySummary(queryType, year).then((response) => {
            dispatch(snackbarAction.openSnackbar({ options: { variant: "success" }, message: "Download Completed" }))
            dispatch(loadAction.hideLoadingOverlay());
        }).catch((error) => {
            let newError = { ...error }
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            dispatch(loadAction.hideLoadingOverlay());
        });
    }

    return (
        <Grid container spacing={2} justifyContent="center">
            <Paper square className="w-full mb-1" position="static" style={{zIndex:300}}>
                <Tabs value={indexOfTab} onChange={onClickSelectedTab} variant="fullWidth" indicatorColor="primary" textColor="primary">
                    <Tab label="ALL" />
                    <Tab label="Soybean Meal" />
                    <Tab label="Wheat" />
                    <Tab label="Corn" />
                    <Tab label="Tapioca" />
                    <Tab label="L-lysine" />
                </Tabs>
            </Paper>
            <Paper elevation={0} style={{ width: '100%', padding: '48px' }}>
                <TabPanel value={indexOfTab} index={0}>
                    <Grid item>
                        {rightHeaderInventorySearch()}
                        {inventorySummaryTable()}
                    </Grid>
                </TabPanel>
                <TabPanel value={indexOfTab} index={1}>
                    <Grid item>
                        {filterInventorySearch("soybeanMealInventory")}
                        <LineChart
                            dataList={inventorySummaryList.filter((item) => { return ( String(item.dateTimeQueryType).includes( year ? String(new Date(year).getFullYear()) : "")) })}
                            argumentField="dateTimeQueryType"
                            argumentType="string"
                            argumentRangeStart={inventorySummaryList.length > 10 ? inventorySummaryList[inventorySummaryList.length - 11]?.dateTimeQueryType : inventorySummaryList[0]?.dateTimeQueryType}
                            argumentRangeEnd ={inventorySummaryList[inventorySummaryList.length - 1]?.dateTimeQueryType}
                            argumentConstantLineList={[
                                { label: 'Today', value: new Date(), color: '#8c8cff', style: 'dash' }
                            ]}
                            valueTitle="Soybean Meal (THB/KG)"
                            valueFieldList={
                                [ 
                                    { key: "soybeanMealTarget", valueField: "soybeanMealTarget", name: 'Target Price', isShowInLegend: true },
                                    { key: "soybeanMealInventory", valueField: "soybeanMealInventory", name: 'Inventory Price', isShowInLegend: true },
                                ]
                            }
                            isStartFromZero={false}
                        />
                    </Grid>
                </TabPanel>
                <TabPanel value={indexOfTab} index={2}>
                    <Grid item>
                        {filterInventorySearch("wheatInventory")}
                        <LineChart
                            dataList={inventorySummaryList.filter((item) => { return ( String(item.dateTimeQueryType).includes( year ? String(new Date(year).getFullYear()) : "")) })}
                            argumentField="dateTimeQueryType"
                            argumentType="string"
                            argumentRangeStart={inventorySummaryList.length > 10 ? inventorySummaryList[inventorySummaryList.length - 11]?.dateTimeQueryType : inventorySummaryList[0]?.dateTimeQueryType}
                            argumentRangeEnd ={inventorySummaryList[inventorySummaryList.length - 1]?.dateTimeQueryType}
                            argumentConstantLineList={[
                                { label: 'Today', value: new Date(), color: '#8c8cff', style: 'dash' }
                            ]}
                            valueTitle="Wheat (THB/KG)"
                            valueFieldList={
                                [ 
                                    { key: "wheatTarget", valueField: "wheatTarget", name: 'Target Price', isShowInLegend: true },
                                    { key: "wheatInventory", valueField: "wheatInventory", name: 'Inventory Price', isShowInLegend: true },
                                    
                                ]
                            }
                            isStartFromZero={false}
                        />
                    </Grid>
                </TabPanel>
                <TabPanel value={indexOfTab} index={3}>
                    <Grid item>
                        {filterInventorySearch("cornInventory")}
                        <LineChart
                            dataList={inventorySummaryList.filter((item) => { return ( String(item.dateTimeQueryType).includes( year ? String(new Date(year).getFullYear()) : "")) })}
                            argumentField="dateTimeQueryType"
                            argumentType="string"
                            argumentRangeStart={inventorySummaryList.length > 10 ? inventorySummaryList[inventorySummaryList.length - 11]?.dateTimeQueryType : inventorySummaryList[0]?.dateTimeQueryType}
                            argumentRangeEnd ={inventorySummaryList[inventorySummaryList.length - 1]?.dateTimeQueryType}
                            argumentConstantLineList={[
                                { label: 'Today', value: new Date(), color: '#8c8cff', style: 'dash' }
                            ]}
                            valueTitle="Corn (THB/KG)"
                            valueFieldList={
                                [ 
                                    { key: "cornTarget", valueField: "cornTarget", name: 'Target Price', isShowInLegend: true },
                                    { key: "cornInventory", valueField: "cornInventory", name: 'Inventory Price', isShowInLegend: true },
                                    
                                ]
                            }
                            isStartFromZero={false}
                        />
                    </Grid>
                </TabPanel>
                <TabPanel value={indexOfTab} index={4}>
                    <Grid item>
                        {filterInventorySearch("tapiocaInventory")}
                        <LineChart
                            dataList={inventorySummaryList.filter((item) => { return ( String(item.dateTimeQueryType).includes( year ? String(new Date(year).getFullYear()) : "")) })}
                            argumentField="dateTimeQueryType"
                            argumentType="string"
                            argumentRangeStart={inventorySummaryList.length > 10 ? inventorySummaryList[inventorySummaryList.length - 11]?.dateTimeQueryType : inventorySummaryList[0]?.dateTimeQueryType}
                            argumentRangeEnd ={inventorySummaryList[inventorySummaryList.length - 1]?.dateTimeQueryType}
                            argumentConstantLineList={[
                                { label: 'Today', value: new Date(), color: '#8c8cff', style: 'dash' }
                            ]}
                            valueTitle="Tapioca (THB/KG)"
                            valueFieldList={
                                [ 
                                    { key: "tapiocaTarget", valueField: "tapiocaTarget", name: 'Target Price', isShowInLegend: true },
                                    { key: "tapiocaInventory", valueField: "tapiocaInventory", name: 'Inventory Price', isShowInLegend: true },
                                    
                                ]
                            }
                            isStartFromZero={false}
                        />
                    </Grid>
                </TabPanel>
                <TabPanel value={indexOfTab} index={5}>
                    <Grid item>
                        {filterInventorySearch("lysineInventory")}
                        <LineChart
                            dataList={inventorySummaryList.filter((item) => { return ( String(item.dateTimeQueryType).includes( year ? String(new Date(year).getFullYear()) : "")) })}
                            argumentField="dateTimeQueryType"
                            argumentType="string"
                            argumentRangeStart={inventorySummaryList.length > 10 ? inventorySummaryList[inventorySummaryList.length - 11]?.dateTimeQueryType : inventorySummaryList[0]?.dateTimeQueryType}
                            argumentRangeEnd ={inventorySummaryList[inventorySummaryList.length - 1]?.dateTimeQueryType}
                            argumentConstantLineList={[
                                { label: 'Today', value: new Date(), color: '#8c8cff', style: 'dash' }
                            ]}
                            valueTitle="Lysine (THB/KG)"
                            valueFieldList={
                                [ 
                                    { key: "lysineTarget", valueField: "lysineTarget", name: 'Target Price', isShowInLegend: true },
                                    { key: "lysineInventory", valueField: "lysineInventory", name: 'Inventory Price', isShowInLegend: true },
                                    
                                ]
                            }
                            isStartFromZero={false}
                        />
                    </Grid>
                </TabPanel>
            </Paper>
            <ModalDownloadInventorySummary isOpen={isOpenModalDownloadSummary} handleOnCancle={(e) => setIsOpenModalDownloadSummary(e)} handleDownload={handleDownloadSummary}/>
        </Grid>
    );
}

export default InventorySummary;