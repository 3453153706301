import ApiProxy from '../utils/ApiProxy';

class DataActivity {
    
    static async getDataActivityList(offset = 0 , limit = 0, month, year, inputType) {
        return new Promise(async (resolve, reject) => {
            let dataActivityList = await ApiProxy.sendRequest('GET', `data-activity?offset=${offset}&limit=${limit}&month=${!month ? '' : month}&year=${!year ? '' : year}&inputType=${inputType}`).catch((error) => {
                reject(error);
            });
            resolve(dataActivityList);
        });
    }

}

export default DataActivity;