const { default: ActionTypes } = require("../actions/actionType");

const initialState = { mobileMenuOpen: false };

const wheatReducer = function (state = initialState, action) {
    switch (action.type) {
        case ActionTypes.UPDATE_MOBILE_MENU_OPEN: {
            const newState = {...state};
            newState['mobileMenuOpen'] = action.payload;
            return {...newState};
        }
        default: {
            return { ...state };
        }
    }

}

export default wheatReducer;