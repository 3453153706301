import { loadAction } from './index'
import InventoryPrice from "../../services/InventoryPrice";
const { default: ActionTypes } = require("./actionType");

export function getInventorySummaryList(queryType) {
    return (dispatch) => {
        dispatch(loadAction.displayLoadingOverlay())
        return InventoryPrice.getInventorySummaryList(queryType)
        .then((inventorySummary) => {
            dispatch({
                type: ActionTypes.GET_INVENTORY_SUMMARY_LIST,
                payload: inventorySummary.data
            });
            dispatch(loadAction.hideLoadingOverlay())
        })
        .catch(() => {
            dispatch({
                type: ActionTypes.GET_INVENTORY_SUMMARY_LIST,
                payload: []
            });
            dispatch(loadAction.hideLoadingOverlay())
        });
    }
}