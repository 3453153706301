import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Button, Grid, Paper, FormGroup, FormControl, Switch, FormControlLabel, TextField } from '@material-ui/core';
import SoybeanMealService from '../services/SoybeanMeal';
import InventoryPriceService from '../services/InventoryPrice';
import EnhancedTable from '../components/Table/EnhancedTable';
import { soybeanMealResultAction, loadAction, snackbarAction, materialEventAction } from '../store/actions';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import LineChart from '../components/Chart/LineChart'
import DateObject from '../utils/DateObject';
import { ModalDownloadForecastResult } from '../components/DataManagement/ModalForm/ModalDownloadForecastResult';
import SeasonalModal from '../components/Options/SeasonalModal';
import CalculateFunction from '../utils/Calulate';

import FilterTimeButton from '../components/FilterTimeButton';
import InputMultipleSelect from '../components/InputMultipleSelect';
import PriceSuggestionResultArea from '../components/Areas/PriceSuggestionResultArea';

import RefreshIcon from '@material-ui/icons/Refresh';
import JsonObject from '../utils/JsonObject';
import Currency from '../utils/Currency';
import InputSelect from '../components/InpuSelect';

const SoybeanMealResult = () => {
    const dispatch = useDispatch();

    const [selectedYear, setSelectedYear] = useState(null)

    const [filterSoybeanMealResult, setFilterSoybeanMealResult] = useState([])
    const [forecastDate, setForecastDate] = useState(DateObject.getDateTime('YYYY-MM-DD'))
    const [inputPrice, setInputPrice] = useState(0)
    const [inputPremiumPrice, setInputPremiumPrice] = useState(0);
    const [inputExchangeRate, setInputExchangeRate] = useState(0);
    
    const [priceSuggestionResultObj, setPriceSuggestionResultObj] = useState(null);

    const [isOpenModalDownloadForecastResult, setIsOpenModalDownloadForecastResult] = useState(false)
    const [isDisplaySeasonalModal, setIsDisplaySeasonalModal] = useState(false);
    const soybeanMealResultList = useSelector(state => state.soybeanMealResultReducer);
    const [isCheckedForecastHistory, setIsCheckedForecastHistory] = useState(true)
    const [isCheckedPriceRange, setIsCheckedPriceRange] = useState(true)
    const [isChartDisplayPoint, setIsChartDisplayPoint] = useState(true);

    const todayDate = DateObject.convertDateTime(new Date(), "YYYY-MM-DD")
    const [selectedFilterTime, setSelectedFilterTime] = useState(-12);
    const [contractMonthList, setContractMonthList] = useState([]);
    const [selectedContractMonthList, setSelectedContractMonthList] = useState(['JAN']);
    const [selectedContractMonth, setSelectedContractMonth] = useState("");
    const unit = "USD/To";

    const [minDate, setMinDate] = useState(null);
    const [maxDate, setMaxDate] = useState(null);

    useEffect(() => {
        if( soybeanMealResultList.length > 0 ) {
            if( !minDate ) {
                setMinDate(soybeanMealResultList.filter((item) => item.forecastValue !== null)[0]?.date)
            }
            if( !maxDate ) {
                setMaxDate(DateObject.getLastDayOfMonth(soybeanMealResultList[soybeanMealResultList.length -1]?.date));
            }
        }
    }, [soybeanMealResultList, minDate, maxDate])

    const getSoybeanMealResultListData = useCallback(() => {
        dispatch(soybeanMealResultAction.getSoybeanMealResultList(""));
        dispatch(materialEventAction.getMaterialEventList("","","","soybeanMeal"));
        setSelectedYear(null)
    }, [dispatch]);

    useEffect(() => {
        getSoybeanMealResultListData()
    }, [getSoybeanMealResultListData])

    useEffect(() => {
        if(soybeanMealResultList.length > 0 && filterSoybeanMealResult.length === 0){
            prepareChartDataList([...soybeanMealResultList]);
        }
    }, [soybeanMealResultList, filterSoybeanMealResult])

    const prepareChartDataList = (soybeanMealList) => {
        const newSoybeanMealList = [];
        let newSoybeanMealObj = {};
        let addedContractList = [];
        for(const soybeanMealObj of soybeanMealList) {
            const contract = soybeanMealObj['contract'];
            if( addedContractList.indexOf(contract) > -1 ) {
                newSoybeanMealList.push(newSoybeanMealObj);
                newSoybeanMealObj = {};
                addedContractList = [];
            }

            const newActualPriceKey = `actualPrice_${contract}`;
            const newForecastValueKey = `forecastValue_${contract}`;
            const newForecastLowerKey = `forecastLower_${contract}`;
            const newForecastUpperKey = `forecastUpper_${contract}`;

            newSoybeanMealObj['date'] = soybeanMealObj['date'];
            newSoybeanMealObj[newActualPriceKey] = soybeanMealObj['actualPrice'];
            newSoybeanMealObj[newForecastValueKey] = soybeanMealObj['forecastValue'];
            newSoybeanMealObj[newForecastLowerKey] = soybeanMealObj['forecastLower'];
            newSoybeanMealObj[newForecastUpperKey] = soybeanMealObj['forecastUpper'];
            addedContractList.push(contract);
        }
        if( newSoybeanMealObj.hasOwnProperty('date') ) {
            newSoybeanMealList.push(newSoybeanMealObj);
        }

        addedContractList.sort( (a, b) => {
            const newA = a.charAt(0) + a.slice(1).toLowerCase();
            const newB = b.charAt(0) + b.slice(1).toLowerCase();
            return DateObject.SHORT_MONTH_EN_LIST.indexOf(newA) - DateObject.SHORT_MONTH_EN_LIST.indexOf(newB);
        } );

        setContractMonthList(addedContractList);
        setFilterSoybeanMealResult(newSoybeanMealList);
    }

    const headCellsSoybeanMealMarketPrice = [
        { id: 'date', sort: 'dateWithContract', label: 'Date', numeric: false, disablePadding: false, isDate: true, isBoolean: false, dateFormat: "YYYY-MM", className: 'w-48', headAlign: 'center', isEditAble: false },
        { id: 'contract', label: 'Contract', numeric: false, disablePadding: false, isDate: false, isBoolean: false, className: 'w-48', headAlign: 'center', isEditAble: false },
        { id: 'actualPrice', label: `Market Price (${unit})`, numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2 },
        { id: 'forecastLower', label: `Forecast Price Min (${unit})`, numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2 },
        { id: 'forecastValue', label: `Forecast Price Mean (${unit})`, numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2 },
        { id: 'forecastUpper', label: `Forecast Price Max (${unit})`, numeric: true, disablePadding: false, isDate: false, isBoolean: false, isEditAble: true, isDecimal: true, decimalNum:2 },
    ]

    const soybeanMealResultTable = () => {
        return (
            <EnhancedTable tableDataList={soybeanMealResultList} headCellList={headCellsSoybeanMealMarketPrice} handleTableClick={() => { }} handleRowSeletion={() => { }} isCheckBox={false} rowSelectedList={[]} defaultOrder="asc" defaultOrderBy="dateWithContract" isMonth={true}/>
        )
    }

    const handleChangeDate = (date) => {
        let newDate = new Date(date)
        let selectedYear = newDate.getFullYear()

        dispatch(loadAction.displayLoadingOverlay())
        setSelectedYear(newDate)
        dispatch(soybeanMealResultAction.getSoybeanMealResultList(selectedYear));
        dispatch(loadAction.hideLoadingOverlay());
    }

    const handleChangeForecastDate = (date) => {
        let newDate = new Date(date)

        setForecastDate(newDate)
    }

    const rightHeaderSoybeanMealResultSearch = () => {
        return (
            <Grid item xs={12} className="mb-3">
                <Grid container justifyContent="space-between">
                    <Grid item>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                views={["year"]}
                                label="Year"
                                format="yyyy"
                                name="soybeanMealForecastDate"
                                onChange={(date) => { handleChangeDate(date) }}
                                value={selectedYear}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <Button className="w-full md:w-auto mt-5 ml-2" variant="outlined" size="small" disableElevation color="primary"  startIcon={<RefreshIcon/>} onClick={ () => { getSoybeanMealResultListData()}}>
                            Refresh
                        </Button>
                    </Grid>
                    <Grid item className="mt-3">
                        <Button variant="contained" color="primary" onClick={() => { setIsOpenModalDownloadForecastResult(true) }}>
                            Download Data
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    const handleDownloadSoybeanMealForecast = (year) => {
        dispatch(loadAction.displayLoadingOverlay())
        SoybeanMealService.downloadDataResult(year).then((response) => {
            dispatch(snackbarAction.openSnackbar({ options: { variant: "success" }, message: "Download Completed" }))
            dispatch(loadAction.hideLoadingOverlay());
        }).catch((error) => {
            let newError = { ...error }
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            dispatch(loadAction.hideLoadingOverlay());
        });
    }

    const calculatePriceSuggest = async() => {
        dispatch(loadAction.displayLoadingOverlay())
        const selectedForecastMonth = DateObject.convertDateTime(forecastDate, 'MM');
        const selectedForecastYear = DateObject.convertDateTime(forecastDate, 'YYYY');
        InventoryPriceService.getInventoryPriceObjectByDate("soybeanMeal", selectedForecastMonth , selectedForecastYear ).then((inventoryPriceData) => {
            SoybeanMealService.getSoybeanMealResultObjectByDate(selectedForecastMonth, selectedForecastYear, selectedContractMonth).then(async(soybeanMealResultData) => {
                const averageTargetPrice = CalculateFunction.findAverageFromObjectList(inventoryPriceData['data'], 'targetPrice');
                const average = Currency.convertPricePerUnit('THB/KG->USD/Sh.To', averageTargetPrice, inputExchangeRate) - Number(inputPremiumPrice);
                const suggestionPercentage = await CalculateFunction.calPriceSuggestion(inputPrice, soybeanMealResultData["data"]?.["forecastValue"], soybeanMealResultData["data"]?.["forecastRmse"]);
                const diffPercentage = (inputPrice - average) / average * 100;
                
                const suggestionObj = {
                    userInputPrice: inputPrice,
                    targetPrice: average,
                    minValue: soybeanMealResultData["data"]?.["forecastLower"],
                    maxValue: soybeanMealResultData["data"]?.["forecastUpper"],
                    forecastValue: soybeanMealResultData["data"]?.["forecastValue"],
                    forecastRmse: soybeanMealResultData["data"]?.["forecastRmse"],
                    suggestionPercentage: suggestionPercentage,
                    diffPercentage: diffPercentage,
                    chartScale: JsonObject.parseChartRate(soybeanMealResultData["data"]?.["forecastScaleJson"])
                }
                setPriceSuggestionResultObj(suggestionObj);
                dispatch(loadAction.hideLoadingOverlay());
            }).catch((error)=> {
                let newError = { ...error }
                dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
                dispatch(loadAction.hideLoadingOverlay());
                setPriceSuggestionResultObj(null);
            })
        }).catch((error) => {
            let newError = { ...error }
            dispatch(snackbarAction.openSnackbar({ options: { variant: "error" }, message: !newError.response ? "An error occurred, please try again" : newError.response.data.message }))
            dispatch(loadAction.hideLoadingOverlay());
            setPriceSuggestionResultObj(null);
        });
    }

    const genNewDateWithMonth = (date, months) => {
        date.setMonth(date.getMonth() + months);
        return date;
    }

    const filterChartDataList = () => {
        if( isCheckedForecastHistory ) {
            return [...filterSoybeanMealResult];
        }

        const chartDataList = [...filterSoybeanMealResult].map(cornResultObj => {
            const returnObj = {...cornResultObj};
            if( (new Date()).getTime() > (new Date(returnObj['date'])).getTime() ) {
                if( selectedContractMonthList.length > 0 ) {
                    for(const month of selectedContractMonthList) {
                        returnObj[`forecastLower_${month}`] = null;
                        returnObj[`forecastUpper_${month}`] = null;
                        returnObj[`forecastValue_${month}`] = null;
                    }
                }
            }
            
            return returnObj;
        });

        return chartDataList;
    };

    const getStartDate = () => {
        if( selectedFilterTime === 0 ) {
            return filterSoybeanMealResult[0]?.date;
        }
        
        const lastDataDate = filterSoybeanMealResult[filterSoybeanMealResult.length-1]?.date;
        const endDate = new Date(lastDataDate);
        const startDate = genNewDateWithMonth(endDate, selectedFilterTime)
        const filterCornResult = filterSoybeanMealResult.filter(item => {
            return new Date(item.date) >= startDate;
        })
        return filterCornResult[0]?.date;
    }

    const generateChartValueFieldList = () => {
        const valueFieldList = [];
        if( selectedContractMonthList.length > 0 ) {
            for(const month of selectedContractMonthList) {
                if( isCheckedPriceRange ) {
                    valueFieldList.push({ key: `rangeArea_${month}`, rangeValue1Field: `forecastLower_${month}`, rangeValue2Field: `forecastUpper_${month}`, type: 'rangeArea', color: '#b0daff', isShowInLegend: false });
                    valueFieldList.push({ key: `actualPrice_${month}`, valueField: `actualPrice_${month}`, name: `Market Price ${month}`, isShowInLegend: true });
                    valueFieldList.push({ key: `forecastValue_${month}`, valueField: `forecastValue_${month}`, name: `Forecast Price ${month}`, isShowInLegend: true });
                    valueFieldList.push({ key: `forecastLower_${month}`, valueField: `forecastLower_${month}`, name: `Lower Bounded ${month}`, isShowInLegend: true });
                    valueFieldList.push({ key: `forecastUpper_${month}`, valueField: `forecastUpper_${month}`, name: `Upper Bounded ${month}`, isShowInLegend: true });
                } else {
                    valueFieldList.push({ key: `actualPrice_${month}`, valueField: `actualPrice_${month}`, name: `Market Price ${month}`, isShowInLegend: true });
                    valueFieldList.push({ key: `forecastValue_${month}`, valueField: `forecastValue_${month}`, name: `Forecast Price ${month}`, isShowInLegend: true });
                }

            }
        }
        return valueFieldList;
    }

    const getArgumentConstantLineLabel = () => {

        let returnValue = `Current Month`;
        if( filterSoybeanMealResult.length === 0 || selectedContractMonthList.length === 0 ) {
            return returnValue;
        }

        if( selectedContractMonthList.length === 1 && selectedContractMonthList[0] === 'JAN' ) {
            const currentObj = filterSoybeanMealResult.filter(itemObj => {
                return itemObj['date'] === DateObject.getDateTime('YYYY-MM-01');
            });
            returnValue+= `:<br>`;
            if( isCheckedPriceRange ) {
                returnValue+= `Max: ${currentObj[0]?.forecastUpper_JAN.toFixed(2)}<br>`;
            }
            returnValue+= `Forecast: ${currentObj[0]?.forecastValue_JAN.toFixed(2)}<br>`;
            if( isCheckedPriceRange ) {
                returnValue+= `Min: ${currentObj[0]?.forecastLower_JAN.toFixed(2)}`;
            }

        }

        return returnValue;
    }

    const handleFilterTimeButtonClick = (newSelectedFilterTime) => {
        setSelectedFilterTime(newSelectedFilterTime);
        if( newSelectedFilterTime === 0 ) {
            setIsChartDisplayPoint(false);
        } else {
            setIsChartDisplayPoint(true);
        }
    }
    
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
                <Paper elevation={0} className="p-2 md:p-4 h-full">
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs="auto"><Typography variant="h3" style={{ fontWeight: 'bold' }}>SoybeanMeal Forecasting Result</Typography></Grid>
                                <Grid item xs="auto">
                                    <Button size="small" variant="outlined" color="primary" onClick={() => setIsDisplaySeasonalModal(!isDisplaySeasonalModal)}>
                                        Crop Calendar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                                <Grid item xs="auto">
                                    <FormControl component="fieldset">
                                        <FormGroup aria-label="position" row>
                                            <FormControlLabel
                                                value="start"
                                                control={<Switch color="primary" onChange={(e) => { setIsCheckedForecastHistory(e.target.checked) }} checked={isCheckedForecastHistory}/>}
                                                label="Forecast History"
                                                labelPlacement="start"
                                            />
                                            <FormControlLabel
                                                value="start"
                                                control={<Switch color="primary" onChange={(e) => { setIsCheckedPriceRange(e.target.checked) }} checked={isCheckedPriceRange}/>}
                                                label="Price Range"
                                                labelPlacement="start"
                                            />
                                        </FormGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs="auto">
                                    <FilterTimeButton
                                        selectedValue={selectedFilterTime}
                                        handleButtonClick={handleFilterTimeButtonClick}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <LineChart
                                argumentRangeStart={getStartDate()}
                                argumentRangeEnd={filterSoybeanMealResult[filterSoybeanMealResult.length - 1]?.['date']}
                                dataList={filterChartDataList()}
                                argumentField="date"
                                argumentType="datetime"
                                argumentFormat="monthAndYear"
                                argumentTitle="Month"
                                argumentConstantLineList={[
                                    { label: getArgumentConstantLineLabel(), value: todayDate, color: '#8c8cff', style: 'dash' }
                                ]}
                                valueTitle={`Market price (${unit})`}
                                valueFieldList={generateChartValueFieldList()}
                                isStartFromZero={false}
                                isDisplayPoint={isChartDisplayPoint}
                                unitType="USD"
                                materialType="soybeanMeal"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={3} sm={2} md={1} lg={1}>Filter:</Grid>
                                <Grid item xs={9} sm={5} md={4} lg={4} xl={3}>
                                    <InputMultipleSelect
                                        label="Contract"
                                        selectItemList={contractMonthList}
                                        selectedValueList={selectedContractMonthList}
                                        handleSelectChange={(event) => setSelectedContractMonthList(event.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12} lg={4}>
                <Paper elevation={0} className="p-2 md:p-4 h-full">
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12}>
                            <Typography variant="h3" style={{ fontWeight: 'bold' }}>Price Suggestion</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <PriceSuggestionResultArea 
                                priceSuggestionObj={priceSuggestionResultObj}
                                unit={unit}
                                unitType="USD"
                            />
                        </Grid>
                        <Grid item xs={12} className="flex">
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <Typography variant="body1" className="self-center">Month</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            views={["year", "month"]}
                                            label="Date"
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            autoOk
                                            inputVariant="outlined"
                                            size="small"
                                            format="MM/yyyy"
                                            name="forecastDate"
                                            onChange={(date) => { handleChangeForecastDate(date) }}
                                            value={forecastDate}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="flex">
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <Typography variant="body1" className="self-center pr-2 mb-2 mt-2">Input price</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField type="number" label={`Price (${unit})`} variant="outlined" size="small" autoComplete="off" value={inputPrice} onChange={(e) => { setInputPrice(e.target.value) }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="flex">
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <Typography variant="body1" className="self-center pr-2 mb-2 mt-2">Premium</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField type="number" label={`Premium (${unit})`} variant="outlined" size="small" autoComplete="off" value={inputPremiumPrice} onChange={(e) => { setInputPremiumPrice(e.target.value) }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="flex">
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <Typography variant="body1" className="self-center pr-2 mb-2 mt-2">Exchange rate</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField type="number" label={`THB -> USD`} variant="outlined" size="small" autoComplete="off" value={inputExchangeRate} onChange={(e) => { setInputExchangeRate(e.target.value) }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="flex">
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                    <Typography variant="body1" className="self-center pr-2 mb-2 mt-2">Contract</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <InputSelect
                                        label="Contract"
                                        selectItemList={contractMonthList}
                                        selectedValue={selectedContractMonth}
                                        handleSelectChange={(event) => setSelectedContractMonth(event.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className="text-right">
                            <Button variant="contained" color="primary" onClick={() => { calculatePriceSuggest() }} disabled={!inputPrice || !inputPremiumPrice || inputExchangeRate < 1}>
                                Calculate
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={0} className="p-10 md:p-4 h-full">
                    <Grid container>
                        <Grid item xs={12}>
                            {rightHeaderSoybeanMealResultSearch()}
                            {soybeanMealResultTable()}
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>

            <ModalDownloadForecastResult isOpen={isOpenModalDownloadForecastResult} handleOnCancle={(e) => setIsOpenModalDownloadForecastResult(e)} handleDownload={handleDownloadSoybeanMealForecast} />
            <ModalDownloadForecastResult isOpen={isOpenModalDownloadForecastResult} handleOnCancle={(e) => setIsOpenModalDownloadForecastResult(e)} handleDownload={handleDownloadSoybeanMealForecast}/>
            <SeasonalModal isDisplayDialog={isDisplaySeasonalModal} materialType="SBM" handleCloseDialog={() => setIsDisplaySeasonalModal(!isDisplaySeasonalModal)} />
        </Grid>
    );
}

export default SoybeanMealResult;