import React from 'react';
import { CircularGauge, Scale, Label, RangeContainer, Range, Tooltip, ValueIndicator, SubvalueIndicator, Geometry, Text } from 'devextreme-react/circular-gauge';
import { Size } from 'devextreme-react/chart';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Grid } from '@material-ui/core';
import Currency from '../../utils/Currency';

const CircleGauge = (props) => {

    const { startValue = 0 , endValue = 0, value = 0, targetValue= 0, valueScale= {}, unit, unitType } = props;

    function customizeTooltip(arg) {
        return {
          text: getTextTooltip(arg.type, arg.valueText)
        };
      }
    
    function customizeText(arg) {
        return getText(arg, arg.valueText);
      }
    
    function getTextTooltip(type, text) {
        if(type === "subvalue-indicator"){
            if( isNaN(targetValue) ) {
                return `Target price: -`
            }
            
            return `Target price: ${convertToNumber(targetValue, 2)} ${Currency.getSign(unitType)}`
        }else{
            return `Input price: ${value} ${Currency.getSign(unitType)}`
        }
    }

    function getText(item, text) {
      return `${text} ${Currency.getSign(unitType)}`
    }

    function customizeSubvalueIndicatorText({ valueText }) {
        return `<div style="font-size: .55rem;">Target: ${ convertToNumber(targetValue, 2) }</div>`
    }
    
    const format = {
        type: 'fixedPoint',
        precision: 2
    };

    const convertToNumber = (value, digits=0) => {
        if( isNaN(value) ) {
            return '-';
        }

        if( digits > 0 ) {
            return Number(Math.round(value * 100) / 100).toFixed(2);
        }

        return Number(Math.round(value * 100) / 100);
    }
    
    return (
        startValue < endValue ? (
            <>
                <CircularGauge id="gauge" value={convertToNumber(value)} subvalues={[convertToNumber(targetValue, 2)]}>
                    <Size height={200}/>
                    <Scale startValue={startValue} endValue={endValue} allowDecimals={true}>
                        <Label customizeText={customizeText} format={format} />
                    </Scale>
                    <ValueIndicator type="triangleNeedle" color="#000000"/>
                    <SubvalueIndicator type="textCloud">
                        <Text customizeText={customizeSubvalueIndicatorText} />
                    </SubvalueIndicator>
                    <Geometry startAngle={180} endAngle={0}/>
                    <Tooltip enabled={true} customizeTooltip={customizeTooltip}/>
                    <RangeContainer>
                        <Range startValue={startValue} endValue={valueScale?.["0.2"]} color="#83c566" />
                        <Range startValue={valueScale?.["0.2"]} endValue={valueScale?.["0.4"]} color="#ffd966" />
                        <Range startValue={valueScale?.["0.4"]} endValue={valueScale?.["0.6"]} color="#f6b26b" />
                        <Range startValue={valueScale?.["0.6"]} endValue={valueScale?.["0.8"]} color="#e06666" />
                        <Range startValue={valueScale?.["0.8"]} endValue={endValue} color="#ab0404" />
                    </RangeContainer>
                </CircularGauge>
                <Grid container justifyContent="center" spacing={1}>
                    <Grid item xs={6} className="text-xs">Min price: {startValue.toFixed(2)} {unit}</Grid>
                    <Grid item xs={6} className="text-xs text-right">Max price: {endValue.toFixed(2)} {unit}</Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={1}>
                            <Grid item xs="auto" className="flex items-center text-xs">
                                <FiberManualRecordIcon style={{ color: "#83c566"}} /> Strong Buy
                            </Grid>
                            <Grid item xs="auto" className="flex items-center text-xs">
                                <FiberManualRecordIcon style={{ color: "#ffd966"}} /> Moderate Buy
                            </Grid>
                            <Grid item xs="auto" className="flex items-center text-xs">
                                <FiberManualRecordIcon style={{ color: "#f6b26b"}} /> Observe
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent="center" spacing={1}>
                            <Grid item xs="auto" className="flex items-center text-xs">
                                <FiberManualRecordIcon style={{ color: "#e06666"}} /> Hold
                            </Grid>
                            <Grid item xs="auto" className="flex items-center text-xs">
                                <FiberManualRecordIcon style={{ color: "#ab0404"}} /> Strong Hold
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </>
        ) : (null)
    );
}

export default CircleGauge;