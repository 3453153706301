class Currency {

    static getSign(unit) {
        switch (unit) {
            case 'USD':
                return '$'
            case 'THB':
                return '฿'
            default:
                return '฿'
        }
    }

    static convertPricePerUnit(covertType, sourcePrice, exchangeRate) {
        let returnValue = 0;

        if( !sourcePrice ){
            return returnValue;
        }

        switch (covertType) {
            case 'THB/KG->USD/Sh.To':
                returnValue = ( (sourcePrice * 907) - 300 ) / exchangeRate ;
                break;
            case 'THB/KG->USD/To':
                returnValue = ( (sourcePrice * 1000) - 300 ) / exchangeRate ;
                break;
            default:
                returnValue = 0;
                break;
        }

        if( returnValue > 0 ) {
            returnValue = Number(Math.round(returnValue * 100) / 100).toFixed(2);
        }

        return Number(returnValue);
    }

    static getDecimal = (rawNumber, decimal) => {
        const salt = Math.pow(10, decimal);
        return Number(Math.round(rawNumber * salt) / salt);
    }
}


export default Currency;