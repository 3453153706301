const { default: ActionTypes } = require("./actionType");

export function updateMobileMenuOpen(isOpen) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.UPDATE_MOBILE_MENU_OPEN,
            payload: isOpen
        });
    }
}

export function updateDesktopMenuOpen(isOpen) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.UPDATE_DESKTOP_MENU_OPEN,
            payload: isOpen
        });
    }
}


