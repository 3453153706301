import React, { Suspense, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { loadAction, userAction } from '../store/actions';
import LoadingOverlay from '../components/Options/LoadingOverlay';
import Snackbar from "../components/Options/Snackbar";
import PermissionRoutes, {firstPageUrl} from '../configs/PermissionRoutes';

const Routes = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const userProfile = useSelector(state => state.userReducer);
    const { isLoading } = useSelector(state => state.loadingReducer);
    const currentRole = userProfile["userLevel"];
    const allowedRouteComponent = PermissionRoutes[currentRole];
    const [allowedComponent, setAllowedComponent] = useState(allowedRouteComponent);

    useEffect(() => {
        setAllowedComponent(PermissionRoutes[currentRole]);
    }, [userProfile, currentRole]);

    useEffect(()=>{
        dispatch(userAction.authenUser());
    }, [dispatch, location])

    useEffect(() => {
        dispatch(loadAction.displayLoadingOverlay());
        dispatch(userAction.authenUser());
        dispatch(loadAction.hideLoadingOverlay());
    }, [dispatch]);

    const conditionalGetPage = (router, props) => {
        if (!props.match.isExact) {
            return <router.page {...props} />
        } else {
            return <router.layout><router.page {...props} /></router.layout>
        }
    }

    const conditionalRedirect = () => {
        if (currentRole === undefined) {
            return null
        } else if (currentRole === "guest" && !isLoading && location.pathname !== '/reset-password') {
            return <Redirect to="/login" />
        } else if (location.pathname === "/login" && currentRole !== "guest") {
            return <Redirect to={firstPageUrl} />
        } else if (location.pathname === "/" && !isLoading && currentRole !== "guest"){
            return <Redirect to={firstPageUrl} />
        } else {
            return null
        }
    }

    return (
        <>
            <Snackbar />
            <LoadingOverlay />
            <Suspense fallback={<LoadingOverlay />}>
                <Switch>
                    {allowedComponent?.map(router => (
                        <Route exact path={router.url} key={router.url || router.page} render={props => conditionalGetPage(router, props)} />
                    ))}
                    {conditionalRedirect()}
                </Switch>
            </Suspense>
        </>
    );
};

export default Routes;
