const axios = require('axios');
const API_URL = process.env.REACT_APP_API_URL

class ApiProxy {

    static async sendRequest(method, path, json=null) {
        if( !json ) {
            json = {};
        }
        return new Promise(async (resolve, reject) => {
            let response = await axios({
                method: method,
                url: API_URL + path,
                data: json,
                withCredentials: true
            }).catch((error) => {
                reject(error);
            });

            if( response ) {
                resolve(response.data);
            } else {
                resolve();
            }
            
        });
    }

    static async exportData(method, path){
        return new Promise(async (resolve, reject) => {
            let response = await axios({
                method: method,
                url: API_URL + path + '&isLogged=true',
                withCredentials: true
            }).catch((error) => {
                console.log("err")
                reject(error);
            });
            
            if( response ) {
                const link = document.createElement("a");
                link.href = API_URL+path;
                link.click();
                resolve();
            }else{
                resolve();
            }
        });
    }
    
}

export default ApiProxy;