const LOADING = '[LOAD] LOADING PAGE';
const AUTHEN_USER = '[AUTHEN] AUTHEN_USER' 
const OPEN_SNACKBAR = "[SNACKBAR] OPEN_SNACKBAR";
const CLOSE_SNACKBAR = "[SNACKBAR] CLOSE_SNACKBAR";
const REMOVE_SNACKBAR = "[SNACKBAR] REMOVE_SNACKBAR";
const GET_USER_OBJECT = '[USER] GET USER OBJECT';
const GET_USER_PROFILE_OBJECT = '[USER] GET USER PROFILE OBJECT';
const GET_USER_LIST = '[USER] GET USER LIST';
const GET_USER_LEVEL_LIST = '[USER] GET USER LEVEL LIST';
const GET_CORN_LIST = '[CORN] GET CORN LIST';
const GET_WHEAT_LIST = '[WHEAT] GET WHEAT LIST';
const GET_LYSINE_LIST = '[LYSINE] GET LYSINE LIST';
const GET_TAPIOCA_LIST = '[TAPIOCA] GET TAPIOCA LIST';
const GET_SOYBEAN_MEAL_LIST = '[SOYBEAN MEAL] GET SOYBEAN MEAL LIST';
const GET_INVENTORY_PRICE_LIST = '[INVENTORY PRICE] GET INVENTORY PRICE LIST';
const GET_INVENTORY_SUMMARY_LIST = '[INVENTORY SUMMARY] GET INVENTORY SUMMARY LIST';
const GET_DATA_ACTIVITY_LIST = '[DATA ACTIVITY] GET DATA ACTIVITY LIST';
const GET_MATERIAL_EVENT_LIST = '[MATERIAL EVENT] GET MATERIAL EVENT LIST';
const GET_CORN_RESULT_LIST = '[CORN RESULT] GET CORN RESULT LIST';
const GET_LYSINE_RESULT_LIST = '[LYSINE RESULT] GET LYSINE RESULT LIST';
const GET_WHEAT_RESULT_LIST = '[WHEAT RESULT] GET WHEAT RESULT LIST';
const GET_SOYBEANMEAL_RESULT_LIST = '[SOYBEANMEAL RESULT] GET SOYBEANMEAL RESULT LIST';
const GET_TAPIOCA_RESULT_LIST = '[TAPIOCA RESULT] GET TAPIOCA RESULT LIST';
const UPDATE_MOBILE_MENU_OPEN = '[SIDEBAR] UPDATE MOBILE MENU OPEN';
const GET_PRICE_SUMMARY_OBJECT = 'GET PRICE SUMMARY OBJECT';

const ActionTypes = {
    LOADING,
    AUTHEN_USER,
    OPEN_SNACKBAR,
    CLOSE_SNACKBAR,
    REMOVE_SNACKBAR,  
    GET_USER_OBJECT,
    GET_USER_PROFILE_OBJECT,
    GET_USER_LIST,
    GET_USER_LEVEL_LIST,
    GET_DATA_ACTIVITY_LIST,
    GET_INVENTORY_PRICE_LIST,
    GET_MATERIAL_EVENT_LIST,
    GET_CORN_LIST,
    GET_WHEAT_LIST,
    GET_LYSINE_LIST,
    GET_TAPIOCA_LIST,
    GET_SOYBEAN_MEAL_LIST,
    GET_CORN_RESULT_LIST,
    GET_LYSINE_RESULT_LIST,
    GET_SOYBEANMEAL_RESULT_LIST,
    GET_TAPIOCA_RESULT_LIST,
    GET_WHEAT_RESULT_LIST,
    GET_INVENTORY_SUMMARY_LIST,
    UPDATE_MOBILE_MENU_OPEN,
    GET_PRICE_SUMMARY_OBJECT
};

export default ActionTypes;