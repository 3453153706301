import { createTheme  } from '@material-ui/core';

import palette from './palette';
import typography from './typography';

const THEME = createTheme({
    overrides: {
    },
    typography,
    palette
});

export default THEME;