class DateObject {

    static today;
    static day
    static month
    static year
    static hour
    static minute
    static second

    static MON_LIST = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
    static WEEKDAY_TH_LIST = ["วันอาทิตย์", "วันจันทร์", "วันอังคาร", "วันพุทธ", "พฤหัสบดี", "วันศุกร์", "วันเสาร์"];
    static WD_TH_LIST = ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."];
    static MONTH_LIST = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    static SHORT_MONTH_EN_LIST = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    static SHORT_MONTH_EN_LIST_UPPER_CASE = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    static MONTH_OBJECT_LIST = [
        {
            name: "January",
            value: "01"
        },
        {
            name: "February",
            value: "02"
        },
        {
            name: "March",
            value: "03"
        },
        {
            name: "April",
            value: "04"
        },
        {
            name: "May",
            value: "05"
        },
        {
            name: "June",
            value: "06"
        },
        {
            name: "July",
            value: "07"
        },
        {
            name: "August",
            value: "08"
        },
        {
            name: "September",
            value: "09"
        },
        {
            name: "October",
            value: "10"
        },
        {
            name: "November",
            value: "11"
        },
        {
            name: "December",
            value: "12"
        },
    ]

    static sortDateTime(valueFrist, valueSecond) {

        const [firstTime, firstDate] = valueFrist.split("/").map(e => e.trim());
        const [fHour, fMin] = firstTime.split(":").map(e => e.trim());
        const [fDate, fMonth, fYear] = firstDate.split(" ").map(e => e.trim());
        const fMonthToIndex = this.MON_LIST.indexOf(fMonth)
        const [secondTime, secondDate] = valueSecond.split("/").map(e => e.trim());
        const [sHour, sMin] = secondTime.split(":").map(e => e.trim());
        const [sDate, sMonth, sYear] = secondDate.split(" ").map(e => e.trim());
        const sMonthToIndex = this.MON_LIST.indexOf(sMonth)

        const resultCompareYear = this.compareDateTime(fYear, sYear)
        const resultCompareMonth = this.compareDateTime(fMonthToIndex, sMonthToIndex)
        const resultCompareDate = this.compareDateTime(fDate, sDate)
        const resultCompareHour = this.compareDateTime(fHour, sHour)
        const resultCompareMin = this.compareDateTime(fMin, sMin)

        if (resultCompareYear !== 0) {
            return resultCompareYear
        }
        if (resultCompareMonth !== 0) {
            return resultCompareMonth
        }
        if (resultCompareDate !== 0) {
            return resultCompareDate
        }
        if (resultCompareHour !== 0) {
            return resultCompareHour
        }
        if (resultCompareMin !== 0) {
            return resultCompareMin
        }
    }

    static compareDateTime(fristDT, secondDT) {
        if (fristDT < secondDT) {
            return -1
        } else if (fristDT > secondDT) {
            return 1
        } else {
            return 0
        }
    }

    static getCurrentTime() {
        this.today = new Date();
        this.day = String(this.today.getDate()).padStart(2, "0");
        this.month = String(this.today.getMonth() + 1).padStart(2, "0");
        this.year = this.today.getFullYear();
        this.hour = String(this.today.getHours()).padStart(2, "0");
        this.minute = String(this.today.getMinutes()).padStart(2, "0");
        this.second = String(this.today.getSeconds()).padStart(2, "0");
    }

    static genarateYearOptionList = () => {
        this.getCurrentTime()
        const yearOptionList = []
        for (let i = 2000; i <= this.year; i++) {
            yearOptionList.push({ label: i, value: i })
        }
        return yearOptionList
    }

    static getDateTime(dateFormat = "YYYY-MM-DD HH:mm:ss") {
        this.getCurrentTime()
        return dateFormat.replace(/YYYY/g, this.year).replace(/MM/g, this.month).replace(/DD/g, this.day)
            .replace(/HH/g, this.hour)
            .replace(/mm/g, this.minute)
            .replace(/ss/g, this.second)
    }

    static convertDateTime(dateString, dateFormat = "YYYY-MM-DD HH:mm:ss", isBC = false) {
        let date = new Date(dateString);
        let rawMonth = date.getMonth();
        let rawDay = date.getDay();
        let day = String(date.getDate()).padStart(2, "0");
        let month = String(rawMonth + 1).padStart(2, "0");
        let year = date.getFullYear();
        let hour = String(date.getHours()).padStart(2, "0");
        let minute = String(date.getMinutes()).padStart(2, "0");
        let second = String(date.getSeconds()).padStart(2, "0");

        return dateFormat.replace(/YYYY/g, year)
            .replace(/yyyy/g, isBC ? year : year + 543)
            .replace(/MM/g, month)
            .replace(/DD/g, day)
            .replace(/HH/g, hour)
            .replace(/mm/g, minute)
            .replace(/ss/g, second)
            .replace(/ShortMon/g, DateObject.SHORT_MONTH_EN_LIST[rawMonth])
            .replace(/Month/g, DateObject.MONTH_LIST[rawMonth])
            .replace(/Mon/g, DateObject.MON_LIST[rawMonth])
            .replace(/dd/g, DateObject.WEEKDAY_TH_LIST[rawDay])
            .replace(/d/g, DateObject.WD_TH_LIST[rawDay])
    }

    static compareDate(firstDate, secondDate, compareType) {
        let firstTimestamp = new Date(firstDate);
        let secondTimestamp = new Date(secondDate);
        let returnBoolean = false;
        switch (compareType) {
            case 'LESS_THAN_EQUAL':
                returnBoolean = (firstTimestamp <= secondTimestamp);
                break;
            case 'LESS_THAN':
                returnBoolean = (firstTimestamp < secondTimestamp);
                break;
            case 'MORE_THAN_EQUAL':
                returnBoolean = (firstTimestamp >= secondTimestamp);
                break;
            case 'MORE_THAN':
                returnBoolean = (firstTimestamp > secondTimestamp);
                break;
            default:
                break;
        }
        return returnBoolean;
    }

    static getLastDayOfMonth(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const rawMonth = date.getMonth();

        return new Date(year, rawMonth + 1, 0);
    }

    static findWeekInYear(dateString){
        let currentdate = new Date(dateString);
        let oneJan = new Date(currentdate.getFullYear(), 0, 1);
        let numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
        return Math.ceil((currentdate.getDay() + 1 + numberOfDays) / 7);
    }
    
    static findWeekInMonth(dateString){
        let currentWeek = this.findWeekInYear(dateString)
        let firstDateInMonth = new Date(new Date(dateString).setDate(1))
        let firstWeekInMonth = this.findWeekInYear(firstDateInMonth)
        const weekInMonth = Math.min(((currentWeek - firstWeekInMonth) + 1), 4)
        return weekInMonth
    }

    static addMonths(date, months) {
        let dateInfo = date.getDate();
        date.setMonth(date.getMonth() + +months);
        if (date.getDate() !== dateInfo) {
            date.setDate(0);
        }
        return date;
    }

    static filterDataListByDate(dataList, dateObjectKey, dateIncreaseNumber){
        let newDataList = dataList.filter(item => {
            let date = new Date(item[dateObjectKey]);
            return date >= this.addMonths(new Date(), -1) && date <= this.addMonths(new Date(), dateIncreaseNumber);
        })

        return newDataList
    }




    static calculatemonthDifference(startDate, endDate) {
        var months;
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        months = (endDateObj.getFullYear() - startDateObj.getFullYear()) * 12;
        months -= startDateObj.getMonth();
        months += endDateObj.getMonth();
        return months;
    }

}

export default DateObject;